import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
//const API_URL = 'https://int.api.forstec.tn'

export const GET_JOBS = `${API_URL}/jobOffers`
export const CREATE_JOB = `${API_URL}/jobOffers/`
export const GET_APPLICATIONS = `${API_URL}/applications`
export const GET_CANDIDATURES = `${API_URL}/condidatures`
export const GET_CANDIDATE = `${API_URL}/users/`
export const INVITATIONS = `${API_URL}/invitations`

// Server should return CompanyModel

export function getJobs(draft: boolean, value: number) {
  if (draft) {
    return axios.get<any>(
      GET_JOBS +
        `?filter={"company_id":{"op":"=","value":"${value}"}, "is_template":{"op":"=","value":"true"}}`
    )
  } else {
    return axios.get<any>(GET_JOBS + `?filter={"company_id":{"op":"=","value":"${value}"}}`)
  }
}

export function getJobsByFilter(company_id: any, draft: boolean, value: string) {
  if (!draft) {
    return axios.get<any>(
      GET_JOBS +
        `?filter={"company_id":{"op":"=", "value":"${company_id}"},"title":{"op":"ilike","value":"%25${value}%25"}}`
    )
  } else {
    return axios.get<any>(
      GET_JOBS +
        `?filter={"company_id":{"op":"=", "value":"${company_id}"},"is_template":{"op":"=","value":"true"}, "title":{"op":"=","value":"${value}"}}`
    )
  }
}

export function getJobsByDepartment(
  company_id: any,
  draft: boolean,
  site_id: string,
  department_id: string
) {
  if (draft) {
    if (department_id) {
      return axios.get<any>(
        GET_JOBS +
          `?filter={"company_id":{"op":"=", "value":"${company_id}"}, "is_template":{"op":"=","value":"true"},"site_id":{"op":"=","value":"${site_id}"}, "department_id":{"op":"=","value":"${department_id}"}}`
      )
    } else {
      return axios.get<any>(
        GET_JOBS +
          `?filter={"company_id":{"op":"=", "value":"${company_id}"}, "is_template":{"op":"=","value":"true"}, "site_id":{"op":"=","value":"${site_id}"}}`
      )
    }
  } else {
    if (department_id) {
      return axios.get<any>(
        GET_JOBS +
          `?filter={"company_id":{"op":"=", "value":"${company_id}"},"site_id":{"op":"=","value":"${site_id}"}, "department_id":{"op":"=","value":"${department_id}"}}`
      )
    } else {
      return axios.get<any>(
        GET_JOBS +
          `?filter={"company_id":{"op":"=", "value":"${company_id}"},"site_id":{"op":"=","value":"${site_id}"}}`
      )
    }
  }
}

export function getUserInvitations(user_id: any, company_id:any) {
  return axios.get<any>(INVITATIONS + `?filter={"user_id":{"op":"=","value":"${user_id}"},"company_id":{"op":"=","value":"${company_id}"}}`)
}

export function getJobById(id: any) {
  return axios.get<any>(CREATE_JOB + id)
}

export function getcandidateApplications(id: any, filter: any) {
  if (filter) {
    if (id)
      return axios.get<any>(
        GET_APPLICATIONS + `?filter={"company_id":{"op":"=","value":"${id}"}}&${filter}`
      )
    else return axios.get<any>(GET_APPLICATIONS + `?filter=${filter}`)
  } else {
    return axios.get<any>(GET_APPLICATIONS + `?filter={"company_id":{"op":"=","value":"${id}"}}`)
  }
}
export function getcandidatures(id: any, filter: any) {
  if (filter) {
    if (id)
      return axios.get<any>(
        GET_CANDIDATURES + `?filter={"company_id":{"op":"=","value":"${id}"}}&${filter}`
      )
    else 
    return axios.get<any>(GET_CANDIDATURES + `?filter=${filter}`)
  } else {
    return axios.get<any>(GET_CANDIDATURES + `?filter={"company_id":{"op":"=","value":"${id}"}}`)
  }
}
