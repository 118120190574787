import { toAbsoluteUrl } from '../../../helpers'
import './loader.css'

export function FallbackView() {
  return (
    <div className='loader-container'>
      <div className='loader'>
        <div className='loader--dot'></div>
        <div className='loader--dot'></div>
        <div className='loader--dot'></div>
        <div className='loader--dot'></div>
        <div className='loader--dot'></div>
        <div className='loader--dot'></div>
        <div className='loader--text'></div>
      </div>
    </div>
  )
}
