/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { PageTitle } from '../../../_metronic/layout/core'
import { MainCard } from '../../../_metronic/partials/content/cards/MainCard'
import MultiSelectAdvanced from '../../../_metronic/partials/multi-select/MultiSelectAdvanced'
import { MainWidget } from '../../../_metronic/partials/widgets'


const DashboardPage: FC = () => {


  const user:any = useSelector<RootState>(({auth}) => auth.user)
  const intl = useIntl()

  return(
  <>
    {/* begin::Row */}

    {/* end::Row */}

    {/* begin::Row */}

    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8 mt-n15'>
      <div className='col-xl-4'>
        <MainCard
          avatar='/media/illustrations/sigma-1/imgWelcome1.png'
          name={intl.formatMessage({id: 'PROFILE'})}
          link='/company/settings'
          description={intl.formatMessage({id: 'DESC.PROFILE.CARD'})}
          button={intl.formatMessage({id: 'EDIT.PROFILE'})}
        />
      </div>
      <div className='col-xl-4'>
        <MainCard
          avatar='/media/illustrations/sigma-1/imgWelcome2.png'
          name={intl.formatMessage({id: 'SOURCING'})}
          link='/sourcing'
          description={intl.formatMessage({id: 'DESC.SOURCING.CARD'})}
          button={intl.formatMessage({id: 'OPEN.SOURCING'})}
        />
      </div>
      <div className='col-xl-4'>
        <MainCard
          avatar='/media/illustrations/sigma-1/imgWelcome3.png'
          name={intl.formatMessage({id: 'JOB.OFFER'})}
          link='/jobs'
          description={intl.formatMessage({id: 'DESC.JOB.OFFER.CARD'})}
          button={intl.formatMessage({id: 'ADD.JOB.OFFER'})}
        />
        {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
      </div>
      {/* <div className='col-xxl-4'>
        <MainWidget
          className='card-xl-stretch mb-xl-8'
          color='3bd3c7'
          secondary="e9fffc"
        />
      </div>
      <div className='col-xxl-4'>
        <MainWidget
          className='card-xl-stretch mb-xl-8'
          color='ffa11d'
          secondary='fff2e3'
        />
      </div>
      <div className='col-xxl-4'>
        <MainWidget
          className='card-xl-stretch mb-xl-8'
          color='ff5f72'
          secondary='ffe6ea'
        />
      </div> */}
    </div>
    {/* <div className='row'>
      <MultiSelectAdvanced />
    </div> */}
    {/* end::Row */}

  </>)
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
