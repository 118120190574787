import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest, select, takeEvery} from 'redux-saga/effects'
import {UserModel} from '../models/UserModel'
import {
  editUser,
  editCompanyEmail,
  editCompanyPassword,
  getUserByToken,
  getNotifications,
  editNotification,
} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  getNotifications: '[getNotifications] Action',
  getNotificationsSucceded: '[getNotificationsSucceded] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  editCompanyPassword: '[editCompanyPassword] Action',
  editCompanyPasswordSucceded: '[editCompanyPasswordSucceded] Action',
  editCompanyPasswordFailed: '[editCompanyPasswordFailed] Action',
  editCompanyEmail: '[editCompanyEmail] Action',
  editCompanyEmailSucceded: '[editCompanyEmailSucceded] Action',
  editCompanyEmailFailed: '[editCompanyEmailFailed] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  editUser: '[editUser] Action',
  editNotification: '[editNotification] Action',
  editUserSucceded: '[editUserSucceded] Action',
  editUserFailed: '[editUserFailed] Action',
  resetMessages: '[resetMessages] Action',
  resetMessagesSucceded: '[resetMessagesSucceded] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  error: '',
  success: '',
  loading: false,
  notifications: undefined,
}

export interface IAuthState {
  user?: any
  notifications?: any
  accessToken?: string
  error: string
  success: string
  loading: boolean
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<any>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        return {...state, accessToken, user: undefined}
      }
      case actionTypes.getNotificationsSucceded: {
        const notifications = action.payload
        return {...state, notifications}
      }
      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        return {...state, accessToken, user: undefined}
      }
      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return {...state, ...user}
      }
      case actionTypes.Logout: {
        return initialAuthState
      }
      case actionTypes.editCompanyEmailSucceded: {
        const email = action.payload.email
        return {...state, success: 'update_company_email', user: {...state.user, email: email}}
      }
      case actionTypes.editCompanyEmailFailed: {
        return {...state, error: 'update_company_email'}
      }
      case actionTypes.editCompanyPasswordSucceded: {
        return {...state, success: 'update_company_password'}
      }
      case actionTypes.editCompanyPasswordFailed: {
        return {...state, error: 'update_company_password'}
      }
      case actionTypes.resetMessagesSucceded: {
        return {...state, error: '', success: '', loading: false}
      }

      case actionTypes.editUserFailed: {
        return {...state, error: 'update', loading: false}
      }
      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string) => ({type: actionTypes.Login, payload: {accessToken}}),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: {accessToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: any) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (data: any) => ({type: actionTypes.SetUser, payload: data}),
  editUser: (data: any) => ({type: actionTypes.editUser, payload: data}),
  editNotification: (data: any) => ({type: actionTypes.editNotification, payload: data}),
  editUserSucceded: (data: any) => ({type: actionTypes.editUserSucceded, payload: data}),
  editCompanyPassword: (data: any) => ({type: actionTypes.editCompanyPassword, payload: data}),
  editCompanyPasswordSucceded: () => ({type: actionTypes.editCompanyPasswordSucceded}),
  editCompanyPasswordFailed: () => ({type: actionTypes.editCompanyPasswordFailed}),
  editCompanyEmail: (data: any) => ({type: actionTypes.editCompanyEmail, payload: data}),
  editCompanyEmailSucceded: (data: any) => ({
    type: actionTypes.editCompanyEmailSucceded,
    payload: data,
  }),
  editCompanyEmailFailed: () => ({type: actionTypes.editCompanyEmailFailed}),
  editUserFailed: () => ({type: actionTypes.editUserFailed}),
  resetMessages: () => ({type: actionTypes.resetMessages}),
  resetMessagesSucceded: () => ({type: actionTypes.resetMessagesSucceded}),
  getNotificationsSucceded: (data: any) => ({
    type: actionTypes.getNotificationsSucceded,
    payload: data,
  }),
  getNotifications: (data: any) => ({type: actionTypes.getNotifications, payload: data}),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    try {
      yield put(actions.requestUser())
    } catch (e) {
      yield put(actions.logout())
    }
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    try {
      yield put(actions.requestUser())
    } catch (e) {
      yield put(actions.logout())
    }
  })

  yield takeEvery(actionTypes.getNotifications, function* getNotificationsSaga(filter: any) {
    try {
      const {
        data: {data},
      } = yield getNotifications(
        filter?.payload?.field,
        filter?.payload?.operand,
        filter?.payload?.value
      )
      yield put(actions.getNotificationsSucceded(data))
    } catch (e) {}
  })

  yield takeLatest(actionTypes.editUser, function* editUserSaga(data: any) {
    try {
      const editedData: {} = yield editUser(data?.payload.id, data?.payload.values)
      yield put(actions.editUserSucceded(editedData))
    } catch (e) {
      yield put(actions.editUserFailed())
    }
  })
  yield takeLatest(actionTypes.editNotification, function* editNotificationSaga(data: any) {
    try {
      const editedData: {} = yield editNotification(data?.payload.id, data?.payload.values)
      yield put(
        actions.getNotifications({
          field: 'company_user_id',
          operand: '=',
          value: data?.payload?.values?.company_user_id,
        })
      )
    } catch (e) {}
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    try {
      const {data: user} = yield getUserByToken()
      yield put(actions.fulfillUser(user))
    } catch (e) {
      yield put(actions.logout())
    }
  })

  yield takeEvery(actionTypes.editCompanyPassword, function* editCompanyPasswordSaga(data: any) {
    try {
      yield editCompanyPassword(data?.payload.values)
      yield put(actions.editCompanyPasswordSucceded())
    } catch (e) {
      yield put(actions.editCompanyPasswordFailed())
    }
  })
  yield takeEvery(actionTypes.editCompanyEmail, function* editCompanyEmailSaga(data: any) {
    try {
      yield editCompanyEmail(data?.payload.values)
      yield put(actions.editCompanyEmailSucceded(data?.payload.values))
    } catch (e) {
      yield put(actions.editCompanyEmailFailed())
    }
  })
  yield takeEvery(actionTypes.resetMessages, function* resetMessagesSaga() {
    yield put(actions.resetMessagesSucceded())
  })
}
