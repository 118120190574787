/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl, KTSVG } from '../../../helpers'

type Props = {
  color?: string
  avatar?: string
  online?: boolean
  name: string
  description: string
  link?: string
  button?: string
}

const MainCard: FC<Props> = ({
  color = '',
  avatar = '',
  online = true,
  name,
  description,
  link,
  button = 'Button'
}) => {
  return (
    <div className='card shadow-lg rounded-3 min-h-100'>
      <div className='card-body d-flex flex-center flex-column p-9'>
        <div className='mb-5'>
          <div className='symbol symbol-200px '>
            <img alt='Pic' src={toAbsoluteUrl(avatar)} />
          </div>
        </div>

        <span className='fs-4 text-gray-800 text-center text-hover-primary fw-bolder mb-0'>
          {name}
        </span>

        <div className='fw-bold text-center text-gray-400 mb-6'>{description}</div>
        <Link to={link}>
          <span className='btn btn-sm btn-light'>
            <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3' />
            {button}
          </span>
        </Link>

      </div>
    </div>
  )
}

export { MainCard }
