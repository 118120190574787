import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import * as authRedux from './modules/auth/redux/AuthRedux'

import io from 'socket.io-client'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../setup'
type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  const mapState = (state: RootState) => ({auth: state.auth})

  const dispatch = useDispatch()
  const user:any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <Routes />
              </AuthInit>
            </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
