/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { login } from '../redux/AuthCRUD'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'


const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
})

const initialValues = {
    email: '',
    password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {


    document.body.style.backgroundImage = "none"
    const intl = useIntl()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState<Boolean>(false)
    const [errorResponse, setErrorResponse] = useState(false)

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setErrorResponse(false)
            setLoading(true)
            setTimeout(() => {
                login(values.email, values.password)
                    .then(({ data: { user } }) => {
                        setLoading(false)
                        dispatch(auth.actions.login(user.token))

                    })
                    .catch(() => {
                        setLoading(false)
                        setErrorResponse(true)
                        setSubmitting(false)
                        setStatus('The login detail is incorrect')
                    })
            }, 1000)
        },
    })

    return (

        <form
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3' style={{ color: "#00015a" }}>{intl.formatMessage({ id: 'WELCOME.TO' })} Forstec</h1>
                <div className='text-gray-400 fw-bold fs-4'>
                    {intl.formatMessage({ id: 'NEW.HERE' })}{' '}
                    <Link to='/auth/registration' className='link-info fw-bolder' style={{ color: '#3bd3c7' }}>
                        {intl.formatMessage({ id: 'CREATE.ACCOUNT' })}
                    </Link>
                </div>
            </div>
            {errorResponse && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{intl.formatMessage({ id: 'AUTH.SIGNIN.ERROR' })}</div>
                </div>
            )}
            {/* begin::Heading */}

            {/* {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            ) : (
                <div className='mb-10 bg-light-info p-8 rounded'>
                    <div className='text-info'>
                        Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
                        continue.
                    </div>
                </div>
            )} */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'EMAIL' })}</label>
                <input
                    placeholder={intl.formatMessage({ id: 'EMAIL' })}
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.email}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                        {/* begin::Label */}
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}</label>
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'></label>
                        {/* end::Label */}

                    </div>
                    <div className='flex-stack mb-2 '>
                        {/* begin::Link */}
                        <Link
                            to='/auth/forgot-password'
                            className='link-info fs-6 fw-bolder'
                            style={{ width: '150px', color: "#3bd3c7" }}
                        >
                            {intl.formatMessage({ id: 'AUTH.FORGOT.TITLE' })}
                        </Link>
                        {/* end::Link */}
                    </div>
                </div>
                <div className="position-relative">
                    <input
                        placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}
                        type={visible ? 'text' : 'password'}
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.password && formik.errors.password,
                            },
                            {
                                'is-valid': formik.touched.password && !formik.errors.password,
                            }
                        )}
                    />
                    <span className="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6 cursor-pointer end-0" onClick={() => setVisible(!visible)}>
                        <KTSVG path={`/media/icons/duotune/general/${visible ? 'invisible' : 'visible'}.svg`} className='svg-icon-2x mt-1' />
                    </span>
                </div>
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container text-danger'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg text-white w-100 mb-5'
                    style={{ backgroundColor: "#00015a" }}
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label' >{intl.formatMessage({ id: 'AUTH.SIGNIN' })}</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            {intl.formatMessage({ id: 'PLEASE.WAIT' })}...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>

                {/* begin::Separator */}
                {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div> */}
                {/* end::Separator */}

                {/* begin::Google link */}
                {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
                    <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                        className='h-20px me-3'
                    />
                    {intl.formatMessage({ id: 'CONTINUE.WITH' })} Google
                </a> */}
                {/* end::Google link */}

                {/* begin::Google link */}
                {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
                    <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
                        className='h-20px me-3'
                    />
                    {intl.formatMessage({ id: 'CONTINUE.WITH' })} Facebook
                </a> */}
                {/* end::Google link */}

                {/* begin::Google link */}
                {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
                    <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
                        className='h-20px me-3'
                    />
                    {intl.formatMessage({ id: 'CONTINUE.WITH' })} Apple
                </a> */}
                {/* end::Google link */}
            </div>
            {/* end::Action */}
        </form>

    )
}
