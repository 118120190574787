/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout, AuthPage } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { RootState } from '../../setup'
import { MasterInit } from '../../_metronic/layout/MasterInit'
import { EmailVerification } from '../modules/email/EmailVerification'
import { TermsOfService } from '../modules/rules/TermsOfService'
import { PrivacyPolicy } from '../modules/rules/PrivacyPolicy'
import { PasswordReset } from '../modules/email/PasswordReset'

const Routes: FC = () => {

  const isAuthorized = useSelector<any>(({ auth }) => auth.user)
  const user: any = useSelector<RootState>(({ auth }) => auth.user)

  return (
    <>
      <Switch>
        <Route path='/candidate/confirmEmail/:token' component={EmailVerification} />
        <Route path='/confirmEmail/:token' component={EmailVerification} />
        <Route path='/candidate/users/forgotPassword/:token/:id' component={PasswordReset} />
        <Route path='/users/forgotPassword/:token/:id' component={PasswordReset} />
        <Route path='/terms-conditions' component={TermsOfService} />
        <Route path='/privacy-policy' component={PrivacyPolicy} />
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from='/auth' to='/' />
        )}

        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to='/auth/login' />
        ) :
          user?.status == "active" ?
            <MasterLayout>
              < PrivateRoutes />
            </MasterLayout> :
            <Redirect to="error/confirmEmail" />

        }
      </Switch>
      <MasterInit />
    </>
  )
}

export { Routes }
