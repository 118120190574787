import {FC} from 'react'

const ConfirmEmail: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-white mb-10'>Your Account is Not Activated Yet</h1>

      <div className='fw-bold fs-3 text-400 mb-15'>
        Not Activated! <br /> Please activate your account from the email that was sent t you.
      </div>
    </>
  )
}

export {ConfirmEmail}
