import React, { Suspense, lazy } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RootState } from '../../setup'
import { PageTitle } from '../../_metronic/layout/core'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'

export function PrivateRoutes() {





  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const SourcingPage = lazy(() => import('../modules/sourcing/SourcingPage'))
  const WorkflowPage = lazy(() => import('../modules/Workflow/WorkflowPage'))
  const JobPage = lazy(() => import('../modules/Job/JobPage'))
  const CandidatePage = lazy(() => import('../modules/candidate/CandidatePage'))
  const CompanyPage = lazy(() => import('../modules/company/AccountPage'))
  const Calendar = lazy(() => import('../modules/Job/components/Calendar'))

  return (
    <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path='/dashboard' component={DashboardWrapper} />
          <Route path='/account' component={AccountPage} />
          <Route path='/company' component={CompanyPage} />
          <Route path='/sourcing' component={SourcingPage} />
          <Route path='/workflow' component={WorkflowPage} />
          <Route path='/jobs' component={JobPage} />
          <Route path='/candidates' component={CandidatePage} />
          <Route path='/calendar' component={Calendar} />
          <Redirect from='/auth' to='/dashboard' />
          <Redirect exact from='/' to='/dashboard' />
          <Redirect to='error/404' />
        </Switch>
    </Suspense>
  )
}
