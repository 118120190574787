import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {getUserByToken} from './AuthCRUD'
import {RootState} from '../../../../setup'
import * as redux from '../../company/redux/CompanyRedux'
import io from 'socket.io-client'

import * as authRedux from '../../auth/redux/AuthRedux'



const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const user:any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)


  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data: user} = await getUserByToken()
          dispatch(props.fulfillUser(user))

        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (accessToken) {
      requestUser()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if(user)
    {
      
      dispatch(redux.actions.getCompany(user?.company_id))
      dispatch(redux.actions.getCategories(user?.company_id))
      dispatch(authRedux.actions.getNotifications({ field: "company_user_id", operand: "=", value: user?.id }))
      dispatch(redux.actions.getMetaData())
    }
  }, [user])
 /* const socket = io(`${process.env.REACT_APP_SOCKET_URL}`)
  socket.connect()
  
  socket.on('getNotifications', (data) => {
    if(user?.id)
    dispatch(authRedux.actions.getNotifications({ field: "company_user_id", operand: "=", value: user?.id }))
  })*/
  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
