/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import { register, getRoles } from '../redux/AuthCRUD'
import { Link, useHistory } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { useIntl } from 'react-intl'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  company_name: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  company_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Company Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {


  const intl = useIntl()
  const history = useHistory()
  const [role, setRole] = useState({
    id: null,
    name: null
  })
  const [apiMessage, setApiMessage] = useState({
    error: false,
    success: false,
    message: ''
  })

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        register(values.email, values.firstname, values.lastname, values.password, values.changepassword, values.company_name, role?.id)
          .then(({ data }) => {
            setLoading(false)
            setApiMessage({ success: true, error: false, message: data.message })
            formik.resetForm()
            //dispatch(auth.actions.register(api_token))
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setApiMessage({ success: false, error: true, message: e.message })
          })
      }, 1000)
    },
  })


  useEffect(() => {
    PasswordMeterComponent.bootstrap();
    getRoles().then(res => {
      let role = res.data.data.find((el: any) => el.name === 'admin')
      if (role.id) {
        setRole({ id: role.id, name: role.name })
      }
    })
  }, []);
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'CREATE.ACCOUNT' })}</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          {intl.formatMessage({ id: 'ALREADY.HAVE.ACCOUNT' })}
          <Link to='/auth/login' className='fw-bolder text-primary' style={{ marginLeft: '5px' }}>
            {intl.formatMessage({ id: 'AUTH.FORGOT.TITLE' })}
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* begin::Action */}
      {/* <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        {intl.formatMessage({ id: 'SIGNIN.WITH' })} Google
      </button> */}
      {/* end::Action */}

      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      {apiMessage.error && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{intl.formatMessage({ id: 'AUTH.LOGIN.ERROR' })}</div>
        </div>
      )}
      {apiMessage.success && (
        <div className='mb-10 bg-light-success p-8 rounded'>
          <div className='text-info fw-normal'>{apiMessage.message + intl.formatMessage({ id: 'CHECK.EMAIL' })}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <div className='fv-row mb-5'>

            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: 'FIRST.NAME' })}</label>
            <input
              placeholder={intl.formatMessage({ id: 'FIRST.NAME' })}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('firstname')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.firstname && formik.errors.firstname,
                },
                {
                  'is-valid': formik.touched.firstname && !formik.errors.firstname,
                }
              )}
            />
            {formik.touched.firstname && formik.errors.firstname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span style={{ color: 'red' }} className='error' role='alert'>{formik.errors.firstname}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: 'LAST.NAME' })}</label>
            <input
              placeholder={intl.formatMessage({ id: 'LAST.NAME' })}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastname')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.lastname && formik.errors.lastname,
                },
                {
                  'is-valid': formik.touched.lastname && !formik.errors.lastname,
                }
              )}
            />
            {formik.touched.lastname && formik.errors.lastname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span style={{ color: 'red' }} role='alert'>{formik.errors.lastname}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group COmpany Name */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: 'COMPANY.NAME' })}</label>
        <input
          placeholder={intl.formatMessage({ id: 'COMPANY.NAME' })}
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('company_name')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.company_name && formik.errors.company_name },
            {
              'is-valid': formik.touched.company_name && !formik.errors.company_name,
            }
          )}
        />
        {formik.touched.company_name && formik.errors.company_name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span style={{ color: 'red' }} role='alert'>{formik.errors.company_name}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: 'EMAIL' })}</label>
        <input
          placeholder={intl.formatMessage({ id: 'EMAIL' })}
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span style={{ color: 'red' }} role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span style={{ color: 'red' }} role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className="d-flex align-items-center mb-3"
            data-kt-password-meter-control="highlight"
          >
            <div
              className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
            ></div>
            <div
              className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
            ></div>
            <div
              className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
            ></div>
            <div
              className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"
            ></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className="text-muted">
        {intl.formatMessage({ id: 'DESC.PASSWORD' })}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: 'CONFIRM.PASSWORD' })}</label>
        <input
          type='password'
          placeholder={intl.formatMessage({ id: 'CONFIRM.PASSWORD' })}
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span style={{ color: 'red' }} role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            {intl.formatMessage({ id: 'I.AGREE' })}{' '}
            <Link to='/terms-conditions' target="_blank" rel="noopener noreferrer" className='ms-1 link-info'>
            {intl.formatMessage({ id: 'TERMS.CONDITIONS' })}
            </Link>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span style={{ color: 'red' }} role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          style={{ backgroundColor: "#00015a" }}
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'AUTH.REGISTER.TITLE' })}{' '}</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              {intl.formatMessage({ id: 'PLEASE.WAIT' })}...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-secondary text-white w-100 mb-5'
            style={{ color: "#00013e" }}
          >
            {intl.formatMessage({ id: 'CANCEL' })}
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
