import { Action } from '@reduxjs/toolkit'
import { userInfo } from 'os'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import { getSites } from '../../../../setup/repository/sharedAPI'
import { CompanyModel } from '../models/CompanyModel'
import { createSite, deleteSite, editCompany, getCategories, getCompany, getMetaData, updateSite } from "./CompanyCRUD";

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    getCompany: '[getCompany] Action',
    getCompanySucceded: '[getCompanySucceded] Action',
    getCompanyFailed: '[getCompanyFailed] Action',
    editCompany: '[editCompany] Action',
    editCompanySucceded: '[editCompanySucceded] Action',
    editCompanyFailed: '[editCompanyFailed] Action',
    getMetaData: '[getMetaData] Action',
    getMetaDataSucceded: '[getMetaDataSucceded] Action',
    getMetaDataFailed: '[getMetaDataFailed] Action',
    createMetaData: '[createMetaData] Action',
    createMetaDataSucceded: '[createMetaDataSucceded] Action',
    getCategories: '[getCategories] Action',
    getCategoriesSucceded: '[getCategoriesSucceded] Action',
    getCategoriesFailed: '[getCategoriesFailed] Action',
    createCategorie: '[createCategorie] Action',
    createCategorieSucceded: '[createCategorieSucceded] Action',
    createSite: '[createSite] Action',
    createSiteSucceded: '[createSiteSucceded] Action',
    createSiteFailed: '[createSiteFailed] Action',
    deleteSite: '[deleteSite] Action',
    deleteSiteSucceded: '[deleteSiteSucceded] Action',
    deleteSiteFailed: '[deleteSiteFailed] Action',
    updateSite: '[updateSite] Action',
    updateSiteSucceded: '[updateSiteSucceded] Action',
    updateSiteFailed: '[updateSiteFailed] Action',
    getSites: '[getSites] Action',
    getSitesSucceded: '[getSitesSucceded] Action',
    getSitesFailed: '[getSitesFailed] Action',
    resetMessages: '[resetMessages] Action',
    resetMessagesSucceded: '[resetMessagesSucceded] Action',
}

const initialAuthState: CompanyState = {
    company: undefined,
    metaData: [],
    categories: [],
    sites: [],
    error: '',
    success: '',
    loading: false
}

export interface CompanyState {
    company?: any
    metaData?: any
    categories?: any
    sites?: any
    error: string,
    success: string,
    loading: boolean
}

export const reducer = persistReducer(
    { storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken'] },
    (state: any = initialAuthState, action: ActionWithPayload<any>) => {
        switch (action.type) {
            case actionTypes.getCompanySucceded: {
                const company = action.payload
                return { ...state, company }
            }
            case actionTypes.createSite: {
                return { ...state, error: '', success: '', loading: true }
            }
            case actionTypes.createSiteSucceded: {
                const site = action.payload
                return { ...state, sites: [...state.sites, site], success: "create", loading: false }
            }
            case actionTypes.createSiteFailed: {
                return { ...state, error: "create", loading: false }
            }
            case actionTypes.updateSite: {
                return { ...state, error: '', success: '', loading: true }
            }
            case actionTypes.updateSiteSucceded: {
                const site = action.payload
                return { ...state, sites: [...state.sites.filter((el: any) => el.id !== site.id), site], success: "update", loading: false }
            }
            case actionTypes.updateSiteFailed: {
                return { ...state, error: "update", loading: false }
            }
            case actionTypes.deleteSite: {
                return { ...state, error: '', success: '', loading: true }
            }
            case actionTypes.deleteSiteSucceded: {
                const site_id = action.payload
                return { ...state, sites: state.sites.filter((site: any) => site.id !== site_id), success: "delete", loading: false }
            }
            case actionTypes.deleteSiteFailed: {
                return { ...state, error: "delete", loading: false }
            }
            case actionTypes.getSites: {
                return { ...state, loading: true }
            }
            case actionTypes.getSitesSucceded: {
                const sites = action.payload
                return { ...state, sites: sites, success: 'get', loading: false }
            }
            case actionTypes.getSitesFailed: {
                return { ...state, error: 'get', loading: false }
            }
            case actionTypes.getMetaDataSucceded: {
                const metaData = action.payload.data
                return { ...state, metaData }
            }
            case actionTypes.createMetaDataSucceded: {
                const metaData = action.payload
                return { ...state, metaData: [...state.metaData, metaData] }
            }
            case actionTypes.createCategorieSucceded: {
                const category = action.payload
                return { ...state, categories: [...state.categories, category] }
            }
            case actionTypes.getCategoriesSucceded: {
                const categories = action.payload.data
                return { ...state, categories }
            }
            case actionTypes.editCompanySucceded: {
                const company = action.payload.data
                return { ...state, company, success: 'update_company' }
            }
            case actionTypes.editCompanyFailed: {
                return { ...state, error: 'update_company' }
            }
            case actionTypes.resetMessagesSucceded: {
                return { ...state, error: '', success: '', loading: false }
            }
            default:
                return state
        }
    }
)

export const actions = {
    getCompanySucceded: (data: any) => ({ type: actionTypes.getCompanySucceded, payload: data }),
    getCompany: (id: any) => ({ type: actionTypes.getCompany, payload: id }),
    getMetaDataSucceded: (data: any) => ({ type: actionTypes.getMetaDataSucceded, payload: data }),
    getMetaData: () => ({ type: actionTypes.getMetaData }),
    getCategoriesSucceded: (data: any) => ({ type: actionTypes.getCategoriesSucceded, payload: data }),
    createMetaDataSucceded: (data: any) => ({ type: actionTypes.createMetaDataSucceded, payload: data }),
    createMetaData: (data: any) => ({ type: actionTypes.createMetaData, payload: data }),
    getCategories: (data:any) => ({ type: actionTypes.getCategories, payload: data }),
    createCategorieSucceded: (data: any) => ({ type: actionTypes.createCategorieSucceded, payload: data }),
    createCategorie: (data: any) => ({ type: actionTypes.createCategorie, payload: data }),
    editCompany: (data: any) => ({ type: actionTypes.editCompany, payload: data }),
    editCompanySucceded: (data: any) => ({ type: actionTypes.editCompanySucceded, payload: data }),
    editCompanyFailed: () => ({ type: actionTypes.editCompanyFailed }),
    createSiteSucceded: (data: any) => ({ type: actionTypes.createSiteSucceded, payload: data }),
    createSiteFailed: () => ({ type: actionTypes.createSiteFailed }),
    createSite: (data: any) => ({ type: actionTypes.createSite, payload: data }),
    deleteSiteSucceded: (id: any) => ({ type: actionTypes.deleteSiteSucceded, payload: id }),
    deleteSiteFailed: () => ({ type: actionTypes.deleteSiteFailed }),
    deleteSite: (id: any) => ({ type: actionTypes.deleteSite, payload: id }),
    updateSiteSucceded: (data: any) => ({ type: actionTypes.updateSiteSucceded, payload: data }),
    updateSiteFailed: () => ({ type: actionTypes.updateSiteFailed }),
    updateSite: (data: any) => ({ type: actionTypes.updateSite, payload: data }),
    getSitesSucceded: (data: any) => ({ type: actionTypes.getSitesSucceded, payload: data }),
    getSitesFailed: () => ({ type: actionTypes.getSitesFailed }),
    getSites: (data: any) => ({ type: actionTypes.getSites, payload: data }),
    resetMessages: () => ({ type: actionTypes.resetMessages }),
    resetMessagesSucceded: () => ({ type: actionTypes.resetMessagesSucceded }),

}

export function* saga() {
    yield takeEvery(actionTypes.getCompany, function* getCompanySaga(id: any) {
        const { data } = yield getCompany(id?.payload)
        yield put(actions.getCompanySucceded(data))
    })
    yield takeEvery(actionTypes.createCategorie, function* createCategorieSaga(data: any) {
        yield put(actions.createCategorieSucceded(data?.payload))
    })
    yield takeEvery(actionTypes.createMetaData, function* createMetaDataSaga(data: any) {
        yield put(actions.createMetaDataSucceded(data?.payload))
    })
    yield takeEvery(actionTypes.createSite, function* createSiteSaga(site: any) {
        try {
            const { data } = yield createSite(site?.payload)
            yield put(actions.createSiteSucceded(data))
        } catch (e) {
            yield put(actions.createSiteFailed())
        }

    })
    yield takeEvery(actionTypes.updateSite, function* updateSiteSaga(site: any) {
        try {
            const { data } = yield updateSite(site?.payload.id, site?.payload.site)
            yield put(actions.updateSiteSucceded(data))
        } catch (e) {
            yield put(actions.updateSiteFailed())
        }

    })
    yield takeEvery(actionTypes.deleteSite, function* deleteSiteSaga(id: any) {
        try {
            const { data } = yield deleteSite(id?.payload)
            yield put(actions.deleteSiteSucceded(id?.payload))
        } catch (e) {
            yield put(actions.deleteSiteFailed())
        }

    })
    yield takeEvery(actionTypes.getSites, function* getSitesSaga(filter: any) {
        try {
            const { data: { data } } = yield getSites(filter?.payload?.field, filter?.payload?.operand, filter?.payload?.value)
            yield put(actions.getSitesSucceded(data))
        } catch (e) {
            yield put(actions.getSitesFailed())
        }

    })
    yield takeEvery(actionTypes.getMetaData, function* getMetaDataSaga(id: any) {
        const { data } = yield getMetaData()
        yield put(actions.getMetaDataSucceded(data))
    })
    yield takeEvery(actionTypes.getCategories, function* getCategoriesSaga(id: any) {
        const { data } = yield getCategories(id?.payload)
        yield put(actions.getCategoriesSucceded(data))
    })
    yield takeEvery(actionTypes.editCompany, function* editCompanySaga(data: any) {
        try {
            const createdData: {} = yield editCompany(data?.payload.id, data?.payload.values)
            const companyData: {} = yield getCompany(data?.payload.id)
            yield put(actions.editCompanySucceded(companyData))
        } catch (e) {
            yield put(actions.editCompanyFailed())
        }
    })
    yield takeEvery(actionTypes.resetMessages, function* resetMessagesSaga() {
        yield put(actions.resetMessagesSucceded())
    })
}
