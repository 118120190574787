import { FC, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { confirmCandidateEmail, confirmEmail } from '../../../setup/repository/sharedAPI'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const Card: FC = () => {
  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const [apiMessage, setApiMessage] = useState({
    error: false,
    success: false,
  })
  const params: any = useParams()
  const token = params.token
  const history = useHistory()

  const handelConfirmEmail = () => {
    setLoading(true)
    if (location.pathname.includes('candidate'))
      confirmCandidateEmail(token)
        .then(() => {
          setLoading(false)
          setApiMessage({ ...apiMessage, success: true })
        })
        .catch(() => {
          setLoading(false)
          setApiMessage({ ...apiMessage, error: true })
        })
    else
      confirmEmail(token)
        .then(() => {
          setLoading(false)
          setApiMessage({ ...apiMessage, success: true })
          setTimeout(() => history.push('/auth'), 3000)
        })
        .catch(() => {
          setLoading(false)
          setApiMessage({ ...apiMessage, error: true })
        })
  }
  return (
    <>
      <div
        style={{
          fontFamily: 'Arial,Helvetica,sans-serif',
          lineHeight: '1.5',
          fontWeight: 'normal',
          fontSize: '15px',
          color: '#2F3044',
          minHeight: '100%',
          margin: '0',
          padding: '0',
          width: '100%',
          backgroundColor: '#edf2f7',
        }}
      >
        <table
          width='100%'
          style={{ borderCollapse: 'collapse', margin: '0 auto', padding: '0', maxWidth: '600px' }}
        >
          <tbody>
            <tr>
              <td align='center' style={{ textAlign: 'center', padding: '40px' }}>
                <a rel='noopener' target='_blank'>
                  <img alt='Logo' width={"50%"}  src={toAbsoluteUrl('/media/logos/forstec2.png')} />
                </a>
              </td>
            </tr>
            <tr>
              <td align='left'>
                <div
                  style={{
                    textAlign: 'left',
                    margin: '0 20px',
                    padding: '40px',
                    backgroundColor: '#ffffff',
                    borderRadius: '6px',
                  }}
                >
                  <div style={{ paddingBottom: '30px', fontSize: '17px' }}>
                    <strong>Welcome to Forstec!</strong>
                  </div>
                  {apiMessage.error && (
                    <div className='mb-lg-15 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>
                        There was a problem activating your account please try again later
                      </div>
                      <div className='alert-text font-weight-bold'>
                        لقد وقع خطأ في تفعيل حسابك يرجى إعادة المحاولة
                      </div>
                    </div>
                  )}
                  {apiMessage.success && (
                    <div className='mb-10 bg-light-success p-8 rounded'>
                      {location.pathname.includes('candidate') ? (
                        <>
                          <div className='text-info fw-normal'>
                            You activated Your Account Successfully
                          </div>
                          <div>
                            ! لقد تم تفعيل حسابك بنجاح
                          </div>
                        </>
                      ) : (
                        <div className='text-info fw-normal'>
                          You activated Your Account Successfully you will redirect to Login page
                        </div>

                      )}
                    </div>
                  )}
                  <div style={{ paddingBottom: '30px' }}>
                    To activate your account, please click on the button below to verify your email
                    address. Once activated, you’ll have full access to our platform.
                  </div>
                  <div dir='auto' style={{ paddingBottom: '30px', float: "right" }}>
                    مرحبا بك في فرصتك!<br/>
                    لتفعيل حسابك، الرجاء الضغط على زر التفعيل.  بمجرد التفعيل، تكون قادرا على استعمال جميع خاصيات المنصة.

                  </div>
                  <div style={{ paddingBottom: '40px', textAlign: 'center' }}>
                    <button
                      onClick={handelConfirmEmail}
                      style={{
                        textDecoration: 'none',
                        display: 'inlineBlock',
                        textAlign: 'center',
                        padding: '0.75575rem 1.3rem',
                        fontSize: '0.925rem',
                        lineHeight: '1.5',
                        borderRadius: '0.35rem',
                        color: '#ffffff',
                        backgroundColor: '#009EF7',
                        border: '0px',
                        marginRight: '0.75rem!important',
                        fontWeight: '600!important',
                        outline: 'none !important',
                        verticalAlign: 'middle',
                      }}
                    >
                      {!loading && <span className='indicator-label'>فعِّل حسابك</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          يرجى الإنتظار...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  
                  <div style={{ borderBottom: '1px solid #eeeeee', margin: '15px 0' }}></div>

                  <div style={{ paddingBottom: '10px' }}>
                    Kind regards, Forstec Team.
                    <tr>
                      <td
                        align='center'
                        style={{
                          fontSize: '13px',
                          textAlign: 'center',
                          padding: '20px',
                          color: '#6d6e7c',
                        }}
                      >
                        <p>Floor 5, Ben Aoun Jawhra, Sousse, 4000, Tunisia.</p>
                        <p>
                          Copyright 2022 © 
                          <a rel='noopener' target='_blank'>
                          &nbsp; Forstec
                          </a>
                          .
                        </p>
                      </td>
                    </tr>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export { Card }
