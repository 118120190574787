/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const PrivacyPolicy: React.FC = () => {
  const history = useHistory()

  return (
    <>
      <div
        style={{
          fontFamily: 'Arial,Helvetica,sans-serif',
          lineHeight: '1.5',
          fontWeight: 'normal',
          fontSize: '15px',
          color: '#2F3044',
          minHeight: '100%',
          margin: '0',
          padding: '0',
          width: '100%',
          backgroundColor: '#edf2f7',
        }}
      >
        <table
          width='100%'
          style={{ borderCollapse: 'collapse', margin: '0 auto', padding: '0', maxWidth: '70vw' }}
        >
          <tbody>
            <tr>
              <td align='center' style={{ textAlign: 'center', padding: '40px' }}>
                <a rel='noopener' target='_blank'>
                  <img alt='Logo' src={toAbsoluteUrl('/media/logos/LogoMenuRsk.png')} />
                </a>
              </td>
            </tr>
            <tr>
              <td align='left'>
                <div
                  style={{
                    textAlign: 'left',
                    margin: '0 20px',
                    padding: '40px',
                    backgroundColor: '#ffffff',
                    borderRadius: '6px',
                  }}
                >

                  
                  <div className='mb-2 fs-1'>
                    <strong>Privacy Policy</strong>
                  </div>

                  <div className='mb-10 fw-bold fs-5'>Last Updated: April 25, 2022</div>

                  <div className='mb-3 fs-4 fw-bolder'>1. Introduction</div>

                  <div className='mb-10'>
                    We created this summary of Forstec’s Full Privacy Policy to help guide you
                    through it and keep you informed about how we handle your information. Our
                    Privacy Policy details how we collect, use and disclose the Personal and Non-
                    Personal Data we Our Privacy Policy details how we collect, use and disclose the
                    Personal and Non-Personal Data we collect from and about you when you access or
                    use our online and/or mobile websites, applications, services, and software,
                    interactions with us on the phone or in person, or that we obtain from publicly
                    available sources or third-party sources. Forstec’s core mission is to help
                    people get jobs and help Employers find great candidates. Since each of our
                    affiliates shares that core mission, we have developed a Privacy Center as a way
                  </div>

                  <div className='mb-2 fs-4 fw-bolder'>
                    2. Who is responsible for your information?
                  </div>

                  <div className='mb-10'>
                    The Forstec entity under RSK Solutions responsible for your information will
                    depend on your location and whether we are acting as a data controller or data
                    processor pursuant to section 1. of Forstec’s full Privacy Policy, below. Please
                    check the table below to determine the entity responsible.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>
                    3. What information do we collect about you?
                  </div>
                  <div className='mb-10'>
                    Across the Sites we collect information: - you choose to provide to us through
                    our Sites, e.g. contact details, resume details, location data;  through your
                    activity on our Sites, e.g. information from your device and actions taken on
                    the Sites, searches you run or jobs you click on; - when you apply to jobs, use
                    screener or assessment questions or automated phone screening, or log in from
                    third party sites; and where you use chargeable services or are an Employer
                    providing necessary Employer information, e.g., for verification purposes.
                    Forstec may also collect information about your interactions with our social
                    media accounts on third-party platforms, or by obtaining data such as resumes,
                    prospect data or recruiting progress data from third parties. Full details of
                    the types of Personal Data we collect from you and details of the legal basis
                    for such collection are detailed in section 2 of our full Privacy Policy.
                  </div>

                  <div className='mb-2 fs-6 fw-bolder'>4. Why do we collect this information?</div>
                  <div className='mb-10'>
                    We collect and use your information in order to help Job Seekers find jobs, to
                    help Employers find candidates, and to provide and improve our services to you.
                    To summarize, we use your Personal Data, where applicable, to: - create your
                    account and send you Job Alerts or other promotional materials; - give you
                    relevant search results and job recommendations; - facilitate communication as
                    part of the job search, application and interview process; - make your resume
                    available to third parties, depending on your privacy settings (e.g. private or
                    public resume); - match Job Seekers with Employer Job Listings; - prevent fraud,
                    spam and other potentially fraudulent or illegal activities; - facilitate
                    payment, in connection with chargeable services; - carry out automated
                    processing in order to provide many of our services; - enhance and optimize our
                    services and security for Job Seekers and Employers by data sharing with our
                    affiliates; - improve our services, maintain product quality and protect users;
                    and - aggregate and share with third parties content posted on Forstec intended
                    to be publicly available. Full details of the purposes for which we use your
                    Personal Data are set out in sections 4, 5 and 9 of our full Privacy Policy.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>5. Who is this information shared with?</div>
                  <div className='mb-10'>
                    Your information is shared with: - Forstec under RSK Solutions affiliates; -
                    Employers and Job Seekers (e.g., if a Job Seeker decides to apply for a job); -
                    third-party service providers who help us with our operations, e.g. for data
                    storage or fraud detection. Please see sections 4, 8, 9 and 11 of our full
                    Privacy Policy for more details.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>
                    6. How long do we store your information?
                  </div>
                  <div className='mb-10'>
                    Forstec stores your Personal Data until it is no longer necessary to provide our
                    services, or until you ask us to delete your Personal Data. We may also be
                    required to retain certain information in order to comply with local laws. If
                    you wish to delete your Personal Data, you should submit a deletion request.
                    Please see section 2 of our full Privacy Policy for further information on how
                    long we store your data.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>
                    7. What are your rights with respect to your Personal Data?
                  </div>
                  <div className='mb-10'>
                    Forstec takes your privacy very seriously. We provide GDPR-type rights to all
                    our users globally. This includes the rights to access your Personal Data;
                    delete your Personal Data; get your Personal Data in a portable format; and to
                    restrict or object to certain processing of your Personal Data. Where our use of
                    your Personal Data is based on your consent, you also have the right to withdraw
                    that consent. Please see section 10 of our full Privacy Policy for further
                    information in respect of your rights and how they can be exercised. You also
                    have the right to complain to your local data protection supervisory authority
                    about Forstec’s use of your data as detailed in section 12 of our full Privacy
                    Policy. If you are located in the US, you may have specific privacy rights which
                    can be found in section 13 of our full Privacy Policy.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>8. Contacting us</div>
                  <div className='mb-10'>
                    To contact our Data Protection Officer please email privacy-dept @ Forstec.com
                    (remove spaces when sending email).
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>
                    <strong>Forstec’s Full Privacy Policy</strong>
                  </div>
                  <strong>
                    Please note that Forstec and its affiliates are directly or indirectly owned by
                    a publicly traded parent company, RSK Solutions.
                  </strong>
                  <div className='mb-2 fs-6 fw-bolder'>1. Introduction</div>
                  <div className='mb-10'>
                    Forstec values the trust our users and customers place in us when they give us
                    access to their Personal Data. The Privacy Policy describes how we work to
                    maintain that trust and protect that information. In particular, this Privacy
                    Policy details how we collect, use and disclose personal and non-personal data
                    you give to us when you access or use the online and/or mobile services, mobile
                    applications and websites, and software offered by Forstec and its related
                    brands covered by this Privacy Policy. Our core mission is to help people get
                    jobs, and help employers find great candidates. Since each of our Affiliates (as
                    detailed in section 4.14) shares this core mission, we developed a Privacy
                    Center as a way of educating our users about our affiliates, and how we share
                    data with them to provide the strongest product and service offerings to our
                    users.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>
                    1.1 The Forstec Entities Responsible for Your Information
                  </div>
                  <div className='mb-10'>
                    This section determines which Forstec entity acts as the controller or processor
                    of your Personal Data. Forstec is the controller of all Personal Data processed
                    pursuant to this Privacy Policy, except as otherwise expressly stated below in
                    this section 1.1. Please also note that the contracting entity indicated in our
                    Terms of Service may differ from the data controller indicated in this Privacy
                    Policy. – Forstec as Processor Forstec offers certain hiring services to
                    Employers where Forstec acts as a data processor, or similarly, under applicable
                    data protection laws, and processes the personal data it collects from and about
                    you only on the Employer’s behalf, subject to our Terms. This applies to our
                    Candidate Import service, where we help Employers contact, interview and manage
                    job applicants that do not apply through our Sites, and other service offerings
                    that help Employers manage the hiring process. If such job applicants create/log
                    into an Forstec account, Forstec may associate their personal data collected
                    through these hiring services with their Forstec account based on the
                    instructions by Employers. For example, if a Job Seeker creates/logs into an
                    Forstec account, they may view, within their Forstec account, the history of the
                    messages and interviews which took place between the Job Seeker and Employers
                    previously interacted with by using these services. If you have any questions
                    about Forstec’s processing of your personal data as part of these services, or
                    to exercise any rights you may have with respect to such information, please
                    contact the Employer to which youapplied. Forstec may also act as a data
                    processor on behalf of its affiliates (e.g. for billing purposes). The remainder
                    of our processing activities covered by this Privacy Policy does not apply to
                    Personal Data that we collect through these services.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>1.2 Some Important Definitions</div>
                  <div className='mb-10'>
                    Personal data (“Personal Data”) refers to any information that relates to an
                    identified or identifiable individual, and non-personal data (“Non-Personal
                    Data”) refers to any information that does not identify a specific individual.
                    Please note that at all times Forstec will adhere to the applicable statutory
                    definition in determining what is and is not Personal Data for the purposes of
                    this Privacy Policy. Where this Privacy Policy refers to “information” or “your
                    information” this may include both Personal and Non-Personal Data. The Sites are
                    made available for use by individuals searching for employment openings, or
                    services or information related to their personal employment or job search (“Job
                    Seekers”). Where applicable, the Sites may also be made available to individuals
                    and/or organizations seeking information related to hiring or human resources or
                    seeking to make available information regarding employment openings (“Job
                    Listings” or “Job Ads”), on their behalf or other’s behalf, including but not
                    limited to agencies purchasing for multiple parties (“Employers”). Forstec
                    allows Job Seekers to include a number of different application materials to
                    Employers when they apply to a job. These can be found in the Profile section of
                    your Forstec account, and include materials such as your resume, your assessment
                    results and your answers to screener questions (“Forstec Profile”). When
                    referring solely to your resume information, whether an Forstec resume or a
                    resume that you upload yourself, this will be referred to simply as “resume”.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>
                    1.3 The Scope of Forstec’s Privacy Policy
                  </div>
                  <div className='mb-10'>
                    This Privacy Policy covers data that we collect through the Sites, or that we
                    obtain from third parties. In cases where you follow a link to a third-party
                    website, you are no longer covered by our Privacy Policy, and any data
                    collection or processing activity that takes place on that website will be
                    governed by that website’s own privacy policy. We only collect data on the Sites
                    that display a direct link to this Privacy Policy. Occasionally, we may refer to
                    this Privacy Policy in notices on the Sites, including special purpose web pages
                    or mobile applications, including invitations for you to submit ideas to improve
                    the Sites. Forstec may amend this Privacy Policy at any time. If you applied to
                    a Job Listing, please consult the Job Listing for additional information
                    applicable to you. Current and former Forstec employees should contact their
                    human resources coordinators for applicable policies.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>1.4 Changes to the Privacy Policy</div>
                  <div className='mb-10'>
                    Please review the “Last Updated” legend at the top of this page to determine
                    when this Privacy Policy was last amended. Any changes to this Privacy Policy
                    will become effective on the “Last Updated” date indicated above. By using the
                    Sites or providing information to us following such changes, you will have
                    accepted the amended Privacy Policy. If Forstec is going to use Personal Data
                    collected through the Sites in a manner materially different from that stated at
                    the time of collection, then Forstec will notify users via email and/or by
                    posting a notice on the applicable Sites for 30 days prior to such use or by
                    other means as required by law.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>2. Information Collected</div>
                  <div className='mb-10'>
                    You provide different types of information in order for Forstec to provide our
                    Sites’ services, products and features to you.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>2.1 Information You Provide to Forstec</div>
                  <strong>From Your Account</strong>
                  <div className='mb-10'>
                    We collect data from you when you create an account, request information,
                    subscribe for a service, participate in a survey, post a rating or review, post
                    a question or answer, upload or build a resume, complete an Forstec Assessment,
                    update your job preferences, upload content, or otherwise actively send us data
                    on our Sites. This may include, but is not limited to, your username, password,
                    first and last name, email address, telephone number (including mobile phone
                    number), street address, gender, occupation, interests, achievements, career
                    goals, professional certification or license numbers, messages you send to
                    users, your desired work schedule or salary and other job preferences, and any
                    other data included in your account, Forstec Profile, answers to screener
                    questions submitted to Forstec or to Employers through Forstec, and answers you
                    give to any questionnaires on our Site or sent to you. All of this information
                    may be associated with your Forstec account. You may provide your specific
                    location data when you provide it yourself on the Forstec Apps (as defined in
                    the Forstec Terms of Service Introduction) to help you find more relevant Job
                    Listings. Forstec seeks your consent at the time of such provision, and such
                    processing will only be performed where consent is secured. Some location data
                    the Forstec Apps collect is linked to your account. Other location data the
                    Forstec Apps collect is not linked to your account (for example, “precise”
                    location data collected by an iOS app) but is still used by Forstec. If you
                    create an Forstec account, Forstec may also create and assign to your device an
                    identifier that is similar to an account number. We may collect the name you
                    have associated with your device, device type, telephone number, country, and
                    any other information you choose to provide, such as username, email address, or
                    zip code. For example, while using a mobile device to access Forstec, Forstec
                    may provide a Job Seeker with relevant Job Listings that are located within the
                    same zip code as where this activity is taking place.
                    <strong>Through Your Activity on the Sites</strong>
                    In addition, as part of the standard and necessary operation of the Sites,
                    Forstec may collect, analyze, process, store, and record in real-time and
                    analyze any and all of your activity on, interaction with, and communications on
                    or through the Sites, including, but not limited to, by collecting, analyzing,
                    processing, storing, and recording the areas of the Sites you visit (such as
                    specific URLs), whether and where you click, scroll, hover- over, mouseover, or
                    otherwise interact with or communicate with or through our Sites, and when and
                    for how long each such activity occurred. This also may include Forstec
                    collecting, analyzing, processing, storing, and recording information from and
                    about your computer or mobile device and your actions taken on the Sites,
                    including, but not limited to, Your Resume, your searches, the titles of jobs
                    you search, click on or apply to, where those jobs are located, your salary
                    interest or experience, the general salary range or experience level of the jobs
                    you view (where indicated), the Job Listings you apply to, the date, time and
                    type of your activity on the Sites such as how often you update Your Resume or
                    look for jobs, session activity, browser type, operating system, type of device,
                    mobile device advertising identifier, MAC address, IP address, and any of your
                    other behavior or actions on, interaction with, or communications with or
                    through the Sites. The Site cannot work as Forstec intends it to work unless
                    Forstec undertakes such activities. If you do not want your actions to be
                    collected, processed, analyzed, or recorded this way, do not use the Site. For
                    Employers, this may also include actions taken with regard to a Job Listing or
                    to Job Seeker’s resume or application, such as pausing or closing a Job Listing,
                    opening, viewing or responding to a resume or application, or making a decision
                    with regard to a resume, application or Job Listing.
                    <strong>Through Your Activity on Forstec Hire</strong>
                    Employers may be contacted to take advantage of our Forstec Hire service, in
                    which case you may provide Forstec with additional information about hiring
                    needs. This allows Forstec to determine whether or not our Hire product is
                    suitable for an Employer’s needs. When Job Seekers apply for a job recommended
                    to them by Forstec Hire, Forstec facilitates certain aspects of the recruitment
                    process including, for example, negotiations between the Job Seeker and the
                    Employer, as applicable. As such, we collect and process any information
                    submitted to Forstec through this process, including but not limited to any
                    queries a Job Seeker may have about the job or interview, information about
                    progression through the recruitment process and information relating to salary.
                    <strong>When Using Automated Phone Screening</strong>
                    If you use any automated phone screening product, Forstec will record and store
                    your answers to an Employer’s screening questions and will forward your answers
                    to the Employer. Where you wish to use such a product, you will agree in advance
                    to Forstec listening to and analyzing the recording in accordance with this
                    Privacy Policy.
                    <strong>When Providing Employer Information</strong>
                    If you are an Employer, we may request that you provide certain information in
                    order to verify your account. This information may include, but may not be
                    limited to, yourFederal Employer Identification Number (EIN), Business
                    Registration information, or a utility bill, the name and email address of an
                    Employer representative. This information will be used for internal purposes
                    only, subject to any request by law enforcement or a court order. As an Employer
                    you may choose to enhance certain user-provided content on Forstec (for example
                    your hosted or claimed job posts, among other user-provided content) with rich
                    media such as photos and videos. This content may be uploaded directly to
                    Forstec, or it may be imported from other third-party sources such as social
                    media accounts or video sharing platforms. Rich media content imported in this
                    manner is stored by Forstec with your permission, and you may remove such
                    content at any time. Please note removing the media from the third-party site
                    (for example, Facebook) will not affect its display on your Forstec post. This
                    means that media imported from your social media accounts to Forstec will remain
                    on Forstec until you remove it from our platforms directly.
                    <strong>When Using Chargeable Services</strong>
                    In connection with chargeable services, we will also collect payment information
                    (e.g., the last four digits of credit, debit, or other payment card numbers and
                    related verification information). Please note that Forstec only has access to
                    the last four digits of credit, debit, or other payment card numbers, and does
                    not store full card numbers or related card security codes.
                    <strong>When Voluntarily Providing Demographic Information</strong>
                    In certain markets users can, entirely at their own choice, provide their
                    demographic information including age, race & ethnicity, gender, disability,
                    arrest or conviction record, and LGBTQ+ community membership to Forstec for
                    specific purpose(s) such as evaluating in our offerings which includes testing
                    for unfair bias. All collection is performed in accordance with data protection
                    law in the applicable market. A commitment to fairness is core to Forstec’s
                    mission. The provision of this information is entirely voluntary and will not be
                    used in any determination of your job application. The data provided will not be
                    displayed on your Forstec Profile. Forstec may share aggregate data (data
                    grouped together with personal identifiers removed) to employers to show them.
                    The data you provide is based on your consent at the time the information is
                    taken. If you choose to provide your demographic information, you can complete
                    as many of the data fields as you like when presented with an input form (you
                    can choose ‘prefer not to say’ for any of the fields that you don’t want to
                    complete). You can easily request to view, edit, or delete your demographic
                    information at any time. Please note that some employers are required by law to
                    ask each job applicant to provide their Demographic Information on a voluntary
                    basis, and you may see these questions after applying to jobs on Forstec. This
                    is a separate process to Forstec’s.
                    <strong>When Updating Information Provided to Forstec</strong>
                    When you share information with Forstec, or update information previously
                    provided to Forstec, including preferences such as making your resume on Forstec
                    or informationshared in the “About Me” section of your profile “public” or
                    “private,” it may take us a few days to reflect these changes on the Sites. From
                    the time you make these changes to the time we reflect them on our Sites, new
                    data may not appear and previously provided data may continue to show. As a
                    result, information you no longer choose to share publicly on our Site may
                    continue to be visible in our Resume Search product and elsewhere on our Sites
                    to Employers and other users.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>
                    2.2 Information Collected When You Apply for Jobs or Access Forstec from Some
                    Third-Party Websites
                  </div>
                  <div className='mb-10'>
                    <strong>When You Log In or Use Forstec from Some Third-Party Websites</strong>
                    You may also create an account or log in to your Forstec account using
                    third-party websites including, but not limited to, Facebook or Google. If you
                    do not already have an Forstec account but log in to Forstec using your Facebook
                    or Google account, this creates an Forstec account using the same email address
                    used in your Facebook or Google account. If you agree to provide this
                    information to Forstec, Facebook or Google will authenticate you and redirect
                    you to Forstec. Please note, when you log in to Forstec using your Facebook or
                    Google account, Facebook or Google will cookie you in order to authenticate you
                    as a Facebook or Google user. By accessing Forstec through your Facebook or
                    Google account, you understand that Facebook or Google will share certain data
                    detailed in the above paragraph for the purposes of authentication to permit you
                    to access Forstec in a secure manner. If you no longer wish to share data with
                    Forstec from your Facebook or Google account, you will need to adjust your
                    Facebook or Google account settings. Information passed from Facebook or Google
                    to Forstec will be considered Forstec account information for purposes of your
                    use of Forstec. You have the ability to disable the connection between your
                    Facebook or Google account and your Forstec account at any time by accessing
                    your privacy settings on your Facebook or Google account. Facebook or Google may
                    also ask for your permission to share certain other details with Forstec,
                    including but not limited to your name, profile picture, public profile
                    information, and email address. Once you give this permission, the requested
                    information will be shared with Forstec. This information will be used to
                    provide services to you, including populating your Forstec Profile on Forstec.
                    The shared information will remain associated with your Forstec Profile until
                    you modify or delete it.
                    <strong>Our Mobile Software</strong>
                    Forstec may also collect information about third-party websites you visit
                    through our mobile software. This could include information about Job Listings,
                    notes or application statuses that you submit, save, or update, or data
                    regarding how you engage with websites or other actions you take on such
                    websites, including URLs of websites you visit. We may also suggest information
                    from your Forstec Profile to include in applications you submit on third-party
                    sites and see whether you make changes tothese suggestions but not the changes
                    themselves. We do this in order to allow a Job Seeker to keep a record of
                    particular Job Listings of interest, to detect a job application on a
                    third-party site to give a Job Seeker the option to auto-fill parts of the form
                    using Forstec Resume, or to confirm an application.
                    <strong>When You Apply to Jobs on Some Third-Party Websites</strong>
                    When you apply for a job on certain third-party websites, Forstec may also
                    receive information about the status of your job application and the decisions
                    taken by the Employer such as whether the application reached the interview
                    stage and whether a job offer was accepted or rejected. This information helps
                    Forstec further understand the effectiveness of our Sites and its features.
                    Forstec’s use of this information is detailed in section 4 of this Privacy
                    Policy.
                    <strong>When You Interact with Sponsored Jobs</strong>
                    Forstec provides Employers with tools which help them manage, track and optimize
                    the performance of their advertising across a multitude of job advertising sites
                    and platforms in real-time (“Sponsored Jobs”). When you click on a Job Listing
                    powered by Sponsored Jobs, and when you start and complete a job application
                    generated by Sponsored Jobs on an Employer’s website or a website operated by an
                    Employer’s applicant tracking system, Forstec may collect certain information
                    about you and any actions taken by you during your visit using automated means,
                    such as cookies and web beacons. The information collected includes, for
                    example, information about your device, Job Listings viewed/clicked, job
                    applications started and completed, referring URLs and dates and times of site
                    visits.
                    <strong> When you Communicate with Forstec through Third-Party Websites</strong>
                    You may also have the ability to interact with Forstec through third-party
                    messaging services, apps and websites, such as Facebook Messenger and WhatsApp.
                    When you interact with Forstec through these third-party services, apps, and
                    websites, you acknowledge that your use of such services may be subject to
                    additional terms provided by the third parties that operate them, and that such
                    third parties may have access to your communications with Forstec, including
                    application data you submit to Forstec while you are on such platforms. PLEASE
                    NOTE THAT YOUR RELATIONSHIP WITH ALL THIRD-PARTY SERVICES, APPS, WEBSITES, AND
                    OTHER PLATFORMS (SUCH AS THOSE FROM FACEBOOK, OR GOOGLE, AND WHATSAPP) OR ANY
                    OTHER THIRD-PARTY WEBSITE IS GOVERNED SOLELY BY YOUR AGREEMENT WITH THIRD-PARTY
                    OPERATORS OF THOSE PLATFORMS.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>
                    2.3 Any Other Personal Data Forstec May Receive
                    from Third Parties
                  </div>
                  <div className='mb-10'>
                    Any Personal Data that Forstec may obtain from other third-party sources, including
                    data from sponsored advertisements that we have on third-party sites, will be processed
                    by Forstec in accordance with this Privacy Policy and all applicable laws.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>3. Job Match</div>
                  <div className='mb-10'>
                    Forstec may use Job Seekers’ application materials (including resumes and
                    responses to screener questions) and the recency of their activity on Forstec to
                    determine whether the words of their resume and answers to screener questions
                    match the words of your Job Listing or Resume query, and vice-versa. You further
                    agree and consent that Forstec may differentiate those matching resumes and
                    screener questions from those that do not match, and present them to you as
                    matches or not matches. Forstec may also use such information in order to
                    improve the Site or any other Forstec product or service (including by
                    displaying or otherwise making available potentially relevant Job Listings and
                    resumes to Job Seekers and Employers).
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>
                    2.4 Storage of Your Information
                  </div>
                  <div className='mb-10'>
                    Forstec stores your Personal Data until it is no longer necessary to provide our services
                    or until you ask us to delete your Personal Data. As one example, if an Employer
                    deletes a job application in their candidate dashboard on Forstec, Forstec may delete
                    information associated with that application. As another example, after an application
                    has been delivered to an Employer, Forstec may retain a copy of that application for a
                    limited time and then delete the copy. We are also required to retain certain information
                    in order to comply with local laws.
                    Forstec may also store the information detailed in this section 2 of the Privacy Policy on
                    its equipment or the equipment of third parties that Forstec has a relationship with. Such
                    storage services will be governed by appropriate protections, as required by applicable
                    rules.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>2.5 What Personal Data is Required</div>
                  <div className='mb-10'>
                    Personal Data may be required in order to provide particular Forstec services or
                    features to you. If Personal Data is required, it will be indicated at the time of
                    collection. If Personal Data is required and you choose not to provide it, then the
                    particular feature or service may not be available to you.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>2.6 The Legal Basis on Which Forstec Collects Your
                    Personal Data</div>
                  <div className='mb-10'>
                    Forstec collects and processes your Personal Data on the basis of different legal
                    grounds, depending on the nature of the Personal Data being provided and the type of
                    processing involved.
                    – Performance of a Contract
                    Some of the Personal Data processed by Forstec is performed on the basis that it is
                    necessary for the performance of our agreement with you, or in order to take steps at
                    the request of the user prior to entering such an agreement. An example of this would
                    be where Forstec sends a Job Seeker’s application to an Employer.
                    – Legitimate Interest
                    A second ground relied upon by Forstec for other types of processing of your Personal
                    Data is that it is necessary for the purposes of legitimate interests pursued by Forstec or
                    an Employer. Examples of such legitimate interests will include where Forstec sends
                    you marketing about our products and services, where we believe you have areasonable expectation that we will perform a particular type of processing on your
                    behalf, where such processing is strictly necessary for fraud detection and prevention,
                    or where we share your Personal Data with Forstec affiliates as detailed in section
                    4.14 below. Forstec will only rely on such a ground where an assessment has been
                    performed balancing the interests and rights involved and the necessity of the
                    processing in order to provide our services, products and features to you.
                    – Compliance with a Legal Obligation
                    A third ground relied upon for certain types of processing is that it is necessary in order
                    to allow Forstec to comply with a legal obligation. An example of this would be where
                    Forstec is required to retain business records for fixed periods of time in order to comply
                    with local legal requirements.
                    – Consent
                    Finally, in certain limited situations, Forstec relies on your consent in order to process
                    your Personal Data. Where Forstec requires your consent, we seek it at the time of
                    provision, and the processing will only be performed where consent is secured. For
                    example, your consent will be sought in certain instances where you initiate a marketing
                    message such as a Job Alert with Forstec (detailed in section 4 of this Privacy Policy).
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>3. Limitations of Use</div>
                  <div className='mb-2 fs-6 fw-bolder'>3.1 Where a Job Seeker Wants to Close a Job Seeker
                    Account</div>
                  <div className='mb-10'>
                    When a Job Seeker closes an account, the Job Seeker will no longer have access to
                    any of the data we make available under that account, including but not limited to,
                    search history, job search preferences, saved jobs, jobs the Job Seeker has applied to,
                    Job Alerts, Forstec Profile, and any other data associated with the account. If the Job
                    Seeker uses the same email address to set up a new account, this data from a previous
                    account will not become available to the Job Seeker again. Please also note that
                    closing your Job Seeker account may result in you losing access to some of our
                    products and the ability to utilize the “log in with Forstec” feature found on certain thirdparty sites.
                    Forstec reserves the right to keep any materials in a closed account as necessary to
                    preserve and protect its rights to the extent permitted by law (for example, to preserve
                    records of a dispute) or to comply with its obligations under local law (for example, if
                    requested to retain by law enforcement). A closed account does not mean that all of the
                    data is expunged from the Forstec systems, although it will not be readily available
                    under the Job Seeker’s original account. Forstec will begin to process that instruction
                    within 24 hours of the Job Seeker initiating the process. Forstec will complete the
                    operation within 40 days.
                    If a Job Seeker wishes to access or delete their Personal Data held by Forstec, they
                    should follow the steps listed in section 10 of this Privacy Policy
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>3.2 Where an Employer Wants to Disable an Employer
                    Account</div>
                  <div className='mb-10'>
                    Employers may disable an account at any time by contacting the Employer Help Center.
                    Please note that Forstec must preserve business records pertaining to that account to
                    comply with its obligations under law. Alternatively, if an Employer agent wishes to
                    access or delete their Personal Data held by Forstec, they should follow the steps listed
                    in section 10 of this Privacy Policy.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>3.3 Where a Job Seeker Wants to Delete a Job
                    Application</div>
                  <div className='mb-10'>
                    If a Job Seeker wants to delete a job application performed on the Sites, they are
                    advised to contact the prospective Employer directly to request deletion of their
                    application. Job seeker applications and materials submitted through the Sites,
                    including through Forstec Apply, may be controlled by the Employer. We may direct you
                    to that Employer for Personal Data deletion requests where that Personal Data is held
                    by that Employer.
                  </div>
                  <div className='mb-2 fs-6 f-bolder'>3.4 Where the Employer Contact Details Provided to
                    Forstec Are Incorrect</div>
                  <div className='mb-10'>
                    Forstec depends on the Employer or the Employer’s agent to provide Forstec with the
                    correct destination for all applications. In the event that the electronic destination
                    provided to Forstec is incorrect, your application materials will not be sent to the
                    intended recipient of the application. Forstec has no liability for such applications. If you
                    do not wish to send your application materials in this manner, you should send them
                    directly to the Employer.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>3.5 @Forstec mail Addresses</div>
                  <div className='mb-10'>
                    When communicating through Forstec’s message relay system, you will see an email
                    address with an @ Forstecemail.com or @ Forstecmail.com ending. You understand
                    that you retain no ownership rights to these email addresses, which are specific to
                    Employers’ Job Listings, but are owned by Forstec. However, Forstec’s relay system
                    will show the name associated with your account or job application in the “To:” or
                    “From:” field. In addition, the above shall apply to any email addresses added to a
                    thread when communicating via Forstec’s message relay system. Specifically, if you
                    choose to add additional recipients to an ongoing email thread through Forstec’s
                    message relay system, the additional recipient(s) will also be assigned @
                    Forstecemail.com or @ Forstecmail.com addresses as well. Please note that emails
                    which include an email address ending in @ Forsteemail.com or @ Forstecmail.com will
                    be stored on our systems. You should not send a message to an email address ending
                    in @ Forstecemail.com or @ Forstecmail.com if you do not wish for this to happen.
                    Please also note that since these message proxy and relay services depend on the
                    functionality of third-party providers, there may be technical delays on the part of those
                    email service providers.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>3.6 Minimum Age of our Users</div>
                  <div className='mb-10'>
                    The Sites are not for use by anyone under the age of 16. However if local laws provide
                    for an alternative minimum age for the provision of the Sites’ services to you, then that
                    shall apply as the applicable minimum age. In all jurisdictions outside the European
                    Union, if you are under the age of 18 or the age of majority in your jurisdiction, you must
                    use the Sites under the supervision of your parent, legal guardian or responsible adult.
                    We adopt a range of measures to try to ensure that we do not accept individuals who do
                    not meet the minimum age and any other applicable age requirements.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>4. Purposes, Uses and Disclosures of Information</div>
                  <div className='mb-10'>
                    We may use any information collected pursuant to this Privacy Policy, including
                    Personal Data, for the purposes set forth in sections 4, 5 and 9 of this Privacy Policy, to
                    the extent applicable. Forstec associates Personal Data with your Forstec Profile in
                    order to perform the activities described in this Privacy Policy. Forstec uses such
                    information to help Job Seekers find jobs and to help Employers find candidates. In
                    order to do this we use your Personal Data to:
                    1. provide our services, products and features to you, to help Job Seekers find jobs and
                    Employers find candidates;
                    2. to measure and improve those services, products and features; and
                    3. to protect our users and provide them with customer support.
                    More detail on these purposes, uses and disclosures of your information is set out
                    below.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>4.1 Creating an Account, Sending You Job Alerts and
                    Other Promotional Materials</div>
                  <div className='mb-10'>
                    If and to the extent permitted by applicable law, Forstec may use the information you
                    provide to us or obtained from third parties, such as your contact information and
                    information about your activity on our Sites, to (i) create an account for you; (ii) send
                    you Job Alerts and Job Feed emails; (iii) send you invitations to additional roles, where
                    you can apply, interview, or take additional action such as confirming your interest, and;
                    (iv) send you information about the Sites, including by displaying our promotional
                    materials to you on third-party platforms. Forstec may use third-party partners to
                    perform these activities on Forstec’s behalf.
                    A Job Alert is an email that you sign up for containing Job Listings in response to a
                    search query you have run. A company alert is one type of Job Alert which contains Job
                    Listings and other information you have requested regarding a company. Job Alerts
                    may also include jobs that are recommended for you, Forstec Targeted Ads, companies
                    you may be interested in following, or other actions you may want to take on the Sites.
                    The frequency of Job Alerts may be adjusted by Forstec on the Sites.
                    When you create an account, you may receive Job Feed emails. Job Feed emails
                    recommend Job Listings based on your aggregated search queries, your Profile
                    information, and your Job Preferences. Job Feed emails may also include jobs that are
                    recommended for you, Forstec Targeted Ads, companies you may be interested in
                    following, or other actions you may want to take on the Sites. The frequency of Job
                    Feed emails may be adjusted by Forstec on the Sites. You can unsubscribe from Job
                    Feed emails in your account email preferences, or as described further in this Privacy
                    Policy.
                    An Invitation delivered by Forstec is a notice sent to you inviting you to take action on a
                    Job Listing that may be relevant to you, which you may receive via email, notification, or
                    push notification depending on your preferences. Forstec may invite you to apply,
                    interview, confirm your interest, or take other actions based on your previous job search
                    and application history on Forstec, and any information, including Personal Data, you
                    upload to the Site. Forstec may also send Invitations on behalf of Employers who
                    determine your resume to be a relevant match for their Job Listing. If you agree to share
                    your resume from an Affiliate site with Forstec, Forstec may send you an Invitation to
                    take action on a Job Listing hosted on the Affiliate’s site. If your resume is public on
                    Forstec, employers may send you an Invitation to apply, interview, or confirm your
                    interest, when they post relevant Job Listings.
                    As part of how we display our promotional materials to you, we may also use your
                    information to show you targeted advertisements, and other content that has been
                    customized for you, on third-party platforms, such as other online, print, or televised
                    media. Such advertisements will only relate to the Sites services, products, and
                    features, all of which focus on helping Job Seekers find jobs and Employers find
                    candidates. If you wish to opt out of receiving any marketing messages from the Sites,
                    please see section 10.3 of this Privacy Policy.
                    – Employer Accounts
                    In the Employer account settings, we offer users the ability to invite other users to link
                    their Forstec Employer accounts, and in doing so, assign invited users specific roles
                    that provide varying levels of access to information, including candidate Personal Data,
                    contained in the account of the user who initiated the invite. If you are an Employer and
                    you invite other Forstec users to link their accounts to yours, you agree that such linked
                    users may have access to any and all information in your account. Depending on the
                    roles assigned to a particular linked user, this may include, but not be limited to, resume
                    contacts, application materials and statuses, communications with applicants, any
                    action taken by a you or another user linked to the account and when you took that
                    action, and billing information within the account.
                    If you accept another user’s invitation to link your Employer account to their Employer
                    account, you acknowledge that any and all information about your activity in your
                    Employer account, including without limitation the actions you take and when you take
                    them, will be visible to all linked users. Linked users will not be able to see your activity,
                    such as your job searches, applications or preferences or your Forstec Profile
                    information, in your Job Seeker account.
                    You may visit your account settings at any time to unlink your account. The
                    administrator assigned to an Employer account may also unlink any user from that
                    Employer account at any time, and may be notified if your linked account is used to
                    login from too many devices.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>4.3 Your Contact Information</div>
                  <div className='mb-10'>
                    When you give the Sites contact information (such as your name and email address)
                    and other forms of Personal and Non-Personal Data, you agree that we may utilize this
                    information for the purposes outlined in sections 4, 5 and 9, to the extent applicable by
                    law. In particular, you agree that we may, as part of our services to you, communicate
                    with you, or initiate communication with you on behalf of a third party, through your
                    account or through other means such as email, telephone (including mobile phone), text
                    message or postal mail. This includes communication through the use of contact
                    information that you may provide to us or that we may otherwise obtain from third-party
                    resources. Should you apply to a job that is supported by our Forstec Hire product, a
                    Hiring Specialist may use your contact information to conduct or coordinate interviews
                    with you, and provide you with updates throughout the hiring process. We may also
                    analyze our users’ contact information in the aggregate to better understand our users
                    and improve our products and services.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>4.4 Your Resume Information</div>
                  <div className='mb-10'>
                    By creating a public resume through the Site or uploading a file resume on the Site, you
                    are requesting and authorizing Forstec to make available your resume to third parties,
                    such as Employers, that Forstec believes may have an interest in your resume. If you
                    do not want Forstec to make available your resume to third parties or if you do not want
                    Employers to contact you, set your resume to private, as detailed below.
                    If you have an Forstec account, you agree that Forstec may associate your resume data
                    with information you provide on the Site in the “Profile” section of your account.
                    We reserve the right to charge third parties a fee for accessing your information,
                    including Personal Data, as part of the Sites’ services. At your direction, we may also
                    send Your Resume to third parties if you choose to apply for a Job Listing, RSVP for an
                    interview, or otherwise show interest in an Employer or Job Listing. Information in your
                    Forstec Profile, except as otherwise indicated on the Site, may be available to third
                    parties, such as Employers. We may also use information in your Forstec Profile to offer
                    suggestions to you when you apply for jobs on third party websites in our mobile
                    software. The extent to which your information is made available to third parties
                    depends on the privacy settings you choose, in accordance with this Privacy Policy.
                    When a Job Seeker creates a profile on Forstec, their resume is set to public by default,
                    and the Job Seeker may change their resume privacy settings at any time. This is
                    detailed below.
                    <br /><strong>
                      – Private Resumes
                    </strong><br />
                    Private resumes are only made available to those to whom the Job Seeker has
                    submitted an application or provided their resume. Where this occurs, you make your
                    resume, your name and other Personal Data that you place on your resume available to
                    third parties. Those third parties may be interested in you as an employee, and may
                    forward your resume to other third parties.
                    <br /><strong>
                      – Public Resumes
                    </strong><br />
                    Public resumes may be available to anyone accessing our Site. This includes users of
                    Forstec’s Resume Search product, Employers whose Company Page you may be
                    following, and anyone with access to the URL associated with your public resume, such
                    as search engines and other third parties that may crawl our Site. We offer you this
                    visibility to help you find a job.
                    When your resume is public on our Site, you authorize Forstec to share the contents of
                    your resume, including your name and any other Personal Data that you may include in
                    it, publicly on the Site.
                    Forstec may also offer you the ability to include additional information in your Forstec
                    Profile about your desired job preferences, such as your desired job schedule and
                    salary, your willingness to relocate, licenses, certifications, skills, and other information
                    you choose to include about your job search. We use this information to help you find a
                    job. Our Resume Search product allows Employers to find great candidates by
                    searching public resumes. By posting a public resume, you authorize Forstec to make
                    the entirety of Your Resume available to any user who searches for you on our Sites, to
                    associate your desired job preferences with Your Resume and share this information
                    with Employers who search for resumes on our Sites, to share Your Resume with
                    Employers who post Job Listings that may be a relevant match, and to allow employers
                    to see if you were recently active on the Sites, your readiness to work, and your
                    available contact methods. For example, Employers using Forstec’s Resume Search
                    product are able to search for candidates based upon the information in Your Resume
                    and any of your desired job preferences associated with it. As another example,
                    Employers may invite Job Seekers with public resumes to take action on their Job
                    Listings when the resume is relevant to their hiring needs. If you do not want Forstec to
                    share this information with Employers, you can remove it from Your Resume and
                    Forstec Profile, or change your Resume Privacy Settings to private.
                    If you upload a file as your resume, you authorize Forstec to extract the contents of this
                    file and share them on our Site, including within our Resume Search product.
                    We may remove your name and contact information from your public resume in certain
                    instances, such as for users of our Resume Search product without a subscription.
                    Employers who have subscribed to our Resume Search product will be able to see your
                    name and any other contents of your public resume, except that we reserve the right to
                    remove your contact information (such as your email and telephone number) until you
                    express interest in an Employer. In either case, Employers may still contact you through
                    our Site using our Relay Service. You also authorize Forstec to share any file you
                    upload as a public resume on our Site with Employers where you express interest in
                    them by applying to a job or responding positively to an invitation to apply.
                    Forstec expressly prohibits all scraping of its Site, although some third parties may use
                    our Site in violation of these terms to obtain your public resume information.
                    Please note that Resume Search has not launched in all markets. If you are located in a
                    market where Resume Search has not yet launched, setting your resume to public may
                    make it available to anyone who visits the associated URL but it may not be searchable
                    on our Site. Resumes set to public in markets where Resume Search is not yet
                    available may become searchable upon our launch of Resume Search in that market.
                    Forstec may also provide browser extensions and other tools to help Employers better
                    interact with Job Seekers and their resume information, including tools that allow
                    Employers to download your resume information and enhance and transfer it to third
                    parties engaged to process information on their behalf, such as applicant tracking
                    systems and other recruitment management tools. Employers are solely responsible for
                    complying with all applicable laws that relate to their use of information obtained from
                    Forstec and other sources, including any obligations Employers may have to restrict
                    how others interact with resume information downloaded from our Site. Employers are
                    also responsible for the activities of any third-party service provider they engage to
                    facilitate their sourcing activities through the Forstec Resume Search Program, such as
                    an Applicant Tracking System.
                    <br /><strong>
                      – Changing Your Resume Privacy Settings
                    </strong><br />
                    When you place Your Resume on Forstec, it will be set to public by default. You may
                    adjust this at any point by editing your privacy settings on Forstec. Where you adjust
                    Your Resume from public to private, Forstec does not warrant how often third-party
                    search engines will update their caches, which may continue to contain your resume
                    information. Additionally, Employers who have saved your resume while it was public
                    may continue to have access to your resume even after you have adjusted it to private.
                    Changes made to your resume privacy settings may take a few days to complete,
                    during which time a resume set to public, for example, may remain visible to Employers
                    and other users searching on Forstec.
                    If you do not want Forstec to make your Forstec Profile available to third parties or if you
                    do not want Employers to contact you, set your resume to private in your resume
                    privacy settings or remove your resume. Your response to an Forstec Assessment will
                    be associated with your Forstec Profile and is only made public at your request. If you
                    take an Forstec Assessment as part of an application, the results are only visible to the
                    Employer who requested the assessment from you or Employers you choose to share
                    your Forstec Assessment score with on our Site.
                    <br /><strong>
                      – Resume Review Services
                    </strong><br />
                    We may also offer additional resume review services to help Job Seekers build and
                    improve their resumes. For example, this can include helping you understand how your
                    resume may be parsed by applicant tracking system software, feedback on ways to
                    build a more effective resume, or a personalized resume review video. In providing
                    these services, Forstec may give you feedback, including questions and comments on
                    the contents of your resume, about Your Resume or other materials you submit through
                    the services. We provide this feedback directly to you, and may give you the ability to
                    access it within your account. It is not made available to Employers.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>4.5 Matching Job Seekers With Employers</div>
                  <div className='mb-10'>
                    You acknowledge that Forstec may determine whether the words of Your Resume or
                    any other individual’s resume match, or match the words of certain job descriptions or
                    Resume search queries and vice versa. Forstec may use this information and other
                    information collected pursuant to this Privacy Policy, such as your answers to screener
                    questions and recent activity on the Sites, in order to improve the Sites or any of our
                    other products or services. As one example, Forstec may display or otherwise make
                    available potentially relevant job descriptions and resumes to Job Seekers and
                    Employers and differentiate between resumes and Job Listings that match and those
                    that do not. As another example, Employers may instruct Forstec to help manage their
                    candidate pool according to their requirements and preferences for the Job Listing,
                    which may include sending updates to Job Seekers based on Employer activity, so that
                    Job Seekers better understand the status of their candidacy, or giving Employers the
                    ability to sort and arrange their candidates for future reference.
                    Forstec may also provide information collected pursuant to this Privacy Policy, including
                    Personal Data, to Employers that may be interested in contacting you. By applying to a
                    job, answering screener questions, providing your contact information to show interest
                    in a Job Listing, confirming your interest in a job invitation, using Forstec Hire, or by
                    replying to a message from an Employer, such as by expressing your interest in a
                    particular Job Listing, you agree to the disclosure of your information to that Employer.
                    Forstec may also use information provided in your Forstec Profile to pre-populate
                    application information for you and for similar purposes.
                    If you have an Forstec Profile, you agree that we may contact you or suggest you or
                    your public resume to Employers that might be interested in a person who matches your
                    activity on Forstec.
                    In addition, if you are located in the United States and you set Your Resume to public,
                    this means that Forstec may share all of the aforementioned information about you with
                    recruiters working for or on behalf of Employers. If you do not wish to share such
                    information, you may set Your Resume to private.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>4.6 Optimizing Job Campaigns</div>
                  <div className='mb-10'>
                    When Job Seekers click on a Job Listing or apply for a job powered by Forstec IQ on
                    our Sites or on a third-party website, including an Employer’s website or a website
                    operated by an Employer’s applicant tracking system, Forstec may collect certain
                    information through this feature about Job Seekers and any actions taken while on the
                    Sites, as detailed in section 2 of this Privacy Policy. Forstec uses this information to
                    help us understand the effectiveness of our Sites and to ensure that Job Listings
                    powered by Forstec IQ perform at the optimal level on our Sites and on other third-party
                    websites.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>4.7 Sending Messages on Forstec</div>
                  <div className='mb-10'>
                    Forstec provides two-way message proxy and relay services to the users of our Forstec
                    products (“Relay Service”), including, but not limited to: Forstec Resume, Forstec Apply,
                    Post a Job, Forstec Chat, Forstec Onboarding, Forstec Text2Apply, private calling, and
                    other electronic communication services whether in the form of phone, text, video or
                    audio. By utilizing any Relay Service, you acknowledge that you are asking Forstec to
                    send these messages on your behalf. This includes your resumes, cover letters,
                    applications, messages, questionnaire answers, responses, offer letters and other
                    materials. Forstec may provide read receipts to the sender of a message through a
                    Relay Service indicating that the recipient has viewed the message. Forstec may also
                    separately inform the sender and recipient of a message through a Relay Service
                    whether the sender or recipient recently has been active on the Site.
                    We process, monitor, review, store and analyze such content, which may include
                    Personal Data, including via automated means, for data analysis, quality control,
                    enforcement of the Sites’ rules and other Forstec policies, content moderation, and to
                    improve the Sites or any other Forstec product or service. Examples of such
                    improvements would be improving the open rate of messages by optimizing their
                    delivery, optimizing our search results, improving Job Listings, permitting applicants to
                    manage their applications after transmitting them to Forstec, providing Job Seekers with
                    the status of their application when instructed to by Employers, ensuring you are
                    provided with personally relevant product features and communications, and preventing
                    fraud and spam. Some of this activity is performed on Forstec’s behalf by service
                    providers, as described in section 4.17 of this Privacy Policy. As a result of this activity,
                    or due to technical malfunction, in certain circumstances such content may be delayed
                    or may not be delivered to the intended recipient. Forstec may (but is not obligated to)
                    notify you in such an event. By using a Relay Service or otherwise providing user
                    content through the Site, you agree to this activity and acknowledge that this activity is
                    necessary for maintaining the quality of the Sites and for providing the Relay Service.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>4.8 Sending You Reminders About Employer
                    Appointments</div>
                  <div className='mb-10'>
                    Forstec may offer you the opportunity to receive a message or phone call from Forstec
                    when an Employer: has scheduled an interview or event with you, seeks to contact you
                    based on your public resume, or has responded to your application via Forstec’s email
                    relay. In such an event you will enter your phone number on the Forstec consent form.
                    By entering your phone number you are representing and confirming it is your number
                    and you have the right to accept messages and phone calls at the number. You also
                    consent to receive messages and phone calls at said number, which will be sent to you
                    by Forstec’s third-party service provider. To unsubscribe to text messages, reply to the
                    text with STOP or contact the Help Center. To unsubscribe to phone calls you should
                    follow the instructions provided to you during the phone call. Replying STOP in
                    response to an Forstec SMS alert or unsubscribing during a phone call will unsubscribe
                    you from all Forstec SMS and call alerts except those related to Text2Apply and Hiring
                    Events. In order to unsubscribe from those products, you must respond STOP within the
                    specific text flows of those products.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>4.9 Facilitating Telephone Relay System between Job
                    Seekers and Employers</div>
                  <div className='mb-10'>
                    Where applicable, Forstec may offer Job Seekers and Employers the option to use a
                    telephone relay system to facilitate contact for the purpose of submitting applications,
                    conducting job interviews or other communication related to a Job Listing. Such a
                    system uses masked or proxy telephone numbers to protect users, and is only available
                    to users who select it on the Site. No recording of conversations or messages is
                    performed through this relay system, although data relating to whether a call was
                    answered and call duration may be gathered. Such functionality is provided to Forstec
                    by third party service providers.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>4.10 Facilitating Text Messaging Relay Service
                    between Job Seekers and Employers</div>
                  <div className='mb-10'>
                    Forstec may offer Job Seekers and Employers the ability to utilize an autodialed text
                    messaging relay service to facilitate contact for the purpose of submitting applications
                    and for other communications related to Job Listings. To use this service, a Job Seeker
                    must text an Employer’s preset keyword to a telephone number or short code that we
                    make available on our Sites or in advertising materials (such as a sign or flyer at an
                    Employer’s place of business). By texting the preset keyword to the telephone number
                    or short code, you are consenting to receive text messages about the Employer’s Job
                    Listings and as part of the application process, and acknowledge Forstec may use your
                    Personal Data to submit your application to the Employer and otherwise in accordance
                    with this Privacy Policy. To unsubscribe at any time from such services, reply STOP by
                    text message to the telephone number or short code provided or contact the Help
                    Center. DUE TO THE UNSTRUCTURED CONVERSATIONAL FORMAT OF THIS
                    SERVICE, WE MAY NOT RECOGNIZE OTHER UNSUBSCRIBE ATTEMPTS.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>4.11 Preventing Fraud and Spam</div>
                  <div className='mb-10'>
                    Forstec may also use information collected pursuant to this Privacy Policy to help
                    diagnose problems with the Sites and our products and services, to prevent potentially
                    fraudulent or illegal activities, and to protect individuals from other activities that may be
                    detrimental to you or others. Forstec may investigate and disclose information from or
                    about you or your use of Forstec if we have a good faith belief that such investigation or
                    disclosure (a) is reasonably necessary to comply with legal process (including
                    subpoenas, search warrants, court orders) and law enforcement instructions and
                    orders; (b) is helpful to prevent, investigate, or identify possible wrongdoing in
                    connection with the use of the Sites; (c) may protect our rights, reputation, property,
                    safety, or that of the public; or (d) as necessary to meet national security requirements.
                    For example, Forstec is subject to the investigatory and enforcement powers of the
                    Federal Trade Commission in the United States, the Data Protection Commission of
                    Ireland, and many other regulatory bodies.
                    We may use a variety of methods to detect and address anomalous activity and screen
                    content to prevent abuse such as spam or fraud. However, such detection methods are
                    not perfect and false positives may occur. These efforts may, on occasion, result in a
                    temporary or permanent suspension or termination of some functions for some users
                    <br />
                    <strong>
                      – Preventing Fraud and Spam in Messages
                    </strong><br />
                    Forstec also reserves the right to drop messages from our relay system that we
                    determine do not adhere to the Sites’ rules or any of the terms of our terms of service.
                    Forstec further reserves the right to conduct investigations to determine whether you
                    are perpetrating a scam, spamming Forstec or its users, or otherwise conducting
                    fraudulent activity on the Sites by various means. Forstec also reserves the right to turn
                    over any information gathered via such investigations to the police or other third parties
                    if we have a good faith belief that you are using the Sites in violation of this Privacy
                    Policy or the applicable Terms of Service.
                    Forstec reserves the right to verify and confirm the identity of any party associated with
                    an email relay. This includes but is not limited to verifying that the email address on a
                    Job Listing is associated with the Employer whose Job Listing it is.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>4.12 Facilitating Payment</div>
                  <div className='mb-10'>
                    If you provide credit, debit or other payment card and related details to us in order to
                    make a payment, we will use such information only for the purpose of processing the
                    payment (e.g., the last four digits of credit card numbers and related verification
                    information). Any credit, debit, or other payment card and related billing and payment
                    information that you provide to us may be shared with companies who work on our
                    behalf, such as payment processors and/or credit agencies, solely for the purposes of
                    checking credit, effecting payment and servicing your account.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>4.13 Automated Processing of Your Personal Data</div>
                  <div className='mb-10'>
                    In order to optimize the performance of the Sites, we perform automated processing of
                    Personal Data evaluating specific aspects relating to a user. We use this automated
                    processing to analyze and predict a user’s preferences and interests. Where we
                    perform this processing, we do so either on the basis of user consent or performance of
                    a contract, whereby such processing is necessary for us to provide our services to Job
                    Seekers and Employers.
                    For example, in certain cases such processing allows us to match Job Seekers with
                    Employers effectively, to provide Job Seekers with Job Alerts, recommended jobs, and
                    invitations to take action on jobs that the Job Seeker is likely to be more interested in, to
                    send particular Job Seeker applications to an Employer, to display search results, to
                    send application status notifications on behalf of Employers, or to perform certain spam
                    and fraud detection activities to protect our users. Such processing is subject to the
                    safeguards detailed in this Privacy Policy to protect the privacy of our users. You may
                    contact privacy-dept @ Forstec.com (remove spaces) to contest any decision generated
                    by said automated processing.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>4.14 Sharing Your Information with Our Affiliates</div>
                  <div className='mb-10'>
                    Our core mission is to help Job Seekers find great jobs, and Employers find great
                    candidates, which is a mission we share with each of our affiliates. In order to support
                    that mission, we share certain information with our affiliates. Such information sharing is
                    governed by this Privacy Policy. We also developed the Privacy Center as a way of
                    educating our users about our affiliates and how we share data with them to provide the
                    strongest product and service offerings to our users. We encourage you to visit
                    the Privacy Center to learn more.
                    Information shared by you with Forstec may be shared with an affiliated entity to provide
                    you with services, to improve the Sites and optimize functionality on the Sites, and other
                    purposes as described in this Policy. In particular, such sharing strengthens our ability
                    to help Job Seekers find jobs and Employers find great candidates. The services
                    provided to you and the functionality of the Sites could not be provided without such
                    transfers. If you do not wish your information to be transferred in this way you should
                    not use the Sites.
                    For the purposes of this Policy, “Affiliates” means any entity that directly or indirectly,
                    controls, is controlled by, or is under common control of or with an Forstec entity, now or
                    in the future. “Control” for these purposes means having a majority of shares or the right
                    and ability to direct management.
                    <br /><strong>
                      – Improved Services
                    </strong><br />
                    Data sharing with affiliates provides Job Seekers with more personalized and improved
                    content, performance, features and services. For example, Forstec and its affiliates use
                    your previous search and browsing history (e.g. the jobs you click on), IP address, or
                    Forstec Profile information, to determine relevant search results, send Job Alerts and
                    Invitations, display and moderate career insights, such as company reviews, Job Listing
                    salary data, interview tips and more. Such sharing is also performed to facilitate
                    improved data analysis, testing, research, and service improvement.
                    This sharing also allows Employers to have better insight into the performance of their
                    Job Listings. For example, we may share data related to user clicks on their Job Listings
                    which in turn allows Employers to understand the extent to which they are attracting
                    candidates. By providing Employers with the information they need to better utilize our
                    platform, they can provide stronger Job Listings.
                    <br /><strong>
                      – More Employers, More Job Listings and More Job Seekers
                    </strong><br />
                    Such data sharing allows Job Seekers to access an even broader database of Job
                    Listings and Employers, and for Employers to access more Job Seekers. For example,
                    Forstec can show you Job Listings that have been posted on our Affiliates’ websites or
                    a Job Seeker from an Affiliate can be recommended as a candidate for an Affiliate’s Job
                    Listing. Forstec and its Affiliates also share Employer data (such as account information
                    and behavioral data) to provide a better experience to those Employers.
                    Forstec may process billing information on behalf of Affiliates. If you are using a
                    chargeable service, Forstec and its Affiliates may share billing information solely for the
                    purposes of checking credit, effecting payment, and servicing your account.
                    <br /><strong>
                      – Better Security for Users
                    </strong><br />
                    Such data sharing allows Forstec and our affiliates to process your Personal Data for
                    the purpose of improving user security and internal operations, troubleshooting, and
                    detecting and preventing fraud and spam.
                  </div>

                  <div className='mb-2 fs-6 fw-bolder'>4.15 Transfers of Information to Employers</div>
                  <div className='mb-10'>
                    Forstec sends Job Seeker Personal Data to Employers on the instruction of Job
                    Seekers. Such transfers are performed in the course of the job search, application and
                    interview process. This is further described in sections 2 and 4 of this Privacy Policy.
                    Such Personal Data will be processed in accordance with these terms and those of the
                    relevant Employer. Job Seekers that apply to jobs through Forstec that are outside their
                    own country may involve sending their Personal Data to countries with lower levels of
                    data protection.
                    Employer account holders agree to comply with all their responsibilities under applicable
                    data protection rules with respect to the collection, processing and storage of Personal
                    Data, as well as providing adequate protection of all data subject rights provided for
                    under all applicable data protection rules.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>
                    4.16 Transfers of Information to Job Seekers
                  </div>
                  <div className='mb-10'>
                    Forstec sends certain Employer-related information to Job Seekers. This includes
                    publishing Job Ads, Company Pages, and salary information on the Sites and
                    publishing Job Listings on third-party websites. Forstec may also provide information to
                    Job Seekers about an Employer’s activity and responsiveness on the Sites in order to
                    help Job Seekers evaluate employment opportunities. This may include the Employer
                    representative’s name, the Employer’s city/state location, whether the Employer has
                    taken or has chosen not to take an action with regard to the Job Seeker’s resume or
                    application such as opening, viewing, responding to or making a decision in regard to it,
                    whether the Employer has engaged with other Job Seekers or viewed other Job
                    Seekers’ resumes, and whether the Employer has taken an action in regard to a Job
                    Listing such as pausing or closing it. As an Employer, when you use the Sites (for
                    example by posting a Job Listing or contacting a Job Seeker through the Forstec
                    Resume Program) you agree that Forstec may provide this information to Job Seekers.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>4.17 Transfers of Information to Service Providers</div>
                  <div className='mb-10'>
                    We may use third parties to perform services in connection with our operations, to
                    improve the Sites and our services, products, and features, to protect our users and
                    better understand their needs, and to optimize the services and the users’ experiences.
                    By using our Sites, you acknowledge and agree to Forstec’s use of such third-party
                    service providers, including, but not limited to, for the purpose of collecting, processing,
                    analyzing, and recording your activity on, interaction with, and communications with or
                    through our Sites, as further described in section 2 of this Privacy Policy.
                    These service providers may have access to Personal Data in the course of providing
                    these services, which may involve transfers of such Personal Data to other countries.
                    Such transfers are made for the purposes set forth in sections 4, 5 and 9 of this Privacy
                    Policy.
                    <br />
                    <strong>
                      – Improving our Services
                    </strong><br />
                    These third parties may provide data points including how much time you spend on
                    specific pages, which links you choose to click, and data that helps determine user
                    preferences. This information enables us to build and maintain our services with user
                    feedback. These third parties may use cookies and other technologies to collect data on
                    your behavior and devices such as the device type, browser information, and preferred
                    language.
                    <br />
                    <strong>
                      – Maintaining Quality and Protecting Users
                    </strong><br />
                    Forstec may transfer your Personal Data in limited situations to certain third-party
                    vendors that assist Forstec with protecting our users and maintaining the quality of our
                    services, products and features provided to those users. An example would be vendors
                    Forstec uses for fraud detection purposes. Examples of those vendors include:
                    SiftScience, Emailage, and PerimeterX. Another example would be vendors used to
                    prevent accounts being created using account credentials that have been publicly
                    disclosed. An example of such a vendor is: SpyCloud.
                    <br />
                    <strong>
                      – Facilitating Payments
                    </strong><br />
                    Forstec may also share any credit, debit, or other payment card and related billing and
                    payment information that you provide to Forstec with companies who work on Forstec’s
                    behalf, such as payment processors and/or credit agencies, solely for the purposes of
                    checking credit, effecting payment to Forstec and servicing your account.
                    <br />
                    <strong>
                      – Installing or Using an Forstec App
                    </strong><br />
                    In order to provide you with certain services and support ad attribution, limited user data
                    may be shared with certain service providers when you install, download or use an
                    Forstec App. This may include your IP address or other unique identifier, and event data
                    related to the installation of the Forstec App.
                    This may be done for a variety of purposes, including facilitating user login through
                    third-party accounts. It may also assist us to understand and optimize our users’
                    complete customer journey by helping us: (i) understand how users arrive to the Sites;
                    (ii) better measure the performance of our ads; and (iii) understand where a user has
                    accessed the Sites through different devices.
                    When you download or install an Forstec App, you acknowledge and accept that your
                    Personal Data may be transferred to these service providers. You also agree to
                    Forstec’s Cookie Policy, Privacy Policy and the applicable Terms of Service, and are
                    informed that you may avail of your rights at any time, as detailed in section 10 of this
                    Privacy Policy, including the right to object to the legitimate interest use of your
                    Personal Data for marketing purposes.
                    Examples of the types of technologies that may support this behavior include pixels,
                    javascript snippets, or cookies.
                    <br />
                    <strong>
                      – Storage
                    </strong><br />
                    Please also be aware that we may also use third-party cloud service providers that
                    provide hosting, data storage and other services pursuant to standard terms and
                    conditions that may be non-negotiable. These service providers have informed us or the
                    general public that they apply security measures they consider adequate for the
                    protection of information within their system, or they have a general reputation for
                    applying such measures. However, we will not be liable (to the fullest extent permitted
                    by law) for any damages that may result from the misuse of any information, including
                    Personal Data, by these companies.
                    <br />
                    <strong>
                      – Verifying Professional Certifications and Licenses
                    </strong><br />
                    We may also share certain professional certification or license numbers that you provide
                    to Forstec with the issuing entity or organization in order to verify the certification or
                    license is valid. We only perform this verification at your request when, for example, you
                    add certain certifications or licenses to your Forstec Profile.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>4.18 Transfers of Publicly Available Information</div>
                  <div className='mb-10'>
                    When users post content on the Sites in a manner that is intended to make that content
                    publicly available and searchable by individuals, Forstec reserves the right to aggregate
                    and share that information with third parties. For example, if an Employer posts a job on
                    the Sites, Forstec may share that Job Listing or information contained within that Job
                    Listing with third parties, at Forstec’s discretion and subject to agreement with such third
                    parties. Forstec also reserves the right to share information aggregated from public
                    sources in this capacity.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>4.19 Monitoring of Forstec Services, etc.</div>
                  <div className='mb-10'>
                    If you are neither a Job Seeker nor an Employer as defined in this Agreement and
                    are not using the Site as intended by Forstec, this Agreement will be governed
                    as to all matters, including, but not limited to the validity, construction and
                    performance of this Agreement.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>10. Severability and Reformation</div>
                  <div className='mb-10'>
                    Forstec may use information about users obtained from third-party platforms such as
                    social networking sites for the following purposes:
                    <br />
                     Classify and quantitatively understand the number of responses and spread of
                    marketing campaign measures such as TV commercials<br />
                    Monitor the reputation of Forstec’s services to understand what is appreciated and
                    what is not, and use this information in communication plans<br />
                     Early detection of improvements to the service, monitoring of user feedback, and
                    planning of countermeasures<br />
                     Support for users who do not know how to use FORSTEC’s services<br />
                     Finding product defects (e.g., “When I try to open the application, it displays a 501 error
                    and I can’t use it” or “I can’t apply for a job”) and escalating the issue to the
                    development team.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>5. Additional Uses and Disclosures of Non-Personal Data</div>
                  <div className='mb-10'>
                    This policy also applies to our use of Non-Personal Data. We may use and disclose
                    Non-Personal Data for any purpose, except where we are required to do otherwise
                    under applicable law. If we combine any Non-Personal Data with Personal Data, then
                    we will only use and disclose such combined information for the purposes described in
                    sections 4 and 9 of this Privacy Policy while it is so combined.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>6. Security</div>
                  <div className='mb-10'>
                    Forstec seeks to use reasonable security measures to help protect against the loss,
                    misuse and alteration of the Personal Data under Forstec’s control. No method of
                    transmission over the Internet, or method of electronic storage, is 100% secure,
                    however. In addition, please note that emails, messages sent via your web browser,
                    and other similar means of communication with other users are not encrypted.
                    Therefore, while we strive to protect your information, we cannot guarantee its security.
                    Please see the following Security page for more information.
                    Please also be aware that we may use third-party cloud service providers as detailed
                    by section 4.17 of this policy.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>7. Cookies</div>
                  <div className='mb-10'>
                    “Cookies” are small pieces of information that are stored by your browser on your
                    computer’s hard drive. Please see our Cookie Policy for further information regarding
                    our use of Cookies.<br />
                    <strong>– Do Not Track Requests</strong><br />
                    We do not respond to the browser “Do Not Track” (DNT) signal if enabled by the user in
                    their web browser. When we set or read cookies on non-affiliated sites that have
                    integrated our job search and display features, we do so, for example, to provide a
                    personalized job search experience to users on those sites. We do not share any
                    information about your use of our Sites with those third parties. When third parties that
                    we have integrated into the Sites (as described in the Forstec Cookie Policy) set or read
                    their own third-party cookies, they may or may not respond to the DNT signal.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>8. Links to Third-Party Sites</div>
                  <div className='mb-10'>
                    The Sites may make available links to other third-party websites. When you click on
                    such links, you may leave our Sites. Your web browser may also disclose to the thirdparty website the URL of the Sites page you clicked from, and this URL may include
                    search terms and filters you used on the Sites. The Sites may also make available
                    Company Pages, which may contain information provided by third-party Employers.
                    FORSTEC IS NOT RESPONSIBLE FOR THE PRIVACY PRACTICES OF THIRD
                    PARTIES. THIS INCLUDES BUT IS NOT LIMITED TO THE COLLECTION, USE AND
                    DISCLOSURE OF INFORMATION BY:<br />
                    OUR AFFILIATES<br />
                     THIRD-PARTY SERVICE PROVIDERS<br />
                     EMPLOYERS (INCLUDING EMPLOYERS WHO PROVIDE A COMPANY PAGE)<br />
                     THIRD-PARTY SOCIAL MEDIA PLATFORMS<br />
                     THE MAKER OF YOUR DEVICE OR OPERATING SYSTEM<br />
                     ANY LINKED THIRD-PARTY WEBSITE<br />
                    YOU MAY HAVE DIRECTLY ENFORCEABLE RIGHTS AGAINST THESE THIRD
                    PARTIES. PLEASE CONSULT THEIR SPECIFIC INDIVIDUAL PRIVACY POLICIES
                    TO LEARN MORE.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>9. Acquisitions and Other Reorganizations</div>
                  <div className='mb-10'>
                    Information collected pursuant to this Privacy Policy, including Personal Data, may be
                    disclosed to one or more third parties in connection with any change of ownership or
                    control in Forstec’s business (whether by merger, sale, or otherwise), or any other
                    reorganization or joint venture, or assignment, transfer or other disposition of all or any
                    portion of our business, assets or stock (including in connection with any bankruptcy or
                    similar proceeding), and any such information may be used by such third party in
                    accordance with this Privacy Policy. Equally, information collected pursuant to this
                    Privacy Policy, including Personal Data, may be disclosed to one or more third parties in
                    connection with any acquisition or merger carried out by Forstec of such third parties.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>10. Exercising Rights With Respect to Personal Data</div>
                  <div className='mb-10'>
                    As a user of the Sites, you may exercise the rights explained below. Please note, we
                    may attempt to verify your identity through the email address or telephone number
                    associated with your use of our Sites prior to fulfilling any such request and reserve the
                    right to deny a request where we are unable to satisfactorily complete this process. If
                    you authorize someone to make a request on your behalf, we may also deny your
                    request if we are unable to verify with you that the individual making the request is
                    authorized to act on your behalf.
                    If you have already submitted an application to an Employer, please contact the
                    Employer directly to learn more about the data subject rights available to you. If you
                    submitted a job application to work at Forstec, you can refer to the Forstec Applicant
                    Privacy Policy for instructions on how to exercise your data subject rights.
                    If you have any queries or complaints about how we process your Personal Data we
                    encourage you to contact us.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>10.1 The Right to Rectify Your Personal Data</div>
                  <div className='mb-10'>
                    Users of the Sites are able to edit and update most of their Personal Data held by each
                    Site on the Site in question. You may make a formal request to have Forstec rectify any
                    inaccurate Personal Data on your behalf by sending an email to privacy-dept @
                    Forstec.com (remove spaces when sending email) from the email address associated
                    with your account.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>
                    10.2 The Right to Object to or Restrict the Processing
                    of Your Personal Data
                  </div>
                  <div className='mb-10'>
                    If you wish to make a formal request to object to or restrict the processing of your
                    Personal Data, please contact us requesting the deletion of your data.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>10.3 Opting Out of Marketing Messages and
                    Withdrawing Consent</div>
                  <div className='mb-10'>
                    If you no longer want to receive marketing materials from us based upon your Personal
                    Data, there are a number of options available to you.
                    If you wish to opt out of a particular marketing email from any Site, you may do so by
                    clicking “unsubscribe” (or an equivalent action) where applicable. Please note that this
                    will only opt you out of that particular marketing message.
                    If you wish to adjust all of your Job Alerts, you can click on “Edit this job alert” (or an
                    equivalent action) to adjust your subscriptions. You can also access and adjust your
                    subscriptions (for Job Alerts, recommended jobs, and other notifications) from your
                    account dashboard (where applicable), or contact us to do so.
                    In addition, you may opt out of all marketing messages by sending an email to privacydept @ Forstec.com (remove spaces when sending email). We will comply with your
                    request as soon as reasonably practicable, and in accordance with applicable laws.
                    Finally, please note that we may still send other messages to you, such as
                    administrative messages.
                  </div>

                  <div className='mb-2 fs-6 fw-bolder'>10.4 The Rights of Access to and Portability of Your
                    Personal Data</div>
                  <div className='mb-10'>
                    Users of the Sites are able to access most of their Personal Data provided to each Site.
                    You may make a formal request to access your Personal Data held by each Site by
                    filling out this Personal Data Request form. Each specific Site will provide your Personal
                    Data to you in a portable format. If you have interacted with a Site using more than one
                    email address, please submit a subsequent request for each additional email address
                    so that we may provide this personal data as well.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>10.5 The Right to Delete Your Personal Data</div>
                  <div className='mb-10'>
                    Users of the Sites are able to delete most of their Personal Data held by each Site. You
                    may make a formal request to delete your Personal Data on each Site by filling out
                    this Personal Data Request form.
                    Once your request has been confirmed, each specific Site will initiate the necessary
                    steps to fulfill your request, including the deletion of any applicable Site account. Where
                    the deletion request relates to Personal Data that you previously requested be made
                    public on each Site (e.g. a Job Seeker posting a public resume), we will make
                    reasonable efforts to inform any other controllers of this request. If you have interacted
                    with the Site in question using more than one email address, please submit a
                    subsequent request with the additional email addresses so that we may delete this
                    Personal Data as well.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>10.6 Withdrawing Consent</div>
                  <div className='mb-10'>
                    There are certain activities where we ask for your consent to use your Personal Data.
                    You have the right to withdraw this consent at any time. Where you are providing
                    location information via a mobile app, you may turn off your location services on your
                    device at any time. For other services that rely on your consent, you can withdraw your
                    consent via the instructions provided to you at the time you sign up for that service.
                    Please note that any processing that we have carried out before the withdrawal of your
                    consent remains lawful.
                    Forstec may provide an Employer with code for a Conversion Tracker to place on its
                    website that pings the Job Seeker web beacon and tracks whenever a Job Seeker has
                    applied to a job on that Employer’s website. This Conversion Tracker will attribute an
                    application to Forstec any time the Job Seeker started their search on Forstec, despite
                    any intervening activity, and a conversion count will be displayed on the Employer
                    dashboard. Data may be shared from the Employer to Forstec for the purposes of
                    displaying unique applications. The Conversion Tracker is simply a counter that notifies
                    Forstec that a Job Seeker has applied, and also has the ability to count these
                    conversions across devices if a Job Seeker is logged in to the Employer’s website. If
                    you would like to remove yourself from Forstec’s Job Seeker web beacon, you must use
                    our web beacon opt-out
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>10.7 Some Limitations</div>
                  <div className='mb-10'>
                    Such rights of rectification, objection, restriction, access, portability and deletion are
                    subject to certain limitations, as provided for by applicable laws. Individual requests will
                    be completed within the time allotted by relevant regulations, which starts to run from
                    the point of us confirming your request. Where permitted, please note there may be a
                    charge for subsequent requests from the same individual, which will be determined by
                    Forstec and only imposed to the extent permitted by applicable law.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>11. Personal Data from Individuals in the EEA and Switzerland</div>
                  <div className='mb-2 fs-6 fw-bolder'>11.1 GDPR Protections</div>
                  <div className='mb-10'>
                    Where appropriate, Forstec Ireland’s transfers of Personal Data of individuals in the
                    EEA and Switzerland to jurisdictions outside the EEA and Switzerland are performed
                    based on the European Commission’s standard contractual clauses. These agreements
                    also incorporate the protections and requirements provided for under Chapter V (and
                    Article 28, where appropriate) of the GDPR.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>11.2 Privacy Shield Principles and Framework</div>
                  <div className='mb-10'>
                    RSK Solutions transfers certain Personal Data to Forstec. While Privacy Shield is no
                    longer a lawful transfer mechanism, participants are still obligated to comply with the set
                    of security principles. Forstec Inc. has certified that it continues to adhere to the Privacy
                    Shield Principles of Notice, Choice, Accountability for Onward Transfer, Security, Data
                    Integrity, and Purpose Limitation, Access, Recourse, Enforcement and Liability. If there
                    is any conflict between the provisions in this Privacy Policy and the Privacy Shield
                    Principles, the Privacy Shield Principles shall govern. You can learn more about the
                    Privacy Shield program, and view our certification, by accessing the Privacy Shield
                    Framework.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>11.3 Principle of Onward Transfer</div>
                  <div className='mb-10'>
                    In the context of an onward transfer of data to a third party, a Privacy Shield
                    organization has responsibility for the processing of Personal Data it receives under the
                    Privacy Shield and subsequently transfers to a third party acting as an agent on its
                    behalf. Forstec shall remain liable under the Principles if its agent processes such
                    Personal Data in a manner inconsistent with the Principles, unless Forstec Inc. proves
                    that it is not responsible for the event giving rise to the damage.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>12. General Inquiries and Complaints</div>
                  <div className='mb-2 fs-6 fw-bolder'>12.1 Contacting Forstec</div>
                  <div className='mb-10'>
                    You may contact Forstec, you may contact either company through the method laid out
                    in the ‘Contact Us’ section. If you would like to contact Forstec’s Data Protection Officer,
                    please send an email to privacy-dept @ Forstec.com (remove spaces when sending
                    email).
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>12.2 Complaints</div>
                  <div className='mb-10'>
                    If you believe your data protection rights have been infringed by Forstec, you have the
                    right to complain to the appropriate data protection supervisory authority. you may avail
                    of independent dispute resolution, as detailed in section 11 of this Privacy Policy.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>13. APPI Privacy Compliance</div>
                  <div className='mb-10'>
                    If you are a user located in Japan, this section applies to our processing of your
                    personal data under the requirements of the Act on the Protection of Personal
                    Information (APPI).
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>
                    13.1 Transfer of Your Personal Data to our Affiliates
                    and Third Parties in Foreign Countries
                  </div>
                  <div className='mb-10'>
                    Forstec may transfer your Personal Data to our Affiliates and third parties in foreign
                    countries for the purposes described in section 4. The details of such transfers can be
                    found below:
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>
                    13.2 Disclosure of Information
                  </div>
                  <div className='mb-10'>
                    Under RSK Solutions, Forstec will disclose, when requested, the following information:<br />
                     Our security control measures (including safeguards for the international transfer)<br />
                     Regarding the transfer (based on equivalent action) of your personal data to our service
                    providers located in foreign countries.<br />
                     Please make your request through this page. For the disclosure of the other information
                    regarding your personal data, please see section 10.4.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>
                    13.3 Personally Referable Information
                  </div>
                  <div className='mb-10'>
                    We provide cookies, which are personally referable information, to the following
                    affiliated companies, which may manage and use it in conjunction with personal data in
                    accordance with their privacy policies. A list of our affiliates and their privacy policies
                    can be found below.
                    We may also link the personally referable information we receive with personal data we
                    hold about you.
                  </div>
                  <div className='mb-2 fs-6 fw-bolder'>
                    13.4 Inquiries and Complaints
                  </div>
                  <div className='mb-10'>
                    If you have any inquiries or complaints regarding the use of your personal data, you
                    may contact us.
                  </div>
                  <div className='mb-2 fs-1 fw-bolder'>
                    Cookie Policy
                  </div>
                  <div className='mb-2 fs-5 fw-bold'>
                    Last Updated: April 25, 2022
                  </div>
                  <div className='mb-10'>
                    By using or accessing Forstec’s websites (“Site”), you agree to Forstec’s use of Cookies
                    as outlined below.
                    For the purposes of this Cookie Policy, “Forstec”, “we” or “us” means Forstec.
                    All references to Job Seeker, Employer and Personal Data are defined as provided for
                    in our Privacy Policy.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>
                    1. Introduction: What is a cookie?
                  </div>
                  <div className='mb-10'>
                    This Cookie Policy helps to explain the occasions when and why cookies may be sent
                    to visitors to the Sites. Cookies are text-only pieces of information that a website
                    transfers to an individual’s hard drive or other website-browsing equipment for recordkeeping purposes. Cookies allow the Sites to remember important information that will
                    make your use of the Sites more convenient. A cookie will typically contain the name of
                    the domain from which the cookie has come, the “lifetime” of the cookie, and a randomly
                    generated unique number or other value. Certain cookies will be used on the Sites
                    regardless of whether you are logged in to your account or not.
                    Session cookies are temporary cookies that remain in the cookie file of your browser
                    until you leave the Site. Persistent Cookies remain in the cookie file of your browser for
                    much longer (though how long will depend on the lifetime of the specific cookie). We
                    use session cookies to track the total number of visitors to our Sites. We may also
                    employ cookies so that we remember your computer when it is used to return to the
                    Sites to help customize your experience. We may associate Personal Data with a
                    cookie file in those instances.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>
                    2. How We Use Cookies
                  </div>
                  <div className='mb-10'>
                    We use cookies, web beacons, and other similar technologies, including from third-party
                    partners such as Google and Facebook, for measurement services, better targeting
                    advertisements, and for marketing purposes. This takes place from the Sites, when you
                    download or install an Forstec App, and in some of our advertisements on third party
                    websites. These cookies, web beacons, and other similar technologies allow us to
                    display our promotional material to you on other sites you visit across the Internet and
                    connect you with more Employers. We may also share information about your behavior
                    on the Sites with third parties (including operators of third-party websites and/or social
                    networking sites) in order to show you targeted advertisements and other content that
                    has been customized for you.
                    We also use these cookies, web beacons, and other similar technologies to facilitate the
                    Forstec Targeted Ads Program and other advertisements to help us to determine which
                    advertisements users have seen, how many times users have seen a particular
                    advertisement, and on which sites a particular advertisement appeared. We may also
                    allow Employers who participate in our Forstec Targeted Ads Program to place cookies,
                    web beacons, and other similar technologies in advertisements we serve on our Site
                    and on third party websites to help these Employers understand how individuals interact
                    with our advertisements about their Job Listings.
                    We may also use these cookies, web beacons, and other similar technologies on the
                    Sites to understand and inform an Employer about which users saw a particular Job
                    Listing, Company Page, Hiring Event, or other advertisement in which the Employer is
                    identified.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>
                    3. What Cookies Do We Use
                  </div>
                  <div className='mb-10'>
                    We use first-party and third-party cookies differently on each of our Sites to provide our
                    many products and services to you. If you use any of the sites listed in the table below,
                    please follow the brand-specific links below for a complete list of our cookies used on
                    our Sites. All other visitors can view a list of our third-party cookie partners in the
                    second table below.
                    We may allow third-parties to use cookies on our Sites to help deliver and improve our
                    services, and provide them to you. Our use of these cookies may differ by site and
                    market. If your use of our Sites is not captured in the table above, please review your
                    browser settings for a list of all cookies stored on your device. The list below identifies
                    all of our third-party cookie provider partners in use as of the effective date of this
                    Cookie Policy.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>
                    4. Your Control over Our Use of Cookies
                  </div>
                  <div className='mb-10'>
                    The tables above may provide “Cookie Settings” options to our users in some
                    markets. Other controls available to our users include:<br />
                    <strong>– Browser and Device Specific Controls</strong><br />
                    Your browser may provide you the option of blocking cookies, such as third-party
                    cookies. Some browsers also give you the ability to review and manage cookies
                    individually. We encourage you to review your browser’s settings and documentation for
                    additional information on any controls your browser may offer and how they
                    work. Please note, if you delete from your device or browser any cookie Forstec uses
                    on our Sites, but do not set your browser or device to block third-party cookies, we may
                    install the same cookie during a later visit. Some third-party cookies are also integral to
                    the services we provide on our Sites. By blocking third-party cookies, you may not be
                    able to take advantage of all of the features available on our Sites.<br />
                    <strong>– Targeted Advertising Opt-outs</strong><br />
                    You can also opt out of targeted advertising that we do through our third-party
                    advertising partners, such as Google and Beeswax, by visiting resources they provide
                    We may update this list from time to time as similar initiatives become available in other
                    markets. We encourage you to visit all of these sites for additional information about
                    each organization’s approach to targeted advertising, lists of participating companies,
                    and the options available to web users.
                  </div>
                  <div className='mb-2 fs-4 fw-bolder'>
                    5. Changes To Our Cookie Policy
                  </div>
                  <div className='mb-10'>
                    Please review the “Last Updated” legend at the top of the Cookie Policy to determine
                    when it was last amended. Any changes will become effective on the “Last Updated”
                    date indicated above. By using the Sites or providing information to us following such
                    changes, you will have accepted the amended Cookie Policy. If Forstec is going to use
                    Personal Data collected through the Sites in a manner materially different from that
                    stated at the time of collection.
                  </div>
                  <div style={{ borderBottom: '1px solid #eeeeee', margin: '15px 0' }}></div>
                  <div className='mb-5'>
                    Kind regards, The Accelerant Team.
                    <tr>
                      <td
                        align='center'
                        style={{
                          fontSize: '13px',
                          textAlign: 'center',
                          padding: '20px',
                          color: '#6d6e7c',
                        }}
                      >
                        <p>Floor 5, Ben Aoun Jawhra, Sousse, 4000, Tunisia.</p>
                        <p>
                          Copyright 2022 ©
                          <a rel='noopener' target='_blank'>
                            Accelerant
                          </a>
                          .
                        </p>
                      </td>
                    </tr>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export { PrivacyPolicy }
