/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { Languages } from './Languages'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { useDispatch } from 'react-redux'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useIntl } from 'react-intl'

const HeaderUserMenu: FC = () => {


  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) 
  const intl = useIntl()

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='profile_image' src={user?.image_url ? user.image_url : toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user?.first_name} {user?.last_name}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>New</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user?.company?.name}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5 my-1'>
        <Link to='/account/overview' className='menu-link px-2'>
          <KTSVG path='/media/icons/duotune/communication/com013.svg' className='svg-icon-2 me-5' />
          {intl.formatMessage({id: 'MY.ACCOUNT'})}
        </Link>
      </div>


      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div> */}

      <div className='menu-item px-5'>
        <Link to={'/company/overview'} className='menu-link px-2'>
          <KTSVG path='/media/icons/duotune/finance/fin006.svg' className='svg-icon-2 me-5' />
          {intl.formatMessage({id: 'MY.COMPANY'})}

        </Link>
      </div>

      {/* <div className='menu-item px-5'>
        <Link to={'/company/overview'} className='menu-link px-2'>
          <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-2 me-5' />
          {intl.formatMessage({id: 'SETTINGS'})}

        </Link>
      </div> */}
      <div className='separator my-2'></div>

      <Languages />
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
        {intl.formatMessage({id: 'AUTH.LOGOUT'})}

        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
