/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const TermsOfService: React.FC = () => {
    const history = useHistory()


    return (
        <>
            <div
                style={{
                    fontFamily: 'Arial,Helvetica,sans-serif',
                    lineHeight: '1.5',
                    fontWeight: 'normal',
                    fontSize: '15px',
                    color: '#2F3044',
                    minHeight: '100%',
                    margin: '0',
                    padding: '0',
                    width: '100%',
                    backgroundColor: '#edf2f7',
                }}
            >
                <table
                    width='100%'
                    style={{ borderCollapse: 'collapse', margin: '0 auto', padding: '0', maxWidth: '70vw' }}
                >
                    <tbody>
                        <tr>
                            <td align='center' style={{ textAlign: 'center', padding: '40px' }}>
                                <a rel='noopener' target='_blank'>
                                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/LogoMenuRsk.png')} />
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td align='left'>
                                <div
                                    style={{
                                        textAlign: 'left',
                                        margin: '0 20px',
                                        padding: '40px',
                                        backgroundColor: '#ffffff',
                                        borderRadius: '6px',
                                    }}
                                >
                                    <div className='mb-2 fs-1' >
                                        <strong>Forstec General Terms of Service</strong>
                                    </div>

                                    <div className='mb-10 fw-bold fs-5'>
                                        Last Updated: April 25, 2022
                                    </div>

                                    <div className='mb-3 fs-4 fw-bolder'>
                                        Introduction
                                    </div>

                                    <div className='mb-10'>
                                        Each time you access or use Forstec’s online and/or mobile services and websites,
                                        including but not limited to any Forstec mobile application and browser extension or
                                        plugin (collectively the “Forstec Apps”), regardless of where it is downloaded from, and
                                        any software, service, feature, product, program and element provided by or on behalf
                                        of Forstec on or in connection with such services or websites (collectively, the “Site”),
                                        including, but not limited to, any products, programs, and services described in these
                                        Terms of Service, (a) you represent that you have read and understand the Cookie
                                        Policy and Privacy Policy; and (b) you are agreeing to the terms and conditions of these
                                        Terms of Service (the “Agreement”) then in effect with the following entity or entities.
                                        The Site is made available for use only by individuals searching for employment
                                        openings, or services or information related to their personal employment or job search
                                        (“Job Seekers”), by individuals and/or organizations seeking information related to hiring
                                        or human resources, seeking Job Seekers, or seeking to make available information
                                        regarding employment openings, on their behalf or other’s behalf, including but not
                                        limited to agencies purchasing for multiple parties and employment agencies
                                        (“Employers”), and by individuals and/or organizations seeking to participate in the
                                        Forstec Publisher Program (“Publishers”).
                                        Forstec may also act as an employment agency on behalf of Employers who purchase
                                        such services, through its affiliates such as Forstec Hire, Inc. You acknowledge and
                                        agree that your license to use the Site is automatically revoked upon your attempt to
                                        use the Site for any other purpose. If you are accessing or using the Site in your
                                        capacity as an employee or other representative of an Employer or Publisher, you are
                                        agreeing to this Agreement on behalf of yourself and such Employer or Publisher, as
                                        applicable, and you represent and warrant that you have the authority to bind such
                                        Employer or Publisher, as applicable, to this Agreement. If you are using assistive
                                        technology to access the Site and you need help, you can use contact us. You
                                        acknowledge that Forstec under RSK Solutions owns a copyright in the Site, Forstec
                                        Apps, and Services, including in compilations of information available through any of the
                                        foregoing.
                                        We may change this Agreement by notifying you of such changes by any reasonable
                                        means, including by posting a revised Agreement through the Site. Any such changes
                                        will not apply to any claim brought prior to the date on which we posted the revised
                                        Agreement incorporating such changes, or otherwise notified you of such changes.
                                        Your access to or use of the Site following any changes to this Agreement will constitute
                                        your acceptance of such changes. The “Last Updated” legends in each section of the
                                        terms and conditions indicate when this Agreement was last changed. We may, at any
                                        time and without liability, modify or discontinue all or part of the Site (including access to
                                        the Site via any third-party links); charge, modify or waive any fees required to use the
                                        Site; or offer opportunities to some or all Site users.
                                        This Agreement hereby incorporates by this reference any additional terms and
                                        conditions posted by Forstec through the Site, or otherwise made available to you by
                                        Forstec. By accessing or using the Site or any Forstec product, program, or service, you
                                        agree to be bound by the Forstec Terms of Service, including the “Terms of Service for
                                        All Users,” any additional terms and conditions posted by Forstec through the Site or
                                        otherwise made available to you. If you access or use the Forstec for Chrome
                                        Extension, you are agreeing to be bound by the Forstec Chrome Terms.
                                    </div>

                                    <div className='mb-2 fs-4 fw-bolder'>
                                        A. Terms of Service for Job Seekers
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        1. Job Ads or Job Listings
                                    </div>

                                    <div className='mb-10'>
                                        Forstec may make available Job Ads advertising employment opportunities and other
                                        job-related content, including links to third-party websites (“Job Listings” or “Job Ads”),
                                        through Forstec’s search results or otherwise through the Site. Searching for Job Ads
                                        on Forstec is free for Job Seekers. Job Ads are created and provided by third parties
                                        over whom Forstec exercises no control; you acknowledge and understand that Forstec
                                        has no control over the content of Job Ads, links to or from Job Ads, or any conditions
                                        third parties might impose once a Job Seeker has submitted an application or left the
                                        Site. For example, some of these third parties may attempt to charge Job Seekers a fee
                                        to apply to a particular job, although Forstec endeavors not to make such Job Ads
                                        available on the Site. If you leave the Forstec Site and choose to enter a third-party
                                        website, you accept any terms and conditions imposed by that third-party. Employer
                                        websites may have conversion trackers or use APIs and other functionality provided by
                                        Forstec, which informs us that an application has been completed on an Employer site.
                                        An Employer who installs such functionality is required under this Agreement to provide
                                        any notice, and obtain any prior consent, that may be required by applicable law.
                                        However, you acknowledge and agree that Forstec has no control over such an
                                        Employer or its website. You agree to Forstec’s use of, and receipt of information from,
                                        any such conversion tracker functionality. Except for sponsored, featured or paid
                                        placements, the Job Ads contained on, or linked from, the Site are indexed or posted in
                                        an automated manner. Forstec has no obligation to screen any Job Ads, or to include
                                        any Job Ads, in its search results or other listings, and may exclude or remove any Job
                                        Ads from the Site or your search result without any obligation to provide reasoning for
                                        removal or exclusion. You understand and agree that Forstec has no obligation to
                                        present you with any or all Job Ads. We cannot confirm the accuracy or completeness
                                        of any Job Ad or other information submitted by any Employer or other user, including
                                        the identity of such Employer or other user. Forstec assumes no responsibility, and
                                        disclaims all liability for the content, accuracy, completeness, legality, reliability, or
                                        availability of any Job Ads, Company Pages, screener questions and responses, and
                                        assessments. Additionally, Forstec may provide search options to narrow down Job Ads
                                        search results by job type categories (i.e. full-time, part-time, etc.), and such categories
                                        are created independently and entirely by Forstec, and may not directly or accurately
                                        reflect the content of the Job Ads. Forstec may reformat Job Listings so that you may
                                        read them more clearly on a mobile phone. Placement of a Job Ad on a certain page is
                                        not a representation regarding the nature of the role for legal purposes. While Forstec
                                        may in some circumstances be compensated by employers who post Job Ads, helping
                                        keep Forstec job search free for Job Seekers, all Job Ads are considered advertising.
                                        Forstec displays Job Ads based on a combination of compensation paid by employers
                                        to Forstec and relevance, such as search terms, and other information provided and
                                        activities conducted on Forstec. You are not permitted to use Forstec’s Site or its
                                        content other than for non-commercial purposes. For purposes of this Section A of the
                                        Forstec General Terms of Service, all references to “you” or “your” shall mean you, the
                                        individual or organization accessing this Site in your capacity as a Job Seeker. Forstec
                                        may provide functionality to call a telephone number contained in a Job Ad using the
                                        phone app on a mobile device. Forstec cannot guarantee that the extracted phone
                                        number is the correct phone number for the Employer or for the Job Ad you are viewing.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        2. Resumes, Profile, and Recommended Jobs
                                    </div>
                                    <div className='mb-10'>
                                        By creating a public resume through the Site (“Forstec Resume”) or uploading a file
                                        resume on the Site (collectively, “Your Resume” or “Job Seeker Resume”), you are
                                        requesting and authorizing Forstec to make available Your Resume to third parties,
                                        such as Employers, that Forstec believes may have an interest in Your Resume. If you
                                        do not want Forstec to make available Your Resume to third parties or if you do not
                                        want Employers to contact you, set your Forstec Resume and uploaded file resume to
                                        private. Setting Your Resume to private will not affect your previous applications or
                                        prevent employers you responded to from contacting you. You can read more about
                                        resume privacy.
                                        By creating an Forstec Resume or uploading a file resume on Forstec, Forstec may
                                        share with you Job Ads that match the contents of Your Resume. Forstec may also
                                        highlight to you items in Your Resume that may match qualifications from the Job Ad, or
                                        highlight to you items that appear in the Job Ad and may be missing from Your Resume.
                                        These highlights do not mean you are or are not qualified for the job or that you should
                                        or should not apply for it. Applying for such Job Ads does not guarantee job interviews
                                        or hiring. Even where Forstec highlights items or a Job Ad is shared with you, Forstec
                                        assumes no responsibility, and disclaims all liability for the content, accuracy,
                                        completeness, legality, reliability, or availability of Job Ads or highlights. By creating or
                                        uploading Your Resume, you are requesting and authorizing Forstec, or an Forstec
                                        vendor, to review or scan Your Resume and provide you feedback (in Forstec’s
                                        discretion), including suggested changes. Forstec assumes no responsibility and
                                        disclaims all liability for any changes you make based on any provided feedback.
                                        Forstec assumes no responsibility and disclaims all liability for the resume or
                                        application information you post, send or receive through the Site. By uploading a file
                                        resume on the Site, you agree that Forstec may convert your file resume to a different
                                        file type. It is your responsibility to review Your Resume to ensure that the content
                                        appears as you intend, and that it contains the right information or any information you
                                        intend to include or update. If you see any inaccuracy in any such material, it is your
                                        responsibility to correct such information or to contact Forstec to do so. It may take a
                                        few days for updates to your account or resume to be reflected. To mitigate fraud,
                                        Forstec may mask or hide your contact information, for example, we may mask or hide
                                        your email address and/or phone number, from Your Resume or application, and may
                                        substitute it with an alias. Forstec makes no promise to you about whether all or part of
                                        your contact info will be masked or hidden, and the decision to mask or hide such info is
                                        in Forstec’s sole discretion. Forstec reserves the right to limit your ability to set Your
                                        Resume to public, and may toggle a public resume to private, at any time, to prevent
                                        illegal conduct, for fraud mitigation, or for any other reason in Forstec’s
                                        discretion. Forstec makes no guarantee that Your Resume will be shown to any
                                        particular Employer or that any portion of the Forstec Resume database will be
                                        available to an Employer at any particular time.
                                        If you have an Forstec account, an Forstec Resume, or an uploaded file resume, you
                                        agree that Forstec may associate Your Resume data with your profile on Forstec
                                        (“Forstec Profile”). Your Forstec Profile consists of information you provide on the Site in
                                        the “Profile” section of your account. This includes your Forstec Resume, your scores
                                        on any Forstec Assessments you may have taken and any information that you choose
                                        to include, such as your desired job title, job types, work schedule, whether you are
                                        ready to work, or pay. Information in your Forstec Profile, except as otherwise indicated
                                        on the Site, may be available to third parties, such as Employers. If you do not want
                                        Forstec to make your Forstec Profile available to third parties or if you do not want
                                        Employers to contact you, set your Forstec Resume and uploaded file resume to private
                                        or remove Your Resume. Your response to an Forstec Assessment will be associated
                                        with your Forstec Profile. You may choose whether your Forstec Assessment response
                                        is available to Employers viewing your Forstec Profile. You can enable the ready to
                                        work feature on your profile to let Employers know that you can start work immediately.
                                        Forstec may disable that feature from your profile after a certain time, but you can
                                        manually enable it again.
                                        Forstec may also automatically send you recommended jobs via the email address you
                                        use to create an Forstec account or apply to a job. Forstec may determine what these
                                        recommended jobs are on the basis of a variety of factors, including but not limited to,
                                        any information you input on the Site about yourself (including in Your Resume and
                                        Forstec Profile), searches you run on the Site, jobs you click on, jobs you apply to, or
                                        the materials you provide in a job application to an Employer through Forstec.
                                    </div>

                                    <div className='mb-2 fs-6 fw-bolder'>
                                        3. Applying to Jobs Through Forstec
                                    </div>
                                    <div className='mb-10'>
                                        Any resume or application information that you submit through the Site, including
                                        Personal Data included in a resume, application or responses to screener questions
                                        and assessments (“Forstec Apply”), is subject to this Agreement (including Section D.3.)
                                        and to Forstec’s Privacy Policy. Prior to submitting an application, you acknowledge that
                                        you are responsible for reviewing and confirming that you are applying to your desired
                                        Employer. Once you provide information to an Employer (whether in the form of a job
                                        application, resume, email, or otherwise), Forstec does not have control over the
                                        Employer’s use or disclosure of that information. If you want to request the Employer
                                        delete, modify, or maintain confidence over any such information, you must make such
                                        a request directly to the Employer. (All references on the Site to “Apply Now”, “Easily
                                        Apply”, “Simple Apply”, “Apply from your phone”, “Apply with Forstec”, “RSVP to hiring
                                        event” or any similar references mean “Forstec Apply.”) Forstec may use your
                                        application materials to determine whether you may be interested in a Job Listing, and
                                        Forstec may reach out to you about such Job Listing. Forstec may store such
                                        information regardless of whether a Job Listing has been closed or is no longer
                                        available on the Site. To maintain the quality of the Site and Services, Forstec in its sole
                                        discretion may impose limits on your ability to apply to Job Listings or to other Forstec
                                        services. In addition, when you indicate your interest in a Job Listing through Forstec
                                        Apply or by RSVPing to a hiring event you are sending your resume and application
                                        information to Forstec, and you are requesting and authorizing Forstec to make
                                        available such application information to the applicable Employer(s) for such Job
                                        Listing(s). When you ask Forstec to transmit an application or a message, including, but
                                        not limited to, a signed offer letter, to an Employer via Forstec Apply or Forstec’s relay
                                        system, or store such application, you understand that this is without warranty, and that
                                        Forstec reserves the right to reformat such application or message.
                                        You acknowledge and agree that Employers may request that Forstec assemble your
                                        application materials, resume, answers to screener questions, assessment responses
                                        and other information you provide to Forstec into one document, and you agree that in
                                        doing so, Forstec assembles these application materials on your behalf. When you
                                        apply for a job on Forstec you agree to Forstec’s performance of automated processing
                                        in relation to your application, as such processing is an essential part of this Agreement.
                                        Additionally, you consent to your application and any responses sent to you by the
                                        Employer (including offer letters) through Forstec being processed and analyzed by
                                        Forstec according to this Agreement and Forstec’s Privacy Policy.
                                        When you apply to a job using Forstec Apply, Forstec will attempt to send your
                                        application to the contact information provided to us by an Employer or their Agent,
                                        which may include sending your application to an Applicant Tracking System (ATS)
                                        selected by the Employer. We cannot guarantee that such messages and applications
                                        will be delivered, received, accessed, read or acted upon. Forstec also does not
                                        guarantee that any Employer will receive, be notified about, access, read or respond to
                                        any such resume or other application material, or that there will be no mistakes in the
                                        transmission or storage of the data. Further, Forstec does not guarantee that the
                                        interface will be error-free. However, Forstec may alert you when any of the above
                                        events occur. Please note that Job Ads may expire between the time you submit your
                                        application and from the time it is received. Forstec has no responsibility for expired Job
                                        Ads or for delivering applications prior to a Job Ad’s expiration. In addition we cannot
                                        vouch for the validity of the contact information provided to us by Employers. We also
                                        cannot vouch for the technical capabilities of any third party sites.If you do not feel
                                        comfortable sending an application or messages in this manner, or having your
                                        application or messages stored on Forstec, do not use the Forstec Apply or Forstec
                                        relay functions and please send your application or messages directly to the Employer
                                        by whatever other method you so choose, including the public mail system. You may
                                        contact the Employer directly to find alternative methods of application if you do not
                                        wish to apply through Forstec. By using the Forstec Apply system, you fully consent to
                                        the above.
                                        If you require alternative methods of screening or application you must approach the
                                        Employer directly to request such as Forstec is not responsible for the Employer
                                        application process.
                                        By using Forstec, you agree that Forstec is not responsible for the content of the
                                        Employer’s job application, messages, screener questions, skills assessments or their
                                        format or method of delivery, and that Forstec does not guarantee receipt of your
                                        application by the Employer, or your receipt of messages from the Employer. Please
                                        note that Forstec does not choose the questions asked by Employers or decide the job
                                        qualification criteria of Employers. Some questions may be labeled as Optional,
                                        indicating only that the application may be submitted to the Employer without providing
                                        an answer. Forstec cannot guarantee that the Employer will consider such an
                                        application or make a particular determination with regard to such an application.
                                        Forstec may inform Job Seekers that an Employer’s requirement from a job description
                                        does not appear to be present on a resume or application; this information is presented
                                        without warranty and Forstec does not guarantee that updating a resume or application
                                        will result in any job offer. Forstec does not guarantee the identity of an Employer or any
                                        individuals working for any Employers, and cautions Job Seekers when applying to jobs.
                                        Forstec cannot make any guarantee regarding health and safety measures in an
                                        Employer’s hiring or interviewing process. Forstec recommends Job Seekers follow
                                        personal health and safety best practices as recommended by the World Health
                                        Organization or similar institution.
                                        <strong>
                                            Forstec does not guarantee the validity of a job
                                            offer and cautions Job Seekers to verify the validity of a job offer before taking an
                                            adverse action regarding their current employment situations. Job Seekers are
                                            solely responsible for verifying the accuracy of any Employer or job offer.
                                        </strong>
                                        Forstec may send you a notice relating to account security, such as informing you that
                                        you may have recently interacted with a compromised account. Forstec cannot and
                                        does not guarantee that such notices will always be sent or received, and you therefore
                                        agree that Forstec bears no responsibility for doing so. Forstec makes no warranty
                                        regarding, and disclaims any liability for, the accuracy, completeness, timeliness, or
                                        reliability of such notices.
                                        Forstec may use your application materials (including resumes and responses to
                                        screener questions) and the recency of your activity on Forstec to determine whether
                                        the words of any Job Seeker’s resume and answers to screener questions match the
                                        words of a Job Listing or Resume query, and vice-versa. You agree and consent that
                                        Forstec may differentiate those matching resumes and screener questions from those
                                        that do not match, and present them to Employers as matches or not matches. Forstec
                                        may also use such information in order to improve the Site or any other Forstec product
                                        or service (including by displaying or otherwise making available potentially relevant Job
                                        Listings and resumes to Job Seekers and Employers). Forstec may also highlight to you
                                        items in your prior application materials (including items in your resume and past
                                        responses to screener questions) that may match qualifications from the Job Listing, or
                                        highlight to you items that appear in the Job Listing that may not match your application
                                        materials. These highlights do not mean you are or are not qualified for the job or that
                                        you should or should not apply for it. Applying for such Job Listings does not guarantee
                                        job interviews or hiring. Even where Forstec highlights items or a Job Listing is shared
                                        with you, Forstec assumes no responsibility, and disclaims all liability for the content,
                                        accuracy, completeness, legality, reliability, or availability of Job Listings or highlights.
                                        By using Forstec Apply and answering screener questions, you acknowledge that
                                        Employers may have instructed Forstec to send out rejection notices if your answers do
                                        not match the answers sought by the Employer, and you acknowledge that Forstec has
                                        no discretion in the transmission of these rejections. Forstec may offer Employers the
                                        ability to activate a feature on their account called Employer Assist. Activating Employer
                                        Assist means the Employer is instructing Forstec to send a message on the Employer’s
                                        behalf to the Job Seeker informing them that the Employer has determined not to move
                                        forward with their application. These notices are automatically sent after a period of time
                                        selected by the Employer, unless the Employer indicates to Forstec their interest in your
                                        application. You acknowledge and agree that Forstec has no discretion in the sending
                                        of these messages, and that any such messages are solely a result of the Employer’s
                                        decision to activate Employer Assist and to not take an action on your application on
                                        Forstec within the time period selected by the Employer. Any interactions the Employer
                                        has directly with you and not through a tool provided by Forstec (for example, calling or
                                        emailing you directly instead of through an Forstec Relay Service) are not visible to
                                        Forstec, and would not by themselves prevent an Employer Assist rejection notice from
                                        being sent. Forstec assumes no responsibility, and disclaims all liability, for the content,
                                        accuracy, validity, completeness, legality, reliability, or availability of any Employer
                                        Assist notices.
                                        By using Forstec Apply and answering screener questions, you acknowledge that
                                        Employers may have instructed Forstec to schedule interviews with Job Seekers who
                                        meet criteria (based on information the Job Seeker has provided to Forstec, such as
                                        answers to screener questions, resume, and Forstec Assessment Responses) that the
                                        Employers have selected, and based on the Employers’ availability. You agree that
                                        Forstec has no discretion in the transmission or storage of these interview invitations
                                        (which are purely mechanical), that transmission or storage is not guaranteed, and that
                                        the availability or criteria the Employer provided to Forstec may not be accurate. Forstec
                                        may request that Job Seekers confirm their interest in the position before scheduling an
                                        interview. It is ultimately your and the Employer’s responsibility to confirm whether any
                                        interviews were scheduled, or to reschedule or cancel interviews if necessary. The
                                        decision of who to interview is decided solely by the Employer, who can choose to
                                        interview any Job Seeker at any time.
                                        When you search for jobs on the Forstec Job Search app and proceed to apply for a
                                        job, including jobs on third party sites, Forstec may suggest information from your
                                        Forstec profile to include in your application. It is your responsibility to review
                                        suggestions before accepting them and including them on your application.
                                        You acknowledge that as part of its efforts to combat fraud and spam, Forstec may
                                        require that users verify their email address. When you apply to a job through Forstec,
                                        you agree that you may be required to verify your email address, and that failure to
                                        verify may lead to your application being rejected.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        4. Communications and Other Actions on the Site
                                    </div>
                                    <div className='mb-10'>
                                        When you view, send, store or receive materials (including Job Listings, resumes,
                                        messages, applications, questions and responses in applications, and any other
                                        information) through or using the Site, Forstec may, for example, use such materials for
                                        data analysis, quality control, or to refine the Site or any other Forstec product or
                                        service (including to provide better search results and other listings for Job Seekers and
                                        Employers), whether via automated means or otherwise. When an Employer views,
                                        sends, stores or receives materials through or using the Site, Forstec may inform you
                                        about such actions. Additionally, we may also inform you that an Employer has taken
                                        other actions with regards to a Job Ad, your Resume, or your application, such as
                                        pausing or closing a Job Ad, opening your Resume or application, viewing your Resume
                                        or application, responding to your Resume or application, and making a decision with
                                        regards to your application or Job Ad. Forstec may inform the Employer about activities
                                        you take on the Site or your use of the Site, for example, whether you are online or
                                        active on Forstec, and you hereby consent to Forstec taking such actions.
                                        If you have turned on browser push notification and wish to turn them off, you may do
                                        so by visiting the settings on your browser.
                                        Forstec may offer you the opportunity to receive a text message interview reminder from
                                        Forstec when an Employer has scheduled interviews with you. In such an event you will
                                        enter your phone number on the Forstec consent form and by entering your phone
                                        number you are representing and confirming it is your phone number and that you have
                                        the right to accept text messages at the number. Forstec will only send you a text
                                        message reminder for such interview and you agree to accept such text message
                                        reminder on your cell phone, including messages sent by automated telephone dialing
                                        system. You acknowledge that if the words “automated,” “automatic,” or similar words
                                        are used on the Site in connection with calls or text messages, these words do not refer
                                        to the generation or storage of a phone number. Forstec only calls the number that you
                                        generated and stored when you provided your number to Forstec. In the event you
                                        change your phone number, you agree to promptly update your Forstec account with
                                        the new number. You agree that by providing your phone number or using the Site, you
                                        are establishing a business relationship with Forstec and that Forstec may send you
                                        text messages in connection with that relationship. Please note that since these text
                                        message services depend on the functionality of third-party providers, there may be
                                        technical delays on the part of those providers. We also cannot vouch for the technical
                                        capabilities of any third parties to receive such text messages.
                                        Forstec may also insert functionality into messages relating to your application allowing
                                        you to place calls directly to third parties. Please note this functionality is provided solely
                                        as a courtesy and that such calls are not placed through or tracked by Forstec. Your
                                        standard calling rates with your phone provider will apply.
                                        You may receive messages, including but not limited to text messages, emails or email
                                        notifications corresponding with your or an Employer’s (in the event you applied for a
                                        job) activity on or use of the Site, Forstec Apply, Forstec Chat, or any other
                                        communications service, product, or feature provided on or through the Site. In all
                                        cases, such messages or notifications are provided solely as a courtesy, and you
                                        should not rely on them. For example, if you accept an interview request, it is your
                                        responsibility to follow up with the employer separately to ensure they know your
                                        response, do not rely on notifications through Forstec. Forstec disclaims all warranties
                                        with regards to the transmission or storage of such courtesy notices, does not
                                        guarantee their delivery or receipt, and does not guarantee the date or time at which
                                        they may be sent. In the event a message being sent is intended for a closed account,
                                        these messages will not be deliverable.
                                        Forstec may use your email address to create an alias email address for your
                                        communication, in lieu of displaying your actual email address to the Employer. Once
                                        an Employer has your contact info, you agree that Forstec is not responsible or liable for
                                        the manner in which the Employer uses the info. If an Employer sends an email to
                                        multiple recipients (e.g. by adding additional aliased email addresses to the cc field in a
                                        third party email client), this may affect the way messages on Forstec are displayed. In
                                        this case, it is best to view the email conversation in the third party email client. Should
                                        you choose to send an email from a third party email client, the person with whom you
                                        are communicating may be able to see your email address, rather than the alias. If you
                                        send or receive communication on or through the Site, you agree to your
                                        communication being processed, analyzed, reviewed, and stored, including via
                                        automated means, for data analysis, quality control, enforcement of the Sites’ rules and
                                        other Forstec policies, content moderation, and to improve the Site or any other Forstec
                                        product or service.
                                        Forstec may enable chatbots, which allows you to take certain actions on the Site, such
                                        as apply to a job, schedule an interview, or otherwise interact with the Site, Employers,
                                        or Job Listings on our Site (“Chatbot”). You understand that the Chatbot is an
                                        automated service that does not involve human interaction. It is your responsibility to
                                        ensure the accuracy and completeness of information submitted through the Chatbot.
                                        Forstec is not responsible for any loss, damage or inconvenience arising as a
                                        consequence of the use (or inability to use) the Chatbot or from the information you
                                        submit via the Chatbot.
                                        Forstec reserves the right to turn on or enable Forstec Chat or other communication
                                        options for select Employers or Job Listings, in its sole discretion, and to notify you that
                                        Forstec Chat or other communication options are available for a particular job or
                                        Employer. For Forstec Chat, you may access or view your messages by visiting your
                                        Forstec Chat messages dashboard. Forstec may, in its sole discretion, turn off or
                                        disable Forstec Chat for any Employer or Job Seeker at any time without prior notice.
                                        Please note that if you are using Forstec Chat to communicate with an Employer,
                                        Forstec will deliver your messages as instructed by the Employer.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        5. Virtual Communications
                                    </div>
                                    <div className='mb-10'>
                                        Employers may offer you the opportunity to participate in virtual and remote
                                        communications using Forstec products, including but not limited to, Forstec Interview,
                                        Forstec Hiring Platform, phone interviews, virtual meetings, and video interviews
                                        (“Virtual Interviews”). You understand that Forstec is not a telecommunications service
                                        provider and that Forstec is only providing the option for you to communicate with
                                        Employers via services that may be offered by third-party providers. Please note that
                                        since these telecommunication services may depend on the functionality of third-party
                                        providers, there may be technical delays or malfunctions on the part of those providers.
                                        We cannot vouch for the technical capabilities of any third parties to receive, transmit, or
                                        support such phone or video communications. Forstec does not guarantee any aspect
                                        of your Virtual Interview experience including transmission of phone or video
                                        communications, quality of audio/visual content, data security, or data usage and
                                        restrictions. <strong>Forstec is not liable for any claims arising out of your use of Virtual
                                            Interviews and you release Forstec from any such claims.</strong>
                                        You understand that Forstec does not guarantee the Employer’s schedule or availability
                                        for conducting Virtual Interviews and cannot vouch for the validity of the contact
                                        information provided to us. You also understand that the Employer is responsible for
                                        any and all questions, comments, or hiring decisions made. Further, Employers are
                                        responsible for any accommodations you need during Virtual Interviews.
                                        NOTICE – Virtual Interviews May be Recorded: You understand that an Employer may
                                        enable the recording of a Virtual Interview. You agree to respect any notification (visual,
                                        audio, or otherwise) which may indicate that recording is enabled by an Employer on
                                        Virtual Interview. If you do not agree to be recorded, you must immediately leave the
                                        Virtual Interview. By using Virtual Interviews, you agree that the Virtual Interview may be
                                        recorded and that Forstec and third party providers can store, access and analyze the
                                        recording. Also, you agree that an Employer activating the recording function, and
                                        subsequently accessing, storing, using, or analyzing the recording is outside of
                                        Forstec’s control.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        6. ForstecAssessments
                                    </div>
                                    <div className='mb-10'>
                                        Forstec Assessments is an online tool for the provision and review of skills assessments
                                        selected by Employers (“Assessments”) and responses from Job Seekers
                                        (“Responses”) through the Site. Employers may use the Forstec Assessments tool to
                                        send you Assessments, to which you may provide Responses. After you provide a
                                        Response, the Employer will be able to use the Forstec Assessments platform to review
                                        the Response. If there are multiple versions of the same Assessment, the Response
                                        percentile for any Job Seeker will be determined only in relation to other Responses to
                                        the same version of that Assessment.
                                        An Assessment only evaluates a particular skill. It does not evaluate a Job Seeker’s
                                        qualification for any job. Whether a particular skill is relevant to a job, or whether a Job
                                        Seeker is qualified or appropriate for a job, is decided solely by the Employer sending
                                        the Assessment and reviewing your Response. Receiving or responding to an
                                        Assessment does not guarantee a job or job offer, or a job offer at any particular salary,
                                        or any further communication or action by any Employer. An Employer is the sole party
                                        to determine whether a Response indicates a qualified Job Seeker. Employers may
                                        have instructed Forstec to send out rejection notices if you have not responded to
                                        Assessments in a manner acceptable to the Employer, and you acknowledge that
                                        Forstec has no discretion in the transmission of these rejections. The Employer is the
                                        sole party to determine whether to consider any retaken Assessments as part of their
                                        hiring process.
                                        You acknowledge and understand that Employers (or their employment agencies)
                                        select which Assessments to associate with their job openings, and that Forstec does
                                        not choose the Assessments sent by Employers and has no control over Employers’
                                        selection or use of Assessments. You agree that the questions in any Assessment are
                                        solely being asked by the Employer sending the Assessment.
                                        <strong>
                                            You further agree that
                                            Forstec is not responsible for offering alternative methods of screening, if so
                                            required by the Americans with Disabilities Act or any other equivalent or similar
                                            law, and that the responsibility for offering any such alternative method lies
                                            solely with Employers. I
                                        </strong>
                                        If you require an accommodation, or any alternative method(s)
                                        of Assessment, screening or application, you must indicate it to the Employer. Forstec
                                        may provide a means by which you may request such an alternative method or other
                                        accommodation from the Employer; Forstec does not guarantee the Employer’s receipt
                                        of, or response to, any such request. While Forstec does not warrant that the method of
                                        delivery of any Assessment question is compliant with the Americans with Disabilities
                                        Act or any equivalent or similar law.
                                        You agree and acknowledge that the usefulness and value of Assessments and
                                        Responses depend on their contents being kept confidential. You therefore agree to
                                        keep the content of Assessments and Responses confidential and to not reproduce or
                                        discuss them with anyone, except that you may communicate with an Employer about
                                        an Assessment they have sent to you and your Response.
                                        Forstec does not have any obligation to screen any Assessment or Response, or to
                                        include any Assessment or Response in the Site, and may exclude or remove any
                                        Assessment or Response from the Site for any or no reason without liability or notice.
                                        Assessment links sent to you may continue to be active after an Employer has paused
                                        or closed their job campaign on Forstec. Once an Employer pauses or closes their Job
                                        Ad (for example, after the Employer has made a hire), Forstec cannot guarantee that
                                        the Employer will view any related notices or updates, including Assessments,
                                        Responses, and accommodation requests. You may still choose to take the
                                        Assessment for the purpose of adding its results to your Forstec Profile or for another
                                        job application.
                                        Forstec may also offer you the option to select an Assessment to complete independent
                                        of a particular job, and your Response will be associated with your Forstec Profile. You
                                        may choose whether the Response is available to Employers viewing your Forstec
                                        Profile, though Forstec will count the Response for purposes of generating aggregate
                                        Response statistics even if Your Response is hidden.
                                        You may be invited to choose to automatically share your Responses to specific
                                        Assessments with other employers who request the same skills test from you in the
                                        future as part of the job application process. Note that this is a completely separate
                                        preference from sharing settings on your Forstec Profile, which only controls whether
                                        your results are available on your Forstec Resume. If you choose to automatically share
                                        your Responses to specific Assessments with other employers who request the same
                                        skills test from you as part of the job application process, you acknowledge that Forstec
                                        has no discretion in the transmission or storage of the Assessments Responses you
                                        choose to share automatically, and that transmission or storage is not guaranteed.
                                        As a Job Seeker, you consent to your Responses, applications, and any other
                                        communications sent through Forstec Assessments being processed and analyzed by
                                        Forstec according to this Agreement and Forstec’s Privacy Policy. You are requesting
                                        and authorizing Forstec to make your Response available to the Employer providing the
                                        Assessment, or (in the case of Assessments selected by you) to any Employer viewing
                                        Your Forstec Profile. You understand that this is without warranty, and that Forstec
                                        reserves the right to display or reformat Responses in a manner to permit Employers to
                                        review your Response together with Responses from other Job Seekers. Forstec may
                                        aggregate the results of all Job Seeker-selected Assessments for the purpose of
                                        displaying the percentile for Your Response. You also acknowledge that once you have
                                        requested that Forstec transmit your Response to an Employer, that request cannot be
                                        canceled. Forstec does not guarantee that any Employer will receive, access, read or
                                        respond to any Response, or that there will be no mistakes in the transmission of the
                                        data. However, Forstec may alert you when any of the above events occur.
                                        As a Job Seeker, you shall not create or send any Response which: (i) contains any
                                        highly confidential personal information, such as bank account or credit card
                                        information, online account information, social security numbers (or similar counterparts
                                        outside the United States of America), health information, or other categories of data
                                        subject to special breach notification requirements in any country; (ii) contains
                                        proprietary information, trade secrets, confidential information, advertisements,
                                        solicitations, chain letters, pyramid schemes, investment opportunities, or other
                                        unsolicited commercial communication (except as otherwise expressly permitted by us
                                        in writing); or (iii) directly or indirectly violates the Site Rules.
                                        Forstec does not act as an employment agency by offering the Forstec Assessments
                                        tool. By using Forstec Assessments, you acknowledge and agree that Forstec is not
                                        procuring employees for Employers or procuring opportunities to work for Job Seekers.
                                        Forstec merely provides a tool enabling Employers and Job Seekers to exchange
                                        Assessments and Responses as they determine. The sole responsibility for the content
                                        of any Assessment or Response, any requests for interviews or offers made, any issues
                                        arising from an acceptance or denial of employment, and any other issues arising from
                                        the use of Forstec Assessments, is solely with Employers or Job Seekers as applicable.
                                        As a Job Seeker, you agree that any rights you have under any applicable employment,
                                        equality or discrimination laws, the US Fair Credit Reporting Act, any corresponding
                                        state laws, or any similar laws regulating consumer or credit reporting agencies in other
                                        countries, may only be asserted against the Employer.
                                        Forstec is not a third party beneficiary of or liable for any agreements between an
                                        Employer and Job Seeker, regardless of whether or not Forstec receives a fee from the
                                        Employer in connection with the transaction. Forstec will not be liable for any costs or
                                        damages arising out of or related to such transaction.
                                        Forstec assumes no responsibility, and disclaims all liability, for the content, accuracy,
                                        validity, completeness, legality, reliability, or availability of the Site, the Forstec
                                        Assessments tool, and any Assessment or Response.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        7. Salary, Career, Hiring and Other Information Provided by Forstec
                                    </div>
                                    <div className='mb-10'>
                                        Forstec may provide some information and content to users for informational purposes
                                        only. For example, Forstec may provide you with data regarding estimated salaries for a
                                        given Job Listing, number of applies to a Job Listing, responses to certain screener
                                        questions for a Job Listing, or the likelihood that a particular event will occur such as
                                        being selected for an interview. This information provided by Forstec is based on
                                        estimates given for informational purposes only and without warranty, and is subject to
                                        change or varying levels of accuracy. Forstec may also include salary estimations on
                                        pages other than Job Listings on the Site. Please note that all salary figures are
                                        approximations based upon multiple third party submissions to Forstec, including from
                                        Forstec affiliates. These figures are given to Forstec users for the purpose of
                                        generalized comparison only. Minimum wage may differ by jurisdiction and you should
                                        consult the employer for actual salary figures.Forstec, in its sole discretion, may add
                                        labels or badges to Employer names or Job Ads, such as, “Responsive Employer” or
                                        “Active Employer” or “Hired on Forstec”. Employers may also request that Forstec add
                                        such labels. Forstec shall determine the method by which such labels or badges are
                                        determined or which Employers qualify. The lack of a label or badge may indicate that
                                        Forstec does not have sufficient data to determine if an Employer qualifies. Some of the
                                        data may be provided by the Employer and Forstec does not guarantee the accuracy of
                                        such data. Forstec does not guarantee the accuracy of any label or badge that is added
                                        to Employer names or Job Ads based on employer provided data, including data on Job
                                        Ads. Forstec may also display publicly available information about employers on
                                        Company Pages. Information on Company Pages is presented for informational and
                                        promotional purposes only, is subject to change, and may be gathered from or
                                        generated by third parties. Forstec assumes no responsibility, and disclaims all liability
                                        for the content, accuracy, completeness, legality, reliability, or availability of any
                                        Company Page. Please contact the employer for the most accurate and up-to-date
                                        company information.
                                        Similarly, career-related or hiring-related information provided by Forstec is for
                                        informational purposes only, and is in no way to be construed as professional career
                                        counseling or staffing services (unless otherwise expressly noted by Forstec in services
                                        such as Forstec Hire in Japan). You understand that Forstec may give suggestions or
                                        information regarding best-practices, however, you understand that it is ultimately your
                                        responsibility to determine how to pursue your job search or candidate search. You
                                        further understand that in providing any such career or hiring guides, Forstec is not
                                        acting as a job placement agency or staffing firm (unless otherwise expressly noted by
                                        Forstec in services such as Forstec Hire in Japan). You also understand that by
                                        referring to or using such career or hiring guides, you are not guaranteed job interviews,
                                        job placement, or assurance of being hired, and you take full responsibility for use of
                                        these services or guides. As a user of the Site, Forstec may, from time to time, send
                                        you emails from the career or hiring guides as part of its services to you. These emails
                                        may include but not be limited to, tips on improving your resume or how to use your
                                        resume effectively in the job application process, commonly asked interview questions,
                                        next steps after you have submitted an application, and how to prepare for a new job
                                        after you have been hired.
                                        Forstec may offer, as part of the Services, the opportunity for you to engage with third
                                        parties, sometimes referred to as career coaches or professional writers, to discuss job
                                        search, resume writing, or career strategies, either through the Site or in person.
                                        Regardless of how you interact with them, you acknowledge that these third parties are
                                        not employees of Forstec and that Forstec is not responsible for the content of any such
                                        discussion. Do not share any information you consider confidential or personally
                                        sensitive with these third parties. Forstec does not guarantee the quality or
                                        effectiveness of any advice, recommendations, or guidance you may receive from these
                                        third parties. You agree that, to the maximum extent permitted by law, Forstec will have
                                        no liability arising from any discussion you have with such a third party.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        8. Your Job Seeker Data
                                    </div>
                                    <div className='mb-10'>
                                        As a Job Seeker, you take a variety of actions on our Site and you provide various
                                        information. For example, you search for jobs, and Forstec knows and stores the titles
                                        of jobs you search for and click on, where those jobs are located, the general salary
                                        range or experience level of the jobs you view (if indicated on the Job Listings), the Job
                                        Listings you apply to, your amount of activity or time of most recent activity on Forstec,
                                        information you provide directly to Forstec including desired salary, or past experience,
                                        or any information in your Forstec Profile, or any of your other behavior on the Site or
                                        information provided. You know exactly what this data is because you are the person
                                        who provided the information or undertook the activity and the data pertains only to your
                                        activity. If you have an Forstec account or a Job Seeker Resume, you agree that
                                        Forstec may associate this data with your Forstec Profile and use this observed factual
                                        data to suggest jobs to you and to suggest you or your public resume to Employers that
                                        might be interested in a person who matches your behavior on Forstec. You also agree
                                        that Forstec may contact you based on this observed behavior or provided info on
                                        behalf of Employers or Forstec itself. Please note, the aforementioned actions do not
                                        include information sent to you by a third party, however, Forstec may publicly display
                                        the fact that you have recently used the Forstec Site to correspond with a third party. <strong>In
                                            addition, if you set Your Resume to public, this means that Forstec may share all
                                            of the aforementioned information about yourself with third-party Employers. If
                                            you do not wish to share such information, you may set Your Resume to private.
                                            When you make changes to your Forstec Profile, including, but not limited to,
                                            updating, deleting, or changing settings on Your Resume, it may take a few days
                                            for the changes to be reflected</strong>.
                                        In addition, you may have the opportunity to provide Forstec with certain demographic
                                        information about yourself, such as your race and ethnicity, gender, age, LGBTQ+
                                        community membership, and disability status (“Demographic Information”). By choosing
                                        to provide your Demographic Information, you agree that Forstec may use it to evaluate
                                        and improve our products, and share aggregate summaries of job seeker information
                                        with employers. If you do not want your Demographic Information to be used in this
                                        way, do not provide it to Forstec (or, if you have already provided your Demographic
                                        Information to Forstec, you can request it be removed). You further acknowledge and
                                        agree that another Job Seeker’s Demographic Information is personal information, and
                                        to the extent permitted by law you waive any right to request or view Demographic
                                        Information pertaining to any other Job Seeker.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        9. Screening Tools
                                    </div>
                                    <div className='mb-10'>
                                        Forstec may make screening tools available to Employers for Employer use in the
                                        application process, including screener questions, phone screen tools and
                                        assessments. Forstec is licensing these tools for Employers’ use as determined by
                                        Employers. As a Job Seeker you agree that the Employer has made the determination
                                        to use these tools as part of its application process, and the questions asked are solely
                                        determined by the Employer, and are not being asked by Forstec. The Employer is the
                                        sole party to determine which answers will qualify a candidate and is solely responsible
                                        for the use of the screening tool including any results which are considered to have a
                                        “disparate impact”. You further acknowledge that only the Employer is responsible for
                                        offering alternative methods of screening, if so required by the Americans with
                                        Disabilities Act or any other equivalent or similar law. While Forstec is guided by WCAG
                                        2.1, Level AA in our efforts to design and develop accessible offerings, as outlined in
                                        the accessibility statement, Forstec does not warrant that the method of delivery of
                                        these questions is compliant with the Americans with Disabilities Act or any equivalent
                                        or similar law.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        10. Forstec Hiring Platform
                                    </div>
                                    <div className='mb-10'>
                                        Forstec Hiring Platform is a platform designed to automate and streamline Employer
                                        hiring and employment decision-making processes by allowing users access to various
                                        products such as Forstec Interview, or related services (“Forstec Hiring Platform”).
                                        Employers may offer you the opportunity to participate in hiring activities that might be
                                        supported by Forstec Hiring Platform. You acknowledge and understand that Employers
                                        select the method and manner that Forstec Hiring Platform will be used for its job
                                        openings and that the Employer determines the job requirements and specific needs of
                                        its hiring efforts. YOU UNDERSTAND AND AGREE THAT EMPLOYER IS
                                        RESPONSIBLE FOR COMPLYING WITH APPLICABLE LAW REGARDING ITS
                                        EMPLOYMENT AND HIRING PRACTICES, INCLUDING TITLE VII, AND THAT
                                        EMPLOYER INDEMNIFIES FORSTEC AGAINST ANY AND ALL CLAIMS ARISING
                                        FROM EMPLOYER’S USE OF FORSTEC HIRING PLATFORM OR SIMILAR
                                        PRODUCTS. FORSTEC DISCLAIMS ALL LIABILITY AND MAKES NO WARRANTY
                                        THAT EMPLOYER’S USE OF THE SERVICES COMPLIES WITH TITLE VII OR ANY
                                        SIMILAR LAW. Forstec has no control nor participates in the hiring or decision-making
                                        process regarding Employer hiring efforts. Further, you understand that Forstec is not
                                        an employment agency by offering Forstec Hiring Platform and related tools. By using
                                        Forstec Hiring Platform, you understand that Forstec is not procuring employees for
                                        Employer or opportunities for Job Seekers. Forstec merely provides Forstec Hiring
                                        platform as a tool that enables Employers and Job Seekers to exchange information as
                                        they determine.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        9. Screening Tools
                                    </div>
                                    <div className='mb-10'>
                                        Forstec may make screening tools available to Employers for Employer use in the
                                        application process, including screener questions, phone screen tools and
                                        assessments. Forstec is licensing these tools for Employers’ use as determined by
                                        Employers. As a Job Seeker you agree that the Employer has made the determination
                                        to use these tools as part of its application process, and the questions asked are solely
                                        determined by the Employer, and are not being asked by Forstec. The Employer is the
                                        sole party to determine which answers will qualify a candidate and is solely responsible
                                        for the use of the screening tool including any results which are considered to have a
                                        “disparate impact”. You further acknowledge that only the Employer is responsible for
                                        offering alternative methods of screening, if so required by the Americans with
                                        Disabilities Act or any other equivalent or similar law. While Forstec is guided by WCAG
                                        2.1, Level AA in our efforts to design and develop accessible offerings, as outlined in
                                        the accessibility statement, Forstec does not warrant that the method of delivery of
                                        these questions is compliant with the Americans with Disabilities Act or any equivalent
                                        or similar law.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        11. Governing Law and Dispute Resolution
                                    </div>
                                    <div className='mb-10'>
                                        This Agreement and any dispute arising out of or in connection with this Agreement or
                                        related in any way to the Site (“Dispute”) will be governed as to all matters, including,
                                        but not limited to the validity, construction and performance of this Agreement.
                                        You also hereby waive any right to a jury trial in connection with any action or litigation
                                        in any way arising out of or related to your use of the Site or these terms of service, if
                                        you are located in the United States.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        12. Class Action Waiver
                                    </div>
                                    <div className='mb-10'>
                                        By using the Site or any Program Elements and in return for the services offered by
                                        Forstec, you acknowledge that Forstec can only offer you these services under the
                                        terms and conditions as presented herein. As partial consideration for your use of the
                                        Site and the Publisher Program, you agree not to sue Forstec as a class plaintiff or
                                        class representative, join as a class member, or participate as an adverse party in any
                                        way in a class-action lawsuit against Forstec regarding your use of the Site.
                                        Additionally, as a Job Seeker, you acknowledge that your use of job search, Forstec
                                        Apply, and other free services on the Site is not purchased. Your acceptance of this
                                        Agreement, including this Class Action Waiver, is an essential part of the bargain
                                        allowing your free use of the Site. If you do not agree to any part of these terms, do not
                                        continue your use of the Site. Nothing in this paragraph, however, limits your rights to
                                        bring a lawsuit as an individual plaintiff, including in small claims court, subject to
                                        Section 11 above.
                                    </div>
                                    <div className='mb-2 fs-4 fw-bolder'>
                                        B. Terms of Service for Employers
                                    </div>
                                    <div className='mb-10'>
                                        The following terms and conditions apply to all Employers and other users who access
                                        or use the Site as intended for individuals and/or organizations seeking to make
                                        available information regarding employment openings, on their behalf or other’s behalf,
                                        including but not limited to agencies purchasing for multiple parties, or otherwise
                                        indicate their acceptance to this Agreement. For purposes of this Section B of the
                                        Forstec General Terms of Service, all references to “you” or “your” shall mean you, the
                                        individual or organization accessing this Site in your capacity as an Employer.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        1. Employer Accounts
                                    </div>
                                    <div className='mb-10'>
                                        When you create an Forstec account, a Company Page or post job listings advertising
                                        employment opportunities and other job-related contents, including links to third-party
                                        websites (“Job Listings” or “Job Ads”) on the Site, whether as part of the Forstec Ads
                                        Program or otherwise, you agree that this Agreement, the terms associated with any
                                        Forstec service you are using, and all of Forstec’s policies, including the Forstec Privacy
                                        Policy and Cookie Policy, apply to you. When you create an Forstec account, Forstec
                                        may require that you verify your identity through a third party service called ID.me. By
                                        using ID.me, you are submitting your identification documents for verification through
                                        Forstec to ID.me, you are agreeing to share your identification and identity
                                        with ID.me, and you agree that ID.me is solely responsible for any collection, use, or
                                        loss of data you provide, including the capture or storage of any biometric identification
                                        or biometric identifiers. Forstec does not collect any biometric information or biometric
                                        identifiers, or receive any biometric information or biometric identifiers from ID.me.
                                        As an Employer, your account is for business use and not for personal use. Forstec is
                                        not responsible for and disclaims all liability if your email is used improperly or falsely by
                                        a third party. By registering for an Forstec account, you agree to receive mandatory
                                        email updates regarding anomalous activity to your Forstec account. If you attempt to
                                        send an email from a name or email address that is not true, accurate, current or
                                        complete, we reserve the right to drop such email, and attempting to send such email is
                                        a violation of our terms.
                                        In some instances, multiple users may be linked to the same account (“a Linked
                                        Account”). A Linked Account is created when the primary account owner(s) (“Admin(s)”)
                                        of an Employer account invites other users to the same account. Admin(s) can provide
                                        these other users varying levels of access and functionality (“Roles”) within the account,
                                        as described on the site, including but not limited to accessing account data or
                                        purchasing services from Forstec under such account. If you are an Admin adding a
                                        user to a Role or several Roles, you represent to Forstec that you are an authorized
                                        representative of this account and that you have the authority to allow this data and
                                        access to be shared. You further agree to indemnify and hold harmless Forstec from
                                        any allegations, claims, actions, suits, demands, damages, liabilities, obligations,
                                        losses, settlements, judgments, costs and expenses (including without limitation
                                        attorneys’ fees and costs) that result from the sharing of this data, access to your
                                        account, and any purchases made under your account. You agree that Forstec
                                        assumes no liability in regards to the accuracy of the statements above and that you are
                                        fully responsible for said accuracy. When using a Linked Account, any users with
                                        access to certain Roles may have access to any or all account information, including but
                                        not limited to: communications and actions of all other users and applicants within the
                                        Linked Account, resume contacts, and billing information, and you consent to such
                                        access. When using a Linked Account, any users with access to certain Roles may also
                                        have the ability to purchase Forstec products under your account, and you agree that
                                        you are responsible for the payment of all such purchases made.
                                        Change of Address: If you have provided a billing address to us, and you change such
                                        billing address from an address within the United States to an address outside the
                                        United States, or from an address outside of the United States to an address inside the
                                        United States, you will continue to be bound by this Agreement with the same Forstec
                                        party for the remainder of the calendar month in which you made the change. Beginning
                                        on the first day of the next calendar month, you hereby agree that you will be bound by
                                        this Agreement with the applicable Forstec party identified in the first paragraph of this
                                        Agreement with respect to such new territory.
                                        If your Forstec employer account has a credit card on file to pay for one Forstec product
                                        or service, Forstec may charge that card for any additional products or services you
                                        order.
                                        You agree that Forstec may send notices to Job Seekers informing them that an
                                        Employer account has been compromised, including if such account is associated with
                                        you. Forstec cannot and does not guarantee that such notices will always be sent or
                                        received, and you therefore agree that Forstec bears no responsibility for doing so.
                                        Forstec makes no warranty regarding, and disclaims any liability for, the accuracy,
                                        completeness, timeliness, or reliability of such notices.
                                        Forstec may offer suggestions, recommendations, or information to users that may
                                        support or improve their experience on Forstec Sites. You understand that these
                                        offerings are provided as a courtesy and without warranty, and your use of such
                                        information is at your sole discretion. As an Employer, you are responsible for your use
                                        of the Site and any tools offered therein, including your decisions regarding your job
                                        description, the requirements for your job, and whom you interview or hire. Forstec
                                        assumes no responsibility and disclaims all liability for any actions you take based on
                                        any information provided by Forstec.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        2. Employer Services Including ForstecApply and Screener Questions
                                    </div>
                                    <div className='mb-10'>
                                        You also agree that, as a service to Job Seekers, Forstec may activate its Forstec Apply
                                        relay function for use in connection with your Job Listings, and that any Job Seekers
                                        who wish to indicate an interest in such Job Listing may do so through Forstec Apply,
                                        and that Forstec will send applications to the email address you provide us with. You
                                        also agree that Forstec may activate a chat bot, which enables Job Seekers to apply to
                                        your Job Ad by answering questions. The answers a Job Seeker submits are presented
                                        to you as a job application. When you use Forstec candidate management tools,
                                        including but not limited to your Employer Dashboard, or by activating the Forstec Apply
                                        relay function for your Job Listings, you acknowledge and agree that Forstec may make
                                        available functions allowing you to take actions regarding the Job Seeker, such as tools
                                        for setting up an interview, viewing a resume and rejecting a candidate. You agree that
                                        by using such candidate management tools, you are instructing Forstec to assemble the
                                        candidate’s application materials, resume, answers to screener questions, assessment
                                        responses and other information you provide to Forstec into one document. You further
                                        agree that Forstec is not responsible for maintaining or storing such application
                                        materials, and that you are responsible for your own compliance with any applicable
                                        record retention requirements. By using these tools, you are providing information to
                                        Forstec and requesting and authorizing us to make available such information to the
                                        applicable Job Seeker. For example, when you choose whether a qualification is
                                        preferred or required (including marking it as a deal breaker), this information may be
                                        highlighted to Job Seekers. Additionally, you consent to any information shared through
                                        Forstec being processed and analyzed by Forstec according to this Agreement and
                                        Forstec’s Privacy Policy. Forstec may store such information regardless of whether a
                                        job vacancy has been filled.
                                        You are responsible for the contents of your emails, application form, screener
                                        questions or their format, criteria you set for inviting candidates to interview, Company
                                        Pages that you create, update, or manage any Job Listings that you post, and any
                                        messages that you send through Forstec Apply or otherwise, and agree that Forstec is
                                        not responsible for such content and disclaims all liability for such content, including as
                                        to whether such content is legal. You agree that Forstec may reject or remove any Job
                                        Listing, any part of any Company Page, or any questions for Job Seekers for any or no
                                        reason. Forstec further does not guarantee delivery, your receipt of the Job Seeker’s
                                        emails or application materials, or that there will be no mistakes in the transmission or
                                        storage of the data. You are solely responsible for checking your Employer dashboard
                                        to view job applications and other information. Any notifications, such as emails, you
                                        may receive about applications or other activities, are provided solely as a courtesy to
                                        you and you should not rely on them.
                                        In the event a message being sent is intended for a closed account, these messages
                                        will not be deliverable. If you do not feel comfortable sending a message, including but
                                        not limited to an offer letter, to a Job Seeker through Forstec’s relay functions, do not
                                        use the Forstec relay functions and please contact the Job Seeker via the personal
                                        information provided in such Job Seeker’s resume or application or by whatever method
                                        you so choose. When using the Forstec Apply function, Forstec will attempt to send
                                        applications to the contact information provided to Forstec by you, however, Forstec has
                                        no ability to verify the contact information provided by you. In the event you provide
                                        incorrect contact information, it shall be your responsibility to correct, appropriately
                                        respond, or take any steps necessary to protect the privacy of such Job Seekers, and
                                        you indemnify Forstec for any damages resulting therefrom.
                                        When you use the Forstec Apply relay function for your Job Listings, you acknowledge
                                        and agree that Forstec may add functions into the corresponding Forstec Apply emails.
                                        For example, Forstec may mask or hide contact information, such as email addresses,
                                        from resumes or applications and may substitute it with an alias. In addition, Forstec
                                        may, on your behalf, send out reminder emails to Job Seekers you wish to interview.
                                        Forstec may also send emails to Job Seekers on your behalf indicating that your Job
                                        Listing is potentially a match for the Job Seeker’s resume. When you use screener
                                        questions, you may be given the option to instruct Forstec to send out rejection notices
                                        if the Job Seeker has not answered the questions in the manner set forth in the online
                                        instruction. If you choose such option, you agree you are instructing us to send out
                                        rejection notices to such candidates and set them to rejected in your candidate
                                        dashboard. You further acknowledge that Forstec has no discretion in the transmission
                                        or storage of these or all other rejection notifications (which is purely mechanical), that
                                        transmission or storage is not guaranteed, and that the Job Seeker may not have
                                        answered the screener questions accurately. When you use Forstec’s candidate
                                        management tools, you may be given the option to send automatic rejection notices to
                                        candidates whose status you set to “rejected”. If you choose such option, you
                                        acknowledge that Forstec has no discretion in the transmission or storage of rejection
                                        notices.
                                        Similarly, you may be given the option to use candidate management tools that
                                        schedule interviews on your behalf with Job Seekers who meet criteria you have
                                        selected. If you choose such option, you agree you are instructing Forstec to schedule
                                        interviews and invite candidates to interviews on your behalf via email based on
                                        whether information the Job Seeker has provided to Forstec (e.g., answers to screener
                                        questions, resume, Forstec Assessment Responses) matches criteria you have set.
                                        You further agree that Forstec has no discretion in the transmission or storage of these
                                        interview invitations (which are purely mechanical), that transmission or storage is not
                                        guaranteed, and that the information the Job Seeker provided to Forstec may not be
                                        accurate. Forstec may request that Job Seekers confirm their interest in the position
                                        before scheduling an interview. It is ultimately the Employer’s responsibility to confirm
                                        whether any interviews were scheduled, or to reschedule or cancel interviews if
                                        necessary. The decision of who to interview is decided solely by the Employer, who can
                                        choose to interview any Job Seeker at any time, and by scheduling such interviews,
                                        Forstec does not warrant that a Job Seeker is qualified, meets the criteria the Employer
                                        has set, or that the information provided by the Job Seeker to Forstec is accurate. You
                                        understand that Forstec does not act as an employment agency by offering this or other
                                        candidate management tools.
                                        You agree that Forstec may take action to try to identify and reduce spam applications,
                                        and that one method of doing so may include imposing a cover letter requirement for
                                        such applicants. Forstec does not verify the identity of any Job Seekers who apply to
                                        your job listing, nor does Forstec know a Job Seeker’s motivation for applying to your
                                        job listing, and thus provides no guarantee as to the Job Seeker’s qualifications or
                                        interest in your job listing. You agree that Forstec Apply and Forstec’s relay functions
                                        are presented to you without warranty and Forstec assumes no responsibility for the
                                        communications between you and the Job Seeker, which communications are yours’
                                        and the Job Seekers’ sole responsibility.
                                        By using any automated phone screen product, you agree that you are requesting
                                        Forstec to send a Job Seeker a telephone number, which the Job Seeker may call with
                                        the purpose of answering your telephone screening questions. You agree these
                                        questions are part of your application process, are solely determined by you, and are
                                        not being asked by Forstec. You also agree that you are only asking Forstec to record
                                        the Job Seeker’s answers to your screening questions and that Forstec will forward you
                                        the recording of the answers to your questions. You consent to Forstec listening to and
                                        analyzing the recording in accordance with Forstec’s Privacy Policy. Forstec disclaims
                                        all warranties with regards to the transmission or storage of such phone screens and
                                        responses, does not guarantee their delivery or receipt, and does not guarantee the
                                        date or time at which they may be sent.
                                        You shall indemnify, defend and hold harmless Forstec, its agents, affiliates, and
                                        licensors from any third-party claim or liability (including without limitation reasonable
                                        legal fees) arising out of any Company Page created or claimed by you, or any Job
                                        Listing or screener questions (or answers thereto) posted by you, or any message sent
                                        by you (including any questions for Job Seekers contained in any of the foregoing).
                                        Forstec may make Job Ad analytics data available on a Company Page or elsewhere
                                        on the Site, and may provide analytics data regarding your Employer account to anyone
                                        at your company at Forstec’s discretion. To the extent permitted by law, if you have an
                                        unpaid or outstanding invoice or account balance for any Forstec product, Forstec
                                        reserves the right to suspend or terminate your use of that Forstec product as well as
                                        any other Forstec product, including but not limited to those Forstec products where you
                                        do not have an unpaid invoice or account balance. Forstec may elect to apply (i)
                                        penalties for late payment as per contemporaneous US Federal Reserve interest rates
                                        plus 10% or the maximum interest permitted by law, whichever is lower and (ii) a
                                        statutory lump-sum indemnity for recovery cost, if provided for under applicable laws,
                                        and (iii) any reasonable expenses and attorney fees Forstec incurs collecting such late
                                        payments.
                                        When you view, send, store or receive materials (including Job Listings, resumes, and
                                        messages) through or using the Site, Forstec may, for example, use such materials for
                                        data analysis, quality control, or to refine the Site or any other Forstec product or
                                        service (including to provide better search results and other listings for Job Seekers and
                                        Employers), whether via automated means or otherwise. When you view, send, store or
                                        receive materials through or using the Site or when you simply use or visit the Site,
                                        Forstec may inform a Job Seeker that you have taken an action, for example, with
                                        regards to a Job Ad, Job Seeker’s Resume, or application such as pausing or closing a
                                        Job Ad, opening the Resume or application, viewing the Resume or application,
                                        responding to the Resume or application, and making a decision with regards to the
                                        application or Job Ad, and you hereby consent to Forstec taking such actions.
                                        Forstec, in its sole discretion, may add labels or badges to Employer names or Job Ads,
                                        such as, “Responsive Employer” or “Active Employer” or “Hired on Forstec”. Employers
                                        may also request that Forstec add such labels. Forstec shall determine the method by
                                        which such labels or badges are determined or which Employers qualify. The lack of a
                                        label or badge may indicate that Forstec does not have sufficient data to determine if an
                                        Employer qualifies. Some of the data may be provided by the Employer and
                                        Forstec does not guarantee the accuracy of such data. Forstec does not guarantee
                                        the accuracy of any label or badge that is added to Employer names or Job Ads based
                                        on employer provided data, including data on Job Ads.
                                        If you access or use any Forstec Application Programming Interface (API), including
                                        accessing and using the Site or any of the Forstec Apps or any Applicant Tracking
                                        System (ATS) through an API, you agree to be bound by this Agreement, the Forstec
                                        API Terms, Forstec Privacy Policy, the Site Rules, and any additional rules and policies
                                        made available by Forstec. YOU UNDERSTAND AND AGREE THAT YOU ACCESS
                                        AND USE ANY API AT YOUR OWN DISCRETION AND RISK AND THAT FORSTEC
                                        DISCLAIMS ALL LIABILITY ARISING OUT OF YOUR USE OF ANY FORSTEC API.
                                        <strong>
                                            . It
                                            is your responsibility to regularly review any Forstec App or the Site for the most
                                            up-to-date information (including, but not limited to, explanations about how
                                            features work, disclaimers and disclosures regarding the services provided,
                                            method for charging) about Forstec’s products and services. By using an API,
                                            rather than directly using the Site or an Forstec App, you agree to all information
                                            provided on the Site or an Forstec App. Use of an Application or ATS via an API,
                                            rather than direct use of the Site or an Forstec App, shall not excuse any lack of
                                            information or understanding about Forstec’s products and services where that
                                            information is otherwise provided on the Site or an Forstec App.
                                        </strong>
                                        You acknowledge that as part of its efforts to combat fraud and spam, Forstec may
                                        require that users verify their email address, including Job Seekers applying to your job.
                                        Email verification is not guaranteed. You are responsible for your use of any software
                                        that rejects applications from unverified email addresses
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        3. Job Match
                                    </div>
                                    <div className='mb-10'>
                                        Forstec may use Job Seekers’ application materials (including resumes and responses
                                        to screener questions) and the recency of their activity on Forstec to determine whether
                                        the words of their resume and answers to screener questions match the words of your
                                        Job Listing or Resume query, and vice-versa. You further agree and consent that
                                        Forstec may differentiate those matching resumes and screener questions from those
                                        that do not match, and present them to you as matches or not matches. Forstec may
                                        also use such information in order to improve the Site or any other Forstec product or
                                        service (including by displaying or otherwise making available potentially relevant Job
                                        Listings and resumes to Job Seekers and Employers).
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        4. Salary, Applies or Other Information Provided by Forstec
                                    </div>
                                    <div className='mb-10'>
                                        Forstec may provide some information and content to users for informational purposes
                                        only. For example, Forstec may provide Job Seekers with data regarding estimated
                                        salaries for a given Job Listing, number of applies to a Job Listing, responses to certain
                                        screener questions for a Job listing, or provide you with estimated applies to your Job
                                        Listing. All such figures provided by Forstec are estimates given for informational
                                        purposes only, may come from a third party, and they are subject to change or varying
                                        levels of accuracy. If you are participating in the Forstec Ads Program and your
                                        Sponsored Job advertising budget is set on a per-apply basis, you will be charged
                                        based on Forstec’s determination of Apply count as reflected in your employer
                                        dashboard and not based on the number of applies that may be provided to Job
                                        Seekers for informational purposes only. Forstec may also include salary estimations on
                                        pages other than Job Listings on the Site. Please note that all salary figures are
                                        approximations based upon multiple third party submissions to Forstec, including from
                                        Forstec affiliates. These figures are given to Forstec users for the purpose of
                                        generalized comparison only. Minimum wage may differ by jurisdiction and you should
                                        consult the employer for actual salary figures. Forstec may also provide data regarding
                                        impressions in relation to your job ad. Such figures are provided for informational
                                        purposes only, are subject to change at any time, and Forstec does not guarantee their
                                        accuracy. Forstec reserves the right to change the method of measuring such figures at
                                        any time.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        5. Screening Tools
                                    </div>
                                    <div className='mb-10'>
                                        Forstec may make available to you screening tools for your use during the application
                                        process, including screener questions, phone screen tools and assessments. Forstec is
                                        licensing these tools to you for your use as you determine. By using any screening
                                        product, made available to you by Forstec, you agree that you have made the
                                        determination to use these tools as part of your application process, and the substantive
                                        questions you ask or choose are solely determined by you, and are not being asked by
                                        Forstec. You are the sole party to determine which answers will qualify a candidate. You
                                        are solely responsible for the use of the screening tool including any results which are
                                        considered to have a “disparate impact”.
                                        <strong>
                                            You further acknowledge that you are
                                            responsible for offering alternative methods of screening, if so required by the
                                            Americans with Disabilities Act or any other equivalent or similar law.
                                        </strong>
                                        Forstec does not warrant that the method of delivery of these questions is compliant
                                        with the Americans with Disabilities Act or any equivalent or similar law. You agree to
                                        indemnify Forstec for any and all claims arising out of your use of a Screening Tool,
                                        including any claims that any screening tool does not comply with the Americans with
                                        Disabilities Act or similar or equivalent law, or that such Screening Tool results in a
                                        “disparate impact”.
                                        Forstec may offer Employers the ability to activate a feature on their account called
                                        Employer Assist. Activating Employer Assist means the Employer is instructing Forstec
                                        to send a message on the Employer’s behalf to the Job Seeker informing them that the
                                        Employer has determined not to move forward with their application. To prevent a
                                        rejection notice from being sent, you must indicate your interest in the application on
                                        Forstec. Any interactions you have directly with a Job Seeker and not through a tool
                                        provided by Forstec (for example, calling or emailing a Job Seeker directly instead of
                                        through an Forstec Relay Service) are not visible to Forstec, and will not prevent an
                                        Employer Assist rejection notice from being sent. If you activate Employer Assist then
                                        you must interact with a Job Seeker through a tool provided by Forstec to prevent an
                                        Employer Assist rejection notice from being sent. If you activate Employer Assist you
                                        agree that you are instructing Forstec to send these messages to candidates with whom
                                        you do not interact within your chosen time frame, and you agree to indemnify Forstec
                                        from any claims arising therefrom. You further agree that Forstec may notify Job
                                        Seekers about the estimated time frame during which they may expect to hear back
                                        from you based on the time frame you choose in Employer Assist.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        6. Communication through Forstec
                                    </div>
                                    <div className='mb-10'>
                                        You may receive messages, emails or email notifications corresponding with your or a
                                        Job Seeker’s activity on or use of the Site, Forstec Apply, Forstec Chat, or any other
                                        communications service, product, or feature provided on or through the Site. In all
                                        cases, such messages or notifications are provided solely as a courtesy, and you
                                        should not rely on them. For example, if you wish to interview a Job Seeker, it is your
                                        responsibility to follow up with the Job Seeker separately to ensure they know about the
                                        interview, do not rely on notifications through Forstec. Forstec disclaims all warranties
                                        with regards to the transmission or storage of such courtesy notices, does not
                                        guarantee their delivery or receipt, and does not guarantee the date or time at which
                                        they may be sent. In the event a message being sent is intended for a closed account,
                                        these messages will not be deliverable.
                                        Forstec may use your email address to create an alias email address for your
                                        communication, in lieu of displaying your actual email address to the Job Seeker. Once
                                        a Job Seeker has your contact info, you agree that Forstec is not responsible or liable
                                        for the manner in which the Employer uses the info. If you send an email to multiple
                                        recipients (e.g. by adding additional aliased email addresses to the cc field in a third
                                        party email client), this may affect the way messages on Forstec are displayed. In this
                                        case, it is best to view the email conversation in the third party email client. Should you
                                        choose to send an email from a third party email client, the person with whom you’re
                                        communicating may be able to see your email address, rather than the alias. If you
                                        send or receive communication on or through the Site, you agree to your
                                        communication being processed, analyzed, reviewed, and stored, including via
                                        automated means, for data analysis, quality control, enforcement of the Sites’ rules and
                                        other Forstec policies, content moderation, and to improve the Site or any other Forstec
                                        product or service.
                                        Specifically, if you post a Job Listing directly on Forstec, applications are sent only to
                                        your Forstec dashboard; any other notifications you may receive are provided solely as
                                        a courtesy to you. For example, you may not receive application email notifications if a
                                        Job Seeker has not answered screener questions correctly, although these applications
                                        will be sent to your Forstec dashboard. Forstec reserves the right to turn on or enable
                                        Forstec Chat or other communication options for select Employers or Job Listings, in its
                                        sole discretion, and to notify Job Seekers that Forstec Chat or other communication
                                        options are available for a particular job or Employer. For Forstec Chat, you may access
                                        or view your messages by visiting your Forstec Chat messages dashboard. If you do
                                        not wish to use Forstec Chat, you may turn it off by visiting your Forstec Chat
                                        dashboard. Forstec may, in its sole discretion, turn off or disable Forstec Chat for any
                                        Employer or Job Seeker at any time without prior notice.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        7. Virtual Communications
                                    </div>
                                    <div className='mb-10'>
                                        Forstec may offer you the option to manage virtual and remote communications with
                                        Job Seekers within Forstec products, including but not limited to, Forstec Interview,
                                        Forstec Hiring Platform, phone interviews, virtual meetings, and video interviews
                                        (“Virtual Interviews”). Services may include giving you access to scheduling, video
                                        conferencing, web conferencing, meeting rooms, and other collaborative services
                                        offered by third-party telecommunications service providers. You understand that
                                        Forstec is not a telecommunications service provider. Forstec disclaims all warranties
                                        with regards to the transmission of virtual communications. Forstec does not guarantee
                                        (1) availability of such services at the time You attempt to initiate them (2) the quality of
                                        such services, or (3) the dates or times you’ve arranged with Job Seekers for your
                                        Virtual Interview. Further, Forstec does not verify the identities or qualifications of Job
                                        Seekers with whom you arrange Virtual Interviews.
                                        YOU UNDERSTAND AND AGREE THAT THERE IS NO PROTECTION FOR ANY
                                        INFORMATION YOU SHARE OR DATA THAT YOU TRANSMIT WHILE
                                        PARTICIPATING IN VIRTUAL INTERVIEWS INCLUDING, BUT NOT LIMITED TO,
                                        AUDIO/VISUAL CONTENT, INTERVIEW QUESTIONS AND ANSWERS, OR YOUR
                                        IMAGE OR LIKENESS. YOU ACKNOWLEDGE AND AGREE THAT FORSTEC IS NOT
                                        RESPONSIBLE FOR SECURING OR PROTECTING ANY DATA OR INFORMATION
                                        THAT YOU SHARE OR TRANSMIT DURING YOUR USE OF VIRTUAL INTERVIEWS.
                                        FORSTEC ASSUMES NO LIABILITY FOR THE MISUSE OF ANY DATA YOU SHARE
                                        OR TRANSMIT THROUGH USE OF VIRTUAL INTERVIEWS. YOU FURTHER
                                        ACKNOWLEDGE AND AGREE THAT FORSTEC DOES NOT ASSUME ANY
                                        RESPONSIBILITY FOR AVAILABILITY OR RETENTION OF ANY RECORDINGS OF
                                        VIRTUAL INTERVIEWS.
                                        YOU UNDERSTAND AND AGREE THAT YOU ACCESS AND USE VIRTUAL
                                        INTERVIEWS AT YOUR OWN DISCRETION AND RISK AND THAT FORSTEC
                                        DISCLAIMS ALL LIABILITY ARISING OUT OF YOUR USE OF VIRTUAL
                                        INTERVIEWS.
                                        Upon your request, Forstec may extend invitations to additional representatives at your
                                        company to join in Virtual Interviews. You understand and agree that Forstec will extend
                                        such invitations on your behalf based on the email addresses you provide for those
                                        representatives but Forstec is not responsible for inaccuracies in the contact information
                                        you provide. Further, Forstec is not responsible, and you are solely responsible for the
                                        conduct or actions of your representatives. You represent that you have the authority to
                                        bind your additional representatives to Forstec’s Terms, and by extending invitations to
                                        your representatives, you are binding them to Forstec’s Terms.
                                        You understand that Forstec is only providing the option for you to communicate with
                                        Job Seekers via services that may be offered by third-party providers. Forstec is not a
                                        party to your Virtual Interviews and is not a participant in any arrangements you make
                                        with Job Seekers. You are responsible for any requests for accommodations from Job
                                        Seekers. For example, if a sign language interpreter is requested, it is your
                                        responsibility to provide one if you are legally obligated to.
                                        Recording Virtual Interviews: As an Employer, you may be presented with an option to
                                        enable recording of Virtual Interviews. You acknowledge and agree that you will not
                                        record, store, or analyze Virtual Interviews without permission from Job Seekers and all
                                        participants. By recording a Virtual Interview, you represent and warrant to Forstec that
                                        you have secured all necessary consent and will comply with all applicable laws,
                                        including state and federal. By choosing to record Virtual Interviews, you agree that
                                        third-party providers and Forstec can store and access the recording. However, you
                                        agree that Forstec is not obligated to store or retain any recording of a Virtual Interview.
                                        YOU FURTHER AGREE TO DEFEND, INDEMNIFY AND HOLD FORSTEC
                                        HARMLESS FROM ANY ALLEGATIONS, CLAIMS, ACTIONS, SUITS, DEMANDS,
                                        DAMAGES, LIABILITIES, OBLIGATIONS, LOSSES, SETTLEMENTS, JUDGMENTS,
                                        COSTS AND EXPENSES (INCLUDING WITHOUT LIMITATION ATTORNEYS’ FEES
                                        AND COSTS) THAT ARISE FROM THE RECORDING OF VIRTUAL INTERVIEWS.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        8. Company Pages
                                    </div>
                                    <div className='mb-10'>
                                        When you post a Job Ad directly on Forstec or submit a request for a landing page on
                                        Forstec, such as Forstec Next, Forstec may automatically generate for you a Company
                                        Page. You understand that Forstec may display publicly available information about
                                        your company on the Company Page, in accordance with applicable law. You authorize
                                        Forstec to claim such Company Page for you on your behalf, which will be marked as a
                                        “Claimed Profile” and allows you to edit and add information to the Company Page.
                                        Company Pages allow User Content (as defined below) to be posted by individuals that
                                        may not be affiliated with the Company that owns the Company Page, including but not
                                        limited to the answers to Q&A questions on Company Pages. Additionally, Forstec may
                                        include all of your Job Ads posted on the Site on your Company Page, including but not
                                        limited to those posted directly on Forstec as well as those indexed by Forstec. You
                                        agree that you are solely responsible for any content you edit or put on a Company
                                        Page, whether generated by or for you, including but not limited to photograph(s) or
                                        videos you provide, and third-party websites reachable from content you put on a
                                        Company Page.
                                        Forstec may additionally offer Forstec Company Pages Premium, which includes
                                        features accessible only on a subscription basis and may be charged as indicated in an
                                        insertion order. Forstec reserves the right to change or remove these features at any
                                        time, in Forstec’s sole discretion. Additionally, these features may include analytics or
                                        other figures, which Forstec does not guarantee the accuracy of, and you are
                                        responsible for determining their suitability for your intended use or purpose. You may
                                        cancel your participation in Forstec Premium Company Pages Program at any time by
                                        notifying Forstec. Such cancellation is generally effective at the beginning of the next
                                        calendar month; if you have prepaid for an annual subscription then cancellation is
                                        effective immediately and Forstec will provide a prorated refund of the unused
                                        subscription term, which may include any discounts as applicable. All Company Pages
                                        Premium monthly subscriptions will automatically renew until cancelled by you. Annual
                                        subscriptions will need to be renewed each year. You are responsible for all taxes as
                                        applicable and appropriate.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        9. ForstecAcademy
                                    </div>
                                    <div className='mb-10'>
                                        If you or anyone at your company is a member of Forstec Academy, your use of the
                                        Graduate logos is limited to your individual use only, and solely for purposes of
                                        indicating your Graduate status. Use of the Graduate logo to represent organizations or
                                        for commercial purposes is not permitted. Further, you agree and acknowledge that you
                                        will not make any representation that you are recruiting on behalf of Forstec, working for
                                        Forstec, or in any form of co-employment or contractor relationship with Forstec.
                                        Additionally, nothing contained herein shall be construed to imply a joint venture,
                                        partnership, principal-agent relationship or employer-employee relationship between
                                        you and Forstec, and neither Party shall have the right, power or authority to obligate or
                                        bind the other in any manner whatsoever, except as otherwise agreed to in writing. If
                                        you make any representation to the contrary, Forstec may remove you from Forstec
                                        Academy in its sole discretion
                                    </div>
                                    <div className='mb-2 fs-6 f-bolder'>
                                        10. HR Management Tools
                                    </div>
                                    <div className='mb-10'>
                                        Forstec may make HR management software tools available to employers and their
                                        employees (“HR Management Tools”). If you are an employee using any HR
                                        Management Tools, you agree that such use is subject to the provisions of this
                                        Agreement. You further acknowledge that while Forstec is only providing the HR
                                        Management Tools for your and your employer’s use, and that Forstec accepts no
                                        responsibility for such use. Forstec does not provide legal advice and cannot guarantee
                                        that your employer will configure or use any HR Management Tool to be in compliance
                                        with the law. Your employer retains all responsibility for such compliance. If you have
                                        questions or concerns about the legality or appropriateness of any configuration of an
                                        HR Management Tool, you must raise them directly with your employer.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        11. Class Action Waiver
                                    </div>
                                    <div className='mb-10'>
                                        By using the Site and in return for the services offered by Forstec, you acknowledge
                                        that Forstec can only offer you these services under the terms and conditions as
                                        presented herein. As partial consideration for your use of the Site and these services,
                                        you agree not to sue Forstec as a class plaintiff or class representative, join as a class
                                        member, or participate as an adverse party in any way in a class-action lawsuit against
                                        Forstec regarding your use of the Site. If you do not agree to any part of these terms, do
                                        not continue your use of the Site. Nothing in this paragraph, however, limits your rights
                                        to bring a lawsuit as an individual plaintiff, including in small claims court, subject to
                                        Section 11 above.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        12. Indemnification
                                    </div>
                                    <div className='mb-10'>
                                        You shall indemnify, defend and hold harmless Forstec, its agents, affiliates, and
                                        licensors from any claim or liability (including without limitation reasonable legal fees)
                                        arising out of or related to: (a) your violation of any term of this Agreement; (b) your use
                                        of, and access to, the Site or any Forstec product, program, or service; (c) your loss of,
                                        or disclosure of, information gained from using the Site or any Forstec product,
                                        program, or service; (d) your violation of any applicable laws or regulations, including
                                        but not limited to the Fair Credit Reporting Act, any applicable employment, equality, or
                                        discrimination laws, and any applicable data protection or privacy laws; (e) your
                                        violation of any third party right, including without limitation any copyright, property, or
                                        privacy right; (f) any claim that your content caused damage to a third party; (g) your
                                        actions as an Employer, including without limitation doing or not doing the following:
                                        screening, hiring, promoting, or demoting any employee or Job Seeker; or (h) the
                                        activities of any third-party service providers you engage to assist you with activities
                                        related to your use of Forstec products and services, such as, but not limited to:
                                        facilitate your sourcing activities or track the conversion rates of job seeker through to
                                        job applicant. This defense and indemnification obligation will survive this Agreement
                                        and your use of the Site or any Forstec product, program, or service.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        13. Extended Campaign Tools
                                    </div>
                                    <div className='mb-10'>
                                        Forstec may offer a service to facilitate the coordination of Job Ads within
                                        geographically distributed businesses, such as franchises or large companies with
                                        many locations, such as Forstec Central or Groups (“Extended Campaign Tools”). You
                                        acknowledge and agree that using Extended Campaign Tools involves sharing a
                                        franchisee’s account information with the franchisor, or sharing a local office’s account
                                        information with the corporate headquarters, as applicable. This information may
                                        include items such as the number of your business locations or accounts, information
                                        about your job ads (content, clicks, impressions, etc.), your contact information, and
                                        your spend levels. The franchisor or corporate headquarters may request that Forstec
                                        contact its franchisees or local offices, respectively, by providing Forstec with contact
                                        information, and may provide the content of Job Ads to Forstec for use in connection
                                        with Extended Campaign Tools. By using Extended Campaign Tools, you agree to this
                                        information being shared, and you represent and warrant that i) all information you
                                        provide to Forstec is accurate, and ii) you have all necessary right and authorization to
                                        provide such information to Forstec and to permit Forstec to share it in connection with
                                        Extended Campaign Tools. You may request that Forstec grant access and control of
                                        your Extended Campaign Tools account to additional users. You understand and agree
                                        that by submitting such requests, Forstec will contact the users based on the
                                        information you provide (name, email address, and job title) and that Forstec is not
                                        responsible for verifying the users prior to completion of such requests.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        14. Candidate Import
                                    </div>
                                    <div className='mb-10'>
                                        Forstec may offer a platform that allows you, or someone acting on your behalf or
                                        authority (such as a recruitment management service), to upload information about job
                                        applicants to Forstec and to interact with these individuals and direct Forstec to interact
                                        with these individuals on your behalf (hereafter “Candidate Import”). To the extent that
                                        any information you provide or otherwise make available to Forstec through our
                                        Candidate Import service includes Client Personal Data, you instruct Forstec to process
                                        such information on your behalf pursuant to this Agreement and any applicable Data
                                        Processing Addendum; Client Personal Data and Forstec as used in this Section shall
                                        have the meanings given to them in the Data Processing Addendum. By using
                                        Candidate Import, you instruct Forstec to display the information of the interactions
                                        (including Virtual Interviews which are currently scheduled or were previously
                                        conducted) between you or Forstec and the job applicants within their Forstec accounts,
                                        and you represent and warrant that you, and any third party who you have authorised to
                                        upload Client Personal Data to the platform, have obtained all necessary rights and
                                        permissions from, and provided all necessary information and disclosures to, job
                                        applicants as required under applicable data protection law for you to provide or
                                        otherwise make available Client Personal Data to Forstec using Candidate Import, and,
                                        without limiting the foregoing, (a) no individual whose Personal Data you provide or
                                        otherwise make available to Forstec using Candidate Import has exercised any right or
                                        made any request to you that would prevent or otherwise limit your ability to share such
                                        individual’s Personal Data with Forstec for processing in connection with this Agreement
                                        and (b) all job applicants have consented that their data will be shared with Forstec (in
                                        accordance with Forstec’s Terms and Privacy Policy) and that Forstec may contact
                                        them over email as part of your hiring process, including requesting them to create an
                                        account with Forstec. You agree to defend and indemnify Forstec for any breach of the
                                        foregoing sentence. You also agree that Forstec is only responsible for such Client
                                        Personal Data, in accordance with the DPA, once it is received by Forstec and you are
                                        responsible for such Client Personal Data up until such receipt by Forstec.
                                    </div>
                                    <div className='mb-2 fs-4 fw-bolder'>
                                        C. Terms of Service for Publishers
                                    </div>
                                    <div className='mb-10'>
                                        The following Forstec Publisher Program Terms (“IPP Terms”) apply to any Publisher
                                        that accesses, participates, or uses the Forstec Publisher Program (the “Publisher
                                        Program”), or that otherwise indicates its acceptance of these IPP Terms, and set out
                                        the terms and conditions applicable to your participation in the Program. Any references
                                        to “Forstec” shall mean Forstec, Inc. For purposes of this Section C of the Forstec
                                        General Terms of Service, “You” or “Publisher” means any entity identified in an  
                                        enrollment form or application submitted by the same or affiliated persons, and/or any
                                        agency or network acting on its (or their) behalf, which shall also be bound by these IPP
                                        Terms.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        1. ForstecPublisher Program
                                    </div>
                                    <div className='mb-10'>
                                        Forstec shall have absolute discretion as to whether or not it accepts a particular
                                        applicant or site for participation in the Publisher Program or any part thereof.
                                        Publishers are ineligible to participate if their web properties do not conform with the
                                        terms of the Forstec Affiliate Acceptable Use Policy. To participate as a Publisher in the
                                        Publisher Program, all participants must be at least eighteen years of age. Applicants
                                        represent and warrant that all information submitted to Forstec shall be true, accurate
                                        and complete.
                                        Publisher acknowledges that by accessing the Site or participating in the Publisher
                                        Program, Publisher may come into contact with content (including User Content, as
                                        defined below) that it finds harmful, offensive, threatening, indecent or objectionable,
                                        including, but not limited to, explicit language and other potentially offensive material,
                                        and Publisher acknowledges that Forstec shall have no liability with respect to such
                                        content.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        2. Publisher Obligations
                                    </div>
                                    <div className='mb-10'>
                                        Any job search results, JobrollTM, pay-per-click advertisements, and any Forstec logos
                                        or other elements supplied by Forstec for use on Affiliates’ websites or websites cobranded by Forstec and Affiliate (collectively, “Program Elements”) may only appear on
                                        URLs that Forstec has reviewed and accepted for participation (“Accepted URLs”).
                                        Publisher shall advise Forstec of any changes in the type of content accessible at any
                                        such accepted URL. Publisher agrees to comply with the specifications provided by
                                        Forstec from time to time to enable proper delivery, display, tracking and reporting of
                                        Program Elements.
                                        Publisher agrees to abide by all terms of the AAUP. Publisher is not permitted to use
                                        Forstec’s Site or its content other than for non-commercial purposes. Publisher agrees
                                        not to access (or attempt to access) the Site by any means other than through the
                                        interface that is provided by Forstec, unless it has been specifically allowed to do so in a
                                        separate, written agreement with Forstec. Use of any automated system or software,
                                        whether operated by a third party or otherwise, to extract data from the Site (such as
                                        screen scraping, crawling, reproducing, duplicating, copying, selling, trading or reselling)
                                        is prohibited. Publisher agrees that it is solely responsible for (and that Forstec has no
                                        responsibility or liability to it or to any third party for) any breach of Publisher’s
                                        obligations under these IPP Terms and for any consequences (including any loss or
                                        damage which Forstec may suffer) of any such breach.
                                        Publisher agrees that it shall not transmit to Forstec or upload to or through the Site
                                        (whether as User Content or otherwise) any Harmful Code, or use or misappropriate the
                                        Site for its own commercial gain. “Harmful Code” shall mean any software (sometimes
                                        referred to as “viruses,” “worms,” “Trojan horses,” “time bombs,” “time locks,” “drop
                                        dead devices,” “traps,” “access codes,” “cancelbots” or “trap door devices”) that: (a) is
                                        designed to damage, disrupt, disable, harm, impair, interfere with, intercept, expropriate
                                        or otherwise impede in any manner, any data, storage media, program, system,
                                        equipment or communication, based on any event, including for example but not limited
                                        to (i) exceeding a number of copies, (ii) exceeding a number of users, (iii) passage of a
                                        period of time, (iv) advancement to a particular date or other numeral, or (v) use of a
                                        feature; or (b) would enable an unauthorized person to cause such result; or (c) would
                                        enable an unauthorized person to access another person’s information without such
                                        other person’s knowledge and permission. Forstec reserves the right to use a variety of
                                        methods to detect and block the above anomalous activity and to screen User Content
                                        to prevent abuse such as spam or fraud. However, such detection methods are not
                                        perfect and false positives may occur. If Publisher uses the Site, Publisher agrees to
                                        Forstec enforcing the above rules or attempting to prevent spam, fraud, or other
                                        anomalous activity. Such measures may result in a temporary or permanent suspension
                                        or termination of Publisher’s account, of its access to Forstec’s message relay, or of its
                                        use of any other functions of the Site, with or without notice. Forstec shall not be
                                        responsible or liable for any such suspension or termination, including any
                                        consequences thereof. We reserve the right to drop any message, including without
                                        limitation dropping any message with an .ade, .adp, .bat, .chm, .cmd, .com, .cpl, .exe,
                                        .hta, .ins, .isp, .jar, .jse, .lib, .so, .dll, .lnk, .mde, .msc, .msp, .mst, .pif, .scr, .sct, .shb,
                                        .sys, .vb, .vbe, .vbs, .vxd, .wsc, .wsf, .wsh, or .zip attachment or any other attachment
                                        containing scripts, macros, or other code, or other messages that Forstec suspects to
                                        be malicious or spam, or for any or no reason. Forstec further reserves the right to
                                        conduct investigations to determine whether Publisher is perpetrating a scam,
                                        spamming Forstec or its users, or otherwise conducting fraudulent activity on Forstec by
                                        various means, including but not limited to investigating Publisher’s functionality by
                                        setting up profiles and names that belong to Forstec. Forstec reserves the right to turn
                                        over any information gathered via such investigations to the police or other third party if
                                        Forstec has a good faith belief that Publisher is using the Site in violation of these
                                        terms. As Forstec wishes to prohibit automated entities from following any individual
                                        links posted by Publisher, all hyperlinks to Forstec’s site should include the use of
                                        rel=”nofollow”. This applies to editorially placed links, links generated by Forstec’s API,
                                        or other link mechanisms.
                                        Forstec reserves the right to take such action as it considers necessary, including
                                        issuing legal proceedings without further notice, in relation to any unauthorized use of
                                        the Site. If Publisher wishes to make commercial use of the Site, if Publisher wishes to
                                        make use of the Site in any capacity other than that of a Jobseeker or Employer, or if
                                        Publisher wishes to purchase Forstec services that utilize the Site, Publisher must have
                                        a prior written agreement with Forstec to do so, or have accepted Forstec’s online terms
                                        of service.
                                        Forstec reserves the right at all times (but will not have any obligation) to terminate
                                        users, and reclaim usernames or URLs, for any reason. Forstec also reserves the right
                                        in its sole discretion to suspend Publisher’s participation in the Publisher Program if it
                                        suspects any violations of the AAUP. Publisher hereby agrees to defend, indemnify and
                                        hold Forstec harmless from and against any claims, demands, liabilities, expenses,
                                        losses, damages and attorney fees arising from or relating to a violation or purported
                                        violation by Publisher of the AAUP. The foregoing shall be in addition to, and not in lieu
                                        of, any other remedies that Forstec may have as a result of a violation of the AAUP by
                                        Publisher. In addition, under no circumstances may Publisher impersonate or act as
                                        Forstec on any site (which shall include bidding on Forstec’s names or trademarks in
                                        any search engine marketing advertising platform), including, but not limited to, on third
                                        party sites. Any marketing performed by Publisher must comply with all laws of the
                                        jurisdiction in which the marketing is conducted, including but not limited to email, text
                                        messaging and general advertising laws, and Publisher agrees to defend and indemnify
                                        Forstec from any third party action, claim, or demand against Forstec arising from any
                                        violation of those laws.
                                        Forstec will not be treated as the publisher or speaker of any information provided by
                                        Publisher or by another information content provider. Forstec will also not be held liable
                                        on account of any action taken in good faith to restrict access or availability of material
                                        that it or its Users considered to be obscene, lewd, lascivious, filthy, excessively violent,
                                        harassing, or otherwise objection, whether or not such material is constitutionally
                                        protected or on account of any action taken to enable or make available to information
                                        content providers or others the technical means to restrict access to any such material
                                        described above.
                                        Some areas of the Site, including areas that may permit Publisher to set up an Forstec
                                        account, requires Publisher to register or provide an email address. If Publisher so
                                        registers, it agrees to (a) provide true, accurate, current and complete information about
                                        Publisher as prompted by Forstec’s registration form (including Publisher’s email
                                        address) and (b) be responsible for the confidentiality and use of Publisher’s username
                                        and password, and not transfer or resell its use of or access to the Site to any third
                                        party. Please note that any individuals with whom Publisher has shared its username
                                        and password and who are able to answer verification questions about its Forstec
                                        account may receive access to information regarding Publisher’s Forstec account.
                                        Publisher hereby grants Forstec permission to refer to Publisher and its websites in
                                        Forstec’s marketing materials, wherever such marketing materials shall appear.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        3. Termination
                                    </div>
                                    <div className='mb-10'>
                                        Either party may terminate these IPP Terms and participation in the Publisher Program
                                        at any time for any or no reason with or without notice and without liability. If you wish to
                                        terminate agreement to these IPP Terms, you may do so by sending a notice by email
                                        to support-at-Forstec dot com or other email address specified by Forstec in the case of
                                        Forstec. All provisions of this Agreement which by their nature should survive
                                        termination shall survive termination, including without limitation, confidentiality,
                                        warranty disclaimers, indemnifications, and limitations of liability.
                                        Upon termination of a publisher from the Publisher Program, for any reason and by
                                        either party, Publisher shall immediately remove all insertion codes and other Program
                                        Elements from all pages of its websites.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        4. Confidentiality
                                    </div>
                                    <div className='mb-10'>
                                        Publisher shall not disclose Forstec Confidential Information without Forstec’s prior
                                        written consent. “Forstec Confidential Information” includes without limitation: (a) all
                                        Forstec software, technology, programming, specifications, materials, guidelines and
                                        documentation relating to the Publisher Program; (b) click-through rates or other
                                        performance statistics relating to the Publisher Program provided to Publisher by
                                        Forstec; and (c) any other information designated in writing by Forstec as “Confidential”
                                        or an equivalent designation.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        5. Indemnification
                                    </div>
                                    <div className='mb-10'>
                                        Publisher shall indemnify, defend and hold harmless Forstec, its agents, affiliates,
                                        licensors, and Partners from any third party claim or liability (including without limitation
                                        reasonable legal fees) arising out of Publisher’s Program use, Publisher Property, site
                                        or Publisher Services, or breach of these IPP Terms.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        6. Class Action Waiver
                                    </div>
                                    <div className='mb-10'>
                                        By using the Site or any Program Elements and in return for the services offered by
                                        Forstec, Publisher acknowledges that Forstec can only offer Publisher these services
                                        under the terms and conditions as presented herein. As partial consideration for
                                        Publisher’s use of the Site and the Publisher Program, Publisher agrees not to sue
                                        Forstec as a class plaintiff or class representative, join as a class member, or participate
                                        as an adverse party in any way in a class-action lawsuit against Forstec regarding
                                        Publisher’s use of the Site or the Publisher Program. Additionally, as a Publisher, the
                                        use of the Site and the Publisher Program is at no charge and Publisher acknowledges
                                        that part of the price it is paying to use this Site and the Publisher Program is its
                                        acceptance of these terms of service including this Class Action Waiver. If Publisher
                                        does not agree to any part of these terms, Publisher should not continue use of the Site,
                                        the Publisher Program, or any Program Elements. Nothing in this paragraph, however,
                                        limits Publisher’s rights to bring a lawsuit as an individual plaintiff, including in small
                                        claims court, subject to the jurisdiction requirements found in Section 8 above.
                                    </div>
                                    <div className='mb-2 fs-4 fw-bolder'>
                                        D. Terms of Service for All Users
                                    </div>
                                    <div className='mb-10'>
                                        The following terms and conditions apply to all Job Seekers, Employers, Publishers and
                                        other users who access or use the Site, or otherwise indicate their acceptance to this
                                        Agreement. For purposes of this Section D of the Forstec General Terms of Service, all
                                        references to “you” or “your” shall mean you, the individual or organization accessing
                                        this Site in any capacity.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        1. Accessing External Sites
                                    </div>
                                    <div className='mb-10'>
                                        The Site contains links to other sites on the Internet which are owned and/or operated
                                        by third-party vendors and other third parties (the “External Sites”). You acknowledge
                                        that Forstec is not responsible or liable for any such third parties or for the availability of,
                                        or the materials located on or through any External Sites. You should contact the site
                                        administrator or webmaster for the applicable External Site if you have any concerns
                                        regarding such links or the materials located on an External Site.
                                        In addition, Forstec may provide you with links to third party sites that offer you services
                                        for your use or benefit. We may stop offering any such third party sites or services at
                                        any time.
                                        <strong>
                                            If you choose to use such third party sites or services, you enter into an
                                            agreement with such third party alone at your own risk.
                                        </strong>
                                        When entering into an
                                        agreement with such parties, you are giving them your data directly and your rights and
                                        their obligations are determined by their privacy policies and terms of service.
                                        <strong>
                                            We are
                                            not responsible for any content or services provided by these third parties, and
                                            disclaim all liability from anything that may occur when you utilize such third
                                            parties. We are additionally not responsible for any payment that may be asked of
                                            you by such third party. You will be notified on the site that these are third party
                                            sites and services, and not Forstec.
                                        </strong>
                                        For example, Forstec may provide you with access to a link on Forstec to background
                                        check services offered and performed by a third party (each a “Third-Party Background
                                        Check Service”). This service may be offered in the form of permitting a Jobseeker to
                                        request their own background check or permitting an Employer to request a background
                                        check of a Jobseeker. If you choose to use this link and these services, you are
                                        contracting directly with the Third-Party Background Check Service, and Forstec is not a
                                        party to this contract. Please note (and you acknowledge) that Forstec cannot and does
                                        not assemble, access, view, analyze, manipulate, alter, evaluate or store the
                                        background check reports provided by any Third-Party Background Check Service to
                                        you or to any other Forstec user. Forstec operates solely as a technical conduit,
                                        allowing the transmission of background checks sent by a Third-Party Background
                                        Check Service to you. Further, you agree to fully indemnify and defend Forstec for any
                                        claims arising out of your use of a Third-Party Background Check Service’s services or
                                        a background check provided to you by a Third-Party Background Check Service.
                                        Pages on the Site may embed content and information provided by third-party APIs,
                                        which is subject to additional terms and conditions imposed by those third parties. For
                                        example, the Site may include Google Maps features and content, which is subject to
                                        the current versions of: (1) the Google Maps/Google Earth Additional Terms of Service;
                                        and (2) the Google Privacy Policy. Information on Company Pages includes publicly
                                        available information and/or information provided by the respective company and/or
                                        may come from third parties.
                                        Please note that while this Agreement describes Forstec’s services generally, services
                                        may differ by availability, and you should check the Site in your location to see which
                                        services are available to you.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        2. Using our Site
                                    </div>
                                    <div className='mb-10'>
                                        When you use the Site, you agree to Forstec knowing, processing, analyzing, storing,
                                        and recording any and all activities you take on the Site and any and all interactions and
                                        communications you have with, on, or through the Site, including, but not limited to,
                                        knowing, processing, analyzing, and recording which areas of the Site you visit
                                        (including without limitation URLs), which content you view, which info you input,
                                        whether and where you click, scroll, hover-over, mouseover, or otherwise interact with
                                        or communicate with or through the Site, the timing of each activity, and time spent on
                                        each activity. Forstec collects data from users visiting our Site in private browsing mode,
                                        “incognito mode,” or similar modes. You agree that Forstec may use third-party vendors
                                        to create, store, process, analyze, and record any and all such interactions, activities,
                                        and communications made with, on, or through our Site, and that such vendors’
                                        activities are an extension of the Site. By using the Site you authorize Forstec and such
                                        vendors to make any connections with wires, lines, cables, or instruments, including
                                        with communication systems, that may be made in connection with the Site. The Site
                                        cannot work as Forstec intends it to work unless Forstec undertakes such activities. If
                                        you do not want your actions to be processed, analyzed, or recorded this way, do not
                                        use the Site.
                                        Use of any automated system or software, whether operated by a third party or
                                        otherwise, to extract data from the Site (such as screen scraping or crawling) is
                                        prohibited. Forstec reserves the right to take such action as it considers necessary,
                                        including issuing legal proceedings without further notice, in relation to any unauthorized
                                        use of the Site. If you wish to make commercial use of the Site, if you wish to make use
                                        of the Site in any capacity other than that of a Jobseeker or Employer, or if you wish to
                                        purchase Forstec services that utilize the Site, you must have a prior written agreement
                                        with Forstec to do so, or have accepted Forstec’s online terms of service.
                                        Please contact us for more information. We reserve the right at all times (but will not
                                        have any obligation) to terminate users, and reclaim usernames or URLs, for any
                                        reason.
                                        Occasionally, Forstec may test improvements to our Site and services. Such tests may
                                        affect, but are not limited to, the ad delivery system, audience, ad performance, ad
                                        placement, profiles, resumes, resume search, job search, recommendations, alerts, and
                                        formatting. You agree that Forstec may conduct such tests, which are meant to improve
                                        the effectiveness of our services, without notice to you. You agree to pay all charges
                                        that appear in the applicable Insertion Order or other agreement during such tests.
                                        Forstec may offer suggestions, recommendations, or information to users that may
                                        support or improve their experience on Forstec Sites. You understand that these
                                        offerings are provided as a courtesy and without warranty, and your use of such
                                        information is at your sole discretion. Forstec assumes no responsibility and disclaims
                                        all liability for any actions you take based on any information provided by Forstec.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        3. User Content
                                    </div>
                                    <div className='mb-10'>
                                        Some parts of the Site, such as message boards and forums or job posting pages, may
                                        allow users to post content like Job Listings, resumes, information, text, images, audio,
                                        video, messages, and other materials (any such materials that a user submits, posts,
                                        displays, or otherwise makes available on or through the Site, or any materials that a
                                        user submits to Forstec, including where it is stated that such materials may be posted
                                        on the Site or a third party website, including, but not limited to, mobile apps, are
                                        referred to herein as “User Content”). Such functionality is designed to help users obtain
                                        career and company information, to let users know of jobs, to provide information about
                                        potential job candidates, and facilitate other communication. User Content is provided
                                        by third-party contributors, many of whom may use anonymous screen names. User
                                        Content may be inaccurate, incomplete, misleading or deceptive. Once updated by the
                                        user, it may take a few days for the change to User Content to be reflected. For
                                        example, the Site hosts Company Pages, which allow User Content to be posted by
                                        individuals that may not be affiliated with the Company who owns the Company Page,
                                        including but not limited to the answers to Q&A questions on Company Pages. Forstec
                                        does not endorse and is not responsible for any User Content, including any opinion,
                                        advice, information, or statement contained therein. Some elements of Forstec job
                                        search results pages may appear to be content produced by Forstec, but are actually a
                                        reproduction of user-generated search queries. Job Ads or other User Content may be
                                        reformatted or include words such as “Powered by Forstec”. Such content retains its
                                        status as User Content despite reformatting or addition of “Powered by Forstec” or a
                                        similar phrase. The Site may allow users to post User Content like photos or videos
                                        from third-party sources (such as social media accounts or video sharing platforms).
                                        Removing such content from a third-party source will not affect its display on Forstec,
                                        and to remove such content from Forstec, a user must remove the content directly from
                                        the Site. You acknowledge that by accessing the Site, you may come into contact with
                                        content (including User Content) that you find harmful, offensive, threatening, indecent
                                        or objectionable, including, but not limited to, explicit language and other potentially
                                        offensive material, and you acknowledge that Forstec shall have no liability with respect
                                        to such content.
                                        You must not post any User Content that is unlawful, fraudulent, discriminatory,
                                        threatening, abusive, libelous, defamatory, obscene or otherwise objectionable, or that
                                        contains sexual, ethnic, racial or other discriminating slurs, or that contains no relevant
                                        or constructive content. You must also not post any User Content that contains
                                        proprietary information, trade secrets, confidential information, advertisements (other
                                        than Job Advertisements permitted by this Agreement), solicitations, chain letters,
                                        pyramid schemes, investment opportunities, or other unsolicited commercial
                                        communication (except as otherwise expressly permitted by us in writing), or
                                        encourages or causes spamming or flooding.
                                        You are prohibited from posting any User Content containing official identification
                                        information (whether your own or of another person), such as social security number,
                                        passport number, national identification number, insurance number, driver’s license
                                        number, immigration number, or any other similar number, code, or identifier. Posting
                                        such identification information may lead to identity theft and other adverse
                                        consequences. Forstec may remove any such identification information, but does not
                                        undertake any obligation to do so, and has no responsibility and disclaims all liability for
                                        any posting of such identification information. Forstec reserves the right to change the
                                        display of resume on its Site, including, but not limited to, hiding fields, rearranging its
                                        format, and changing visual elements.
                                        Although Forstec has no obligation to do so, Forstec may monitor User Content, and
                                        reserves the right to delete any User Content or portion thereof that, in Forstec’s sole
                                        discretion, violates the above rules, including any User Content that is unrelated to the
                                        specific portion of the Site on which it is posted, or that is an advertisement, recruiting or
                                        other commercial message (other than Job Advertisements permitted by this
                                        Agreement), or that Forstec deems in its sole discretion to be inappropriate. Forstec
                                        may limit your use of the Site, including but not limited to, your ability to contact other
                                        Employers or Job Seekers. If you believe that any User Content violates this Agreement
                                        or our policies, please contact Forstec immediately so that we may have the opportunity
                                        to consider its removal. You acknowledge and agree that Forstec does not have any
                                        obligation to host or remove any User Content, and i) the interpretation of whether any
                                        User Content violates any Forstec policy, ii) whether to publish or to withdraw from
                                        publication any User Content, and iii) whether to exclude any material that any party
                                        seeks to post on Forstec, will always remain within the sole discretion of Forstec. You
                                        agree that Forstec has no liability to you or anyone else arising from its editorial
                                        decisions. You acknowledge and agree that Forstec is an interactive computer service
                                        and does not have any obligation to screen any User Content, or to include any User
                                        Content (including without limitation Job Ads) in its search results or other listings, and
                                        may exclude or remove any User Content from publication for any or no reason.
                                        Forstec reserves the right to disclose all User Content and other relevant information,
                                        and the circumstances surrounding their transmission, to any third party in connection
                                        with operating the Site; to protect itself, its affiliates, its partners and its visitors; and to
                                        comply with legal obligations or governmental requests. This means, for example, that
                                        Forstec may honor subpoenas, search warrants, law enforcement or court-mandated
                                        requests to reveal a user’s electronic address and identity, or other properly requested
                                        information. When you post on Forstec confidentially or otherwise (e.g. anonymous
                                        Company Reviews or a confidential Job Ad), your identity may be discoverable by legal
                                        request, including, but not limited to, requests from the government or law enforcement.
                                        Without limiting the generality of the foregoing, Forstec reserves the right (but is under
                                        no obligation) to remove any Job Listing that directly or indirectly discriminates against
                                        Job Seekers. Direct discrimination means, for example, that a Job Listing specifically
                                        makes clear that only Job Seekers matching a certain criteria are wanted, thereby
                                        excluding others because of, for example, their gender, race, age or disability. Indirect
                                        discrimination means, for example, that a Job Listing implicitly excludes certain classes
                                        of Job Seekers by making it impossible for them to qualify for the job requirements. You
                                        understand and agree that it is your responsibility to refrain from posting any Job Listing
                                        that directly or indirectly discriminate against Job Seekers or otherwise violate
                                        applicable law
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        4. License to User Content and Feedback
                                    </div>
                                    <div className='mb-10'>
                                        Only to the extent permitted by law, you grant Forstec a nonexclusive, worldwide,
                                        perpetual, (revocable only as described below), fully paid, royalty-free, transferable,
                                        sublicensable (through multiple layers of sub-licensees) right and license to make, use,
                                        sell, sublicense, reproduce, distribute, perform, display, prepare derivative works from
                                        and otherwise exploit all User Content you post or submit, for the purpose of publishing
                                        material on the Forstec website or its publishers or third party sites, maintaining the
                                        Forstec website and promoting Forstec and such User Content without restriction. You
                                        understand that no compensation in any form shall be due or payable to you in
                                        connection with Forstec’s exercise of its rights under the license granted under this
                                        Section. To the extent any compensation may be due under applicable law as a result
                                        of, or in connection with, Forstec’s exercise of these rights, you hereby waive any and
                                        all rights to such compensation.
                                        Furthermore, you grant to Forstec, its affiliates, and sublicensees a license to use your
                                        name, user name, and/or trademarks and logos in connection with (i) any such User
                                        Content, (ii) any Forstec marketing materials containing or incorporating such User
                                        Content, or (iii) any actions by Forstec to promote or publicize such User Content (e.g.
                                        Job Listings), including without limitation the use of keywords in third-party internet
                                        search engines. To any extent that such User Content contains your name, likeness,
                                        voice or image, you hereby irrevocably waive all legal and equitable rights relating to
                                        claims for violation of your rights of publicity (or any similar claims) arising directly or
                                        indirectly from Forstec’s exercise of its rights pursuant to the license granted hereunder.
                                        You represent and warrant that: (i) you own the User Content that you submit, post,
                                        display, or otherwise make available on or through the Site, or that you otherwise have
                                        the right to grant the license set forth in this section, (ii) such User Content, and its
                                        submission, posting, display or availability on or through the Site does not violate
                                        applicable law or the privacy rights, publicity rights, copyrights, contract rights,
                                        intellectual property rights or any other rights of any person or entity, and (iii) such
                                        submission, posting, display or availability on or through the Site does not result in any
                                        breach of contract between you and any third party. You agree to pay for all royalties,
                                        fees, damages, and any other monies owed to any person or entity by reason of such
                                        User Content. You agree to defend, indemnify and hold harmless Forstec and its
                                        affiliates from any claims resulting from any such User Content. If you wish to request to
                                        revoke the license granted by you in this Section for any such User Content, please
                                        send a certified letter of request to the postal address listed above with a copy of your
                                        passport or national identity card (for identity verification purposes) and request removal
                                        of such User Content. Your certified letter of request must include (a) the signature of
                                        the applicable rights holder for such User Content or a person authorized to act on
                                        behalf of the rights holder; (b) identification of the User Content for which the license is
                                        to be revoked, and information reasonably sufficient to allow Forstec to locate and
                                        remove such User Content on the Site; (c) your name, address, telephone number, and
                                        email address; (d) a statement that you have a good faith belief that you are the rights
                                        holder or authorized by the rights holder to revoke the license for the designated User
                                        Content; and (e) a statement that the information in the request is accurate, and under
                                        penalty of perjury, that you are the rights holder or are authorized to act on behalf of the
                                        rights holder with respect to such User Content.
                                        At your discretion, you may provide feedback and related materials to Forstec
                                        concerning the functionality and performance of the Site from time to time, including,
                                        without limitation, identifying potential errors, improvements, modifications, bug fixes, or
                                        enhancements (“Feedback”). If you, through your evaluation or otherwise, provide any
                                        Feedback, you hereby grant to Forstec a nonexclusive, worldwide, perpetual,
                                        irrevocable, royalty-free, sublicensable (through multiple layers of sublicensees) right
                                        and license to make, use, sell, sublicense, reproduce, distribute, perform, display,
                                        prepare derivative works from and otherwise exploit all such Feedback for any purpose
                                        without restriction. You agree that Forstec may disclose any or all Feedback to any third
                                        party in any manner, and you agree that Forstec may sublicense any or all Feedback in
                                        any form to any third party without restriction. By providing any Feedback, you agree
                                        that your provision of such Feedback is gratuitous, unsolicited and without restriction,
                                        and will not place Forstec under any fiduciary, confidentiality or other obligation, and
                                        that we are free to use such Feedback without any additional compensation to you, and
                                        free to disclose such Feedback on a non-confidential basis or otherwise to anyone. You
                                        further acknowledge that, by accepting any Feedback, Forstec does not waive any
                                        rights to use similar or related ideas previously known to Forstec, or developed by its
                                        employees, or obtained from sources other than you.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        5. Rules for Using Our Site
                                    </div>
                                    <div className='mb-10'>
                                        You agree not to access (or attempt to access) the Site by any means other than
                                        through the interface that is provided by Forstec, unless you have been specifically
                                        allowed to do so in a separate, written agreement with Forstec. You agree that you will
                                        not engage in any activity that interferes with or disrupts the Site (or the servers and
                                        networks which are connected to the Site). Unless you have been specifically permitted
                                        to do so in a separate, written agreement with Forstec, you agree that you will not crawl,
                                        scrape, reproduce, duplicate, copy, sell, trade or resell the Site for any purpose. Using
                                        or providing any false, fake, or fictitious name or contact information in connection with
                                        the Site is grounds for immediate termination of your Forstec account and ability to use
                                        the Site. You agree that you are solely responsible for (and that Forstec has no
                                        responsibility or liability to you or to any third party for) any breach of your obligations
                                        under this Agreement and for any consequences (including any loss or damage which
                                        Forstec may suffer) of any such breach.
                                        You agree that you shall not transmit to Forstec or upload to or through the Site
                                        (whether as User Content or otherwise) any Harmful Code, or use or misappropriate the
                                        Site for your own commercial gain. “Harmful Code” shall mean any software (sometimes
                                        referred to as “viruses,” “worms,” “Trojan horses,” “time bombs,” “time locks,” “drop
                                        dead devices,” “traps,” “access codes,” “cancelbots” or “trap door devices”) that: (a) is
                                        designed to damage, disrupt, disable, harm, impair, interfere with, intercept, expropriate
                                        or otherwise impede in any manner, any data, storage media, program, system,
                                        equipment or communication, based on any event, including for example but not limited
                                        to (i) exceeding a number of copies, (ii) exceeding a number of users, (iii) passage of a
                                        period of time, (iv) advancement to a particular date or other numeral, or (v) use of a
                                        feature; or (b) would enable an unauthorized person to cause such result; or (c) would
                                        enable an unauthorized person to access another person’s information without such
                                        other person’s knowledge and permission.
                                        Forstec reserves the right to use any User Content (including the content of messages
                                        or material sent through or to the Site or Forstec) to prevent potentially illegal or tortious
                                        activities, or any other activities that are detrimental to other users. Forstec reserves the
                                        right to use a variety of methods to detect and block the above anomalous activity and
                                        to screen User Content to prevent abuse such as spam or fraud. However, such
                                        detection methods are not perfect and false positives may occur. If you use the Site,
                                        you agree to Forstec enforcing the above rules or attempting to prevent spam, fraud, or
                                        other anomalous activity. Such measures may result in a temporary or permanent
                                        suspension or termination of your account, of your access to Forstec’s message relay,
                                        or of your use of any other functions of the Site, with or without notice. Forstec shall not
                                        be responsible or liable for any such suspension or termination, including any
                                        consequences thereof.
                                        We reserve the right to not deliver any message, including, but not limited to, job
                                        applications, that Forstec suspects to be malicious or spam, for fraud mitigation, or for
                                        any reason. This includes but is not limited to messages with an .ade, .adp, .bat, .chm,
                                        .cmd, .com, .cpl, .exe, .hta, .ins, .isp, .jar, .jse, .lib, .so, .dll, .lnk, .mde, .msc, .msp, .mst,
                                        .pif, .scr, .sct, .shb, .sys, .vb, .vbe, .vbs, .vxd, .wsc, .wsf, .wsh, or .zip attachment or any
                                        other attachment containing scripts, macros, or other code. Forstec further reserves the
                                        right to conduct investigations to determine whether you are perpetrating a scam,
                                        spamming Forstec or its users, or otherwise conducting fraudulent activity on Forstec by
                                        various means, including but not limited to investigating your functionality by setting up
                                        profiles and names that belong to Forstec. Forstec reserves the right to turn over any
                                        information gathered via such investigations to the police or other third party if Forstec
                                        has a good faith belief that you are using the Site in violation of these terms.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        6. Registering with and Contacting Forstec
                                    </div>
                                    <div className='mb-10'>
                                        Some areas of the Site, including areas that may permit you to set up an Forstec
                                        account, require you to register or provide an email address. If you so register, you
                                        agree to (a) provide true, accurate, current and complete information about yourself as
                                        prompted by our registration form (including your email address) and (b) be responsible
                                        for the confidentiality and use of your username and password, and not transfer or resell
                                        your use of or access to the Site to any third party. You may not share your username
                                        and password with other individuals. Please note that any individuals with whom you
                                        have shared your username and password and who are able to answer verification
                                        questions about your Forstec account may receive access to information regarding your
                                        Forstec account. You are advised to safeguard important data and to not upload
                                        confidential information on the Site. We reserve the right at all times (but will not have
                                        an obligation) to terminate users or to reclaim usernames or URLs. At Forstec’s
                                        discretion, it may mandate two-factor authentication on your account.
                                        <strong>
                                            Forstec
                                            encourages you to enable two-factor authentication on your account where this
                                            functionality is available. If you do not enable two-factor authentication on your
                                            account, please be aware that you are proceeding at your own risk. I
                                        </strong>
                                        If someone
                                        obtains unauthorized access of your account, they may have the ability to contact users
                                        or take other actions through your account, and Forstec may have only limited ability to
                                        remedy such situations.
                                        You agree that Forstec may, as part of its services to you, communicate with you, or
                                        initiate communication with you on behalf of a third party, through your Forstec account
                                        or through other means such as email, telephone (including mobile phone), or postal
                                        mail, including through the use of contact information that you may provide to Forstec or
                                        that Forstec may otherwise obtain from third-party sources. By providing Forstec with a
                                        mobile phone number, you confirm that you are the primary user and subscriber to the
                                        telephone number entered, and you expressly consent to receiving communication via
                                        such mobile number. If you are a Job Seeker who provided your phone number in
                                        connection with your job application, you agree the Employer may contact you at that
                                        number. When you give Forstec a mobile number and consent to receiving
                                        communication, such communications are inherent to the services you have signed up
                                        for. To stop receiving text messages at any time, reply to the text with STOP or contact
                                        Forstec Customer Support. For clarity, you agree and understand that texting STOP in
                                        response to an Forstec SMS alert will unsubscribe you from Forstec SMS alerts.
                                        However, you may continue to receive texts related to some offerings to which you have
                                        subscribed, such as Text2Apply and Hiring Events. In order to unsubscribe from these,
                                        you must respond STOP within the specific text flows of those offerings. Please note
                                        that replying STOP to any text message would unsubscribe you from text messages
                                        only, but you may still receive other communications from Forstec. You may also revoke
                                        your consent to receive text messages by terminating the Agreement through closing
                                        your Forstec account. By enabling two step verification, you verify that you are the
                                        owner of the phone number you provided. Revoking consent by replying STOP or
                                        terminating the Agreement does not apply to a phone number that an Employer already
                                        has that you provided as part of a job application. Once an Employer has your phone
                                        number, you agree that Forstec is not responsible or liable for the manner in which the
                                        Employer uses the number.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        7. Disclaimers of Warranties
                                    </div>
                                    <div className='mb-10'>
                                        Forstec disclaims any and all responsibility or liability for the accuracy, content,
                                        completeness, legality, reliability, operability or availability of information or material
                                        displayed in or linked from the Forstec search results (including Jobs Listings) or
                                        otherwise made available on the Site by Forstec or third parties (including User
                                        Content), regardless of whether paid for or used for free. Forstec disclaims any
                                        responsibility or liability for the accuracy, content, completeness or reliability of
                                        information provided by Forstec for informational purposes only, including but not limited
                                        to, Forstec Analytics data like estimated applies or organic traffic, and salary
                                        information, or information that may come from a third party. You acknowledge you are
                                        not paying Forstec for the aforementioned information. Forstec disclaims any
                                        responsibility for the deletion, failure to store, mis-delivery, or untimely delivery of any
                                        information, emails, attachments or material (including User Content). Forstec does not
                                        guarantee that the Site will always be error free, safe, or secure. Forstec further
                                        disclaims any responsibility for any harm resulting from downloading or accessing any
                                        information or material on the Site or on other sites or services on the Internet accessed
                                        through the Site. Under no circumstances shall Forstec be liable to you or any third
                                        party on account of your use or misuse of or reliance on the Site. Additionally, under no
                                        circumstances shall Forstec be liable to you or any third party on account of your use or
                                        misuse of or reliance on any third party site or service you link to from Forstec’s Site.
                                        Forstec further disclaims all liability for any technical malfunction of the Site, including
                                        but not limited to failure of any telephone network or service, computer systems, servers
                                        or providers, computer or mobile phone equipment, software, failure of email delivery
                                        (including attachments), account login, or any other Services provided by Forstec as a
                                        result of technical problems or traffic congestion on the Internet or any third party
                                        website (including but not limited to Applicant Tracking Systems (ATSs)) or combination
                                        thereof, including injury or damage to your or to any other person’s computer, mobile
                                        device or other hardware or software, related to or resulting from using or downloading
                                        any Content in connection with the Site or Services. Under no circumstances will
                                        Forstec be responsible for any loss or damage to any content or personal injury or
                                        death, resulting from anyone’s use of the Site, Services, User Content, or third party
                                        applications, websites, software or content posted on or through the Site or transmitted
                                        to users or any interactions between users of the Site or Services, whether online or
                                        offline.
                                        THE SITE, AND ALL MATERIALS, INFORMATION, ADVICE, JOB LISTINGS,
                                        CAREER GUIDE, USER CONTENT, PRODUCTS, FEATURES AND SERVICES
                                        AVAILABLE ON OR THROUGH THE SITE ARE PROVIDED “AS IS,” WITH NO
                                        WARRANTIES WHATSOEVER, EITHER EXPRESS OR IMPLIED. YOU USE
                                        FORSTEC AND THE SITE AT YOUR OWN RISK. FORSTEC AND ITS AFFILIATES,
                                        AND ITS AND THEIR THIRD-PARTY LICENSORS, EXPRESSLY DISCLAIM TO THE
                                        FULLEST EXTENT PERMITTED BY LAW ALL WARRANTIES, WHETHER EXPRESS,
                                        IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, THE
                                        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                                        NON-INFRINGEMENT OF PROPRIETARY RIGHTS, TITLE, SERVICE QUALITY, AND
                                        THAT THE SITE AND SERVICES PROVIDED WILL BE UNINTERRUPTED, ERROR
                                        FREE, SAFE, OR SECURE. FORSTEC AND ITS AFFILIATES, AND ITS AND THEIR
                                        THIRD-PARTY LICENSORS, DISCLAIM ANY WARRANTIES REGARDING THE
                                        SECURITY, RELIABILITY, TIMELINESS, AND PERFORMANCE OF THE SITE, AND
                                        ALL MATERIALS, INFORMATION, ADVICE, JOB LISTINGS, USER CONTENT,
                                        FEATURES, PRODUCTS AND SERVICES AVAILABLE ON OR THROUGH THE SITE.
                                        FORSTEC AND ITS AFFILIATES, AND ITS AND THEIR THIRD-PARTY LICENSORS,
                                        DISCLAIM ANY WARRANTIES FOR SERVICES OR GOODS RECEIVED THROUGH
                                        OR ADVERTISED ON THE SITE OR RECEIVED THROUGH ANY LINKS MADE
                                        AVAILABLE BY FORSTEC. FORSTEC IS NOT RESPONSIBLE FOR THE ACTIONS,
                                        CONTENT, INFORMATION, OR DATA OF THIRD PARTIES, AND YOU RELEASE US,
                                        OUR DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS
                                        AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY
                                        CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD PARTIES.
                                        YOU UNDERSTAND AND AGREE THAT YOU DOWNLOAD OR OTHERWISE
                                        OBTAIN MATERIAL OR DATA THROUGH THE USE OF THE SITE AT YOUR OWN
                                        DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR
                                        ANY DAMAGES TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT
                                        RESULTS FROM DOWNLOADING OR OBTAINING ANY SUCH MATERIAL OR
                                        DATA.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        8. Limitations of Liability
                                    </div>
                                    <div className='mb-10'>
                                        UNDER NO CIRCUMSTANCES SHALL FORSTEC OR ITS AFFILIATES, OR ITS OR
                                        THEIR THIRD-PARTY LICENSORS, BE LIABLE TO ANY USER ON ACCOUNT OF
                                        THAT USER’S USE OR MISUSE OF OR RELIANCE ON THE SITE. SUCH
                                        LIMITATION OF LIABILITY SHALL APPLY TO PREVENT RECOVERY OF INDIRECT,
                                        INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, AND PUNITIVE
                                        DAMAGES WHETHER SUCH CLAIM IS BASED ON WARRANTY, CONTRACT, TORT
                                        (INCLUDING NEGLIGENCE), INDEMNITY, OR OTHERWISE, EVEN IF FORSTEC OR
                                        ITS AFFILIATES, OR ITS OR THEIR THIRD-PARTY LICENSORS, HAVE BEEN
                                        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND NOTWITHSTANDING
                                        THE FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY. SUCH LIMITATION OF
                                        LIABILITY SHALL APPLY WHETHER THE DAMAGES ARISE FROM USE OR
                                        MISUSE OF AND RELIANCE ON THE SITE, FROM RELIANCE OR DAMAGE
                                        CAUSED BY INFORMATION POSTED ON THE SITE, FROM INABILITY TO USE THE
                                        SITE, OR FROM THE INTERRUPTION, SUSPENSION, OR TERMINATION OF THE
                                        SITE (INCLUDING SUCH DAMAGES INCURRED BY THIRD PARTIES). THIS
                                        LIMITATION SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY
                                        REASON OF OTHER SERVICES OR GOODS RECEIVED THROUGH OR
                                        ADVERTISED ON THE SITE OR RECEIVED THROUGH ANY LINKS MADE
                                        AVAILABLE ON THE SITE. THIS LIMITATION SHALL ALSO APPLY, WITHOUT
                                        LIMITATION, TO THE COSTS OF PROCUREMENT OF SUBSTITUTE GOODS OR
                                        SERVICES, LOST PROFITS, OR LOST DATA. SUCH LIMITATION SHALL FURTHER
                                        APPLY WITH RESPECT TO THE PERFORMANCE OR NON-PERFORMANCE OF
                                        THE SITE OR ANY INFORMATION OR MERCHANDISE THAT APPEARS ON, OR IS
                                        LINKED OR RELATED IN ANY WAY TO, THE SITE. SUCH LIMITATION SHALL
                                        APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY
                                        LIMITED REMEDY, AND TO THE FULLEST EXTENT PERMITTED BY LAW. TO THE
                                        EXTENT PERMITTED BY LAW, THE MAXIMUM AGGREGATE LIABILITY OF
                                        FORSTEC AND ITS AFFILIATES, AND ITS AND THEIR THIRD-PARTY LICENSORS,
                                        FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN
                                        CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, SHALL BE
                                        LIMITED TO THE AMOUNT YOU PAID FORSTEC TO USE THE SITE.
                                        Without limiting the foregoing, under no circumstances shall Forstec or its affiliates, or
                                        its or their third-party licensors, be liable or responsible, or be deemed to have defaulted
                                        under or breached this Agreement, for any delay or failure in fulfillment or performance
                                        resulting, arising out of or caused by, directly or indirectly, or results from acts, causes,
                                        forces or circumstances beyond its or their control, including, without limitation, the
                                        following force majeure events: (a) acts of God or natural catastrophes or forces, (b)
                                        flood, fire, storm, earthquake, epidemics, explosion or other similar events; (c) war,
                                        invasion, hostilities (whether war is declared or not), terrorist threats or acts, riots,
                                        insurrections, other civil unrest or disturbances, military disturbances or sabotage; (d)
                                        government action, order, law or regulation or orders of domestic or foreign courts or
                                        tribunals; (e) actions, embargoes or blockades in effect on or after the date of this
                                        Agreement; (f) action by any governmental authority; (g) national or regional
                                        emergency; (h) strikes, labor disputes or stoppages or slowdowns or other industrial
                                        disturbances or shortage of labor or materials; (i) breakdown, interruptions, loss.,
                                        fluctuations or malfunctions of utilities (including heat, light or air conditioning),
                                        communications, computers (software and hardware, and including, but not limited to,
                                        computer viruses), or telephone communication services; (j) internet, computer
                                        equipment, telecommunication equipment, electrical power or other equipment or
                                        mechanical failures or shortages; (k) loss of data due to power failures or mechanical
                                        difficulties with information storage or retrieval systems; (l) non performance of third
                                        parties; and (m) other events beyond the control of Forstec.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        9. Governing Law and Dispute Resolution
                                    </div>
                                    <div className='mb-10'>
                                        If you are neither a Job Seeker nor an Employer as defined in this Agreement and are
                                        not using the Site as intended by Forstec, this Agreement will be governed as to all
                                        matters, including, but not limited to the validity, construction and performance of this
                                        Agreement.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        10. Severability and Reformation
                                    </div>
                                    <div className='mb-10'>
                                        Each provision of this Agreement is a separately enforceable provision. If any provision
                                        of this Agreement is determined to be or becomes unenforceable or illegal, such
                                        provision shall be reformed to the minimum extent necessary in order for this
                                        Agreement to remain in effect in accordance with its terms as modified by such
                                        reformation. The unenforceability or invalidity of any provision shall not affect any other
                                        provision of this Agreement, and this Agreement shall continue in full force and effect,
                                        and be construed and enforced, as if such provision had not been included, or had been
                                        modified as above provided, as the case may be.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        11. Filtering for Minors
                                    </div>
                                    <div className='mb-10'>
                                        We hereby notify you that parental control protections (such as computer hardware,
                                        software or filtering services) are commercially available that may assist you in limiting
                                        access to material that is harmful to minors. Information identifying current providers of
                                        such protections is available from GetNetWise and OnGuard Online. Please note that
                                        Forstec does not endorse any of the products or services listed on such sites.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        12. Use of Site by Minors
                                    </div>
                                    <div className='mb-10'>
                                        The Site is not for use by anyone under the age of 16. However, if local laws provide for
                                        an alternative minimum age for Forstec to lawfully provide the services in the Site to
                                        you, then that shall apply as the applicable minimum age. In all jurisdictions outside the
                                        European Union, if you are under the age of 18 or the age of majority in your
                                        jurisdiction, you must use Forstec under the supervision of your parent, legal guardian
                                        or responsible adult. Forstec adopts a range of measures to try to ensure that we do not
                                        accept individuals who do not meet the minimum age and any other applicable age
                                        requirements. If you do not satisfy these age requirements, please email privacy-dept @
                                        Forstec.com (remove spaces when sending email).
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        13. Using e-Signature
                                    </div>
                                    <div className='mb-10'>
                                        Some pages on the Site may offer an e-signature option, which is powered by a thirdparty service provider. By using e-signatures on the Site, you agree that your esignature is intended to authenticate the document you’re signing, and your e-signature
                                        will have the same force and effect as a manual signature. Forstec disclaims all
                                        warranties associated with e-signatures, including any errors associated with the
                                        software or the validity of the signature. Forstec is not a legal record keeper. Your use
                                        of e-signatures is at your own risk, and it is your responsibility to ensure that your
                                        document is received and to retain your own copies.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        14. Beta Products
                                    </div>
                                    <div className='mb-10'>
                                        Forstec may offer beta test products or features on our Site. You will know that a
                                        product or feature is offered on a beta or test basis because the product name or
                                        feature will state that it is offered on a beta or test basis or the name will include the
                                        word “beta.” These beta test products or features may be offered in limited quantities, in
                                        limited locations, and for a limited time. Forstec may discontinue or cancel all or part of
                                        a beta product or feature at anytime without prior notice to you. ANY BETA PRODUCT
                                        OR FEATURE IS IN TESTING PHASE AND IS PROVIDED ON AN “AS IS” AND “AS
                                        AVAILABLE” BASIS, IS BELIEVED TO CONTAIN DEFECTS, AND A PRIMARY
                                        PURPOSE OF THIS BETA TEST IS TO OBTAIN FEEDBACK ON THE PRODUCT
                                        PERFORMANCE. YOU ARE ADVISED TO SAFEGUARD IMPORTANT DATA, TO
                                        USE CAUTION AND NOT RELY IN ANY WAY ON THE CORRECT FUNCTIONING OR
                                        PERFORMANCE OF THE BETA PRODUCT OR FEATURE OR ACCOMPANYING
                                        MATERIALS. If you have been invited to test a beta product or feature, you agree that,
                                        unless otherwise specifically provided herein or agreed by Forstec in writing, any beta
                                        product or feature and any documentation provided corresponding with a beta product
                                        or feature constitute confidential proprietary information of Forstec. You shall permit
                                        only authorized users, who possess rightfully obtained access to any beta product or
                                        functionality, access to the beta product or functionality or any corresponding
                                        documentation. You agree not to transfer, copy, disclose, provide or otherwise make
                                        available such confidential information in any form to any third party without the prior
                                        written consent of Forstec.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        15. Questions or Complaints
                                    </div>
                                    <div className='mb-10'>
                                        If you have a question or complaint regarding the Site, please use our Forstec Help
                                        Center. When you use the Forstec Help Center, you are accessing a third party site
                                        Zendesk and consent to receiving cookies from Zendesk, as set forth in the Zendesk
                                        cookie policy. Please note that communications within Zendesk will not necessarily be
                                        secure; accordingly you should not include credit card information or other sensitive
                                        information in your e-mail correspondence with us.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        16. Miscellaneous
                                    </div>
                                    <div className='mb-10'>
                                        This Agreement (including any other section of this Agreement like the Forstec Ads
                                        Program Terms or Forstec Resume Terms) constitutes the entire agreement between
                                        the parties with respect to the subject matter hereof, including any and all provisions
                                        applicable to the parties, and supersedes and replaces all prior or contemporaneous
                                        understandings or agreements, written or oral, regarding such subject matter.
                                        The materials on the Site are provided with “Restricted Rights.” Use, duplication, or
                                        disclosure by the Government is subject to restrictions as set forth in applicable laws
                                        and regulations. Use of the materials by the Government constitutes acknowledgment
                                        of Forstec’s proprietary rights in them.
                                        You understand and acknowledge that Forstec or its affiliates, or its or their licensors,
                                        owns all right title and interest to the Site and all proprietary rights associated therewith.
                                        Forstec reserves all rights not specifically granted herein. You shall not modify any
                                        copyright notices, proprietary legends, any trademark and service mark attributions, any
                                        patent markings, or other indicia of ownership on the materials accessed through the
                                        Site, other than your User Content. Any use of materials or descriptions, any derivative
                                        use of the Site or its materials, and any use of data mining, robots, or similar data
                                        gathering and extraction tools is strictly prohibited. In no event may you frame any
                                        portion of the Site or any materials contained therein.
                                        In the event of any irreconcilable conflict between these Forstec General Terms of
                                        Service and the Forstec Ads Program Terms, Forstec Resume Search Program Terms,
                                        Forstec Targeted Ads Terms, Terms of Service for Publishers or other subset of terms
                                        as applicable to you, the Forstec Ads Program Terms, Forstec Resume Search
                                        Program Terms, Forstec Targeted Ads Terms, Terms of Service for Publishers or other
                                        subset of terms as applicable to you will govern with respect to the Forstec Ads
                                        Program, Forstec Resume Program, Forstec Targeted Ads, Terms of Service for
                                        Publishers or other subset of terms as applicable to you, respectively, and these
                                        Forstec General Terms of Service will otherwise govern.
                                        Any waiver of any provision of this Agreement will be effective only if in writing and
                                        signed by Forstec. This Agreement, together with any amendments and any additional
                                        agreements you may enter into with Forstec in connection with the Site, shall constitute
                                        the entire agreement between you and Forstec concerning the Site. If any provision of
                                        this Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of
                                        such provision shall not affect the validity of the remaining provisions of this Agreement,
                                        which shall remain in full force and effect. You agree that this Agreement cannot be
                                        altered, amended, modified or overridden, except by a document signed by an
                                        authorized representative of each party. For the avoidance of doubt, email or phone
                                        communication between you and an Forstec employee shall not constitute an
                                        amendment or alteration of this Agreement.
                                        Any notices to Forstec must be sent to the applicable Forstec entity identified in the first
                                        paragraph of this Agreement via certified first class or air mail or overnight courier, are
                                        deemed given upon receipt, and must reference the Program that is the subject of the
                                        notice (i.e., Forstec Ads Program, Forstec Resume Search Program, or Forstec
                                        Publisher Program). Notice to you may be effected by sending email to the email
                                        address specified in your account, or by posting a message to your account interface,
                                        and is deemed received when sent (for email) or no more than fifteen (15) days after
                                        having been posted (for messages in your Forstec Ads interface).
                                        You may not assign or delegate any of your rights or obligations hereunder without
                                        Forstec’s prior written consent and any such attempt is void. Forstec may freely assign
                                        or delegate its rights and obligations hereunder without notice to you. Additionally,
                                        Forstec may assign any agreement between you and Forstec to any related Forstec
                                        entity by informing you of such assignment. Forstec and you are not in a legal
                                        partnership, agency relationship, or employment relationship. When Forstec provides
                                        the Site or any related services to you, Forstec does so solely in its capacity as a
                                        service provider.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        17. Apple-Specific Terms
                                    </div>
                                    <div className='mb-10'>
                                        In addition to your agreement with the foregoing terms and conditions, and
                                        notwithstanding anything to the contrary herein, the following provisions apply with
                                        respect to your use of any mobile application version of the Site compatible with the iOS
                                        operating system of Apple Inc. (“Apple”, and such mobile application, the “App”). Apple
                                        is not a party to this Agreement and does not own and is not responsible for the App.
                                        Apple is not providing any warranty for the App except, if applicable, to refund the
                                        purchase price for it. Apple is not responsible for maintenance or other support services
                                        for the App and shall not be responsible for any other claims, losses, liabilities,
                                        damages, costs or expenses with respect to the App, including any third-party product
                                        liability claims, claims that the App fails to conform to any applicable legal or regulatory
                                        requirement, claims arising under consumer protection or similar legislation, and claims
                                        with respect to intellectual property infringement. Any inquiries or complaints relating to
                                        the use of the App, including those pertaining to intellectual property rights, must be
                                        directed to Forstec in accordance with the “Questions or Complaints” section above.
                                        The license you have been granted herein is limited to a non-transferable license to use
                                        the App on an Apple-branded product that runs Apple’s iOS operating system and is
                                        owned or controlled by you, or as otherwise permitted by the Usage Rules set forth in
                                        Apple’s App Store Terms of Service. In addition, you must comply with the terms of any
                                        third-party agreement applicable to you when using the App, such as your wireless data
                                        service agreement. Apple and Apple’s subsidiaries are third-party beneficiaries of this
                                        Agreement and, upon your acceptance of the terms and conditions of this Agreement,
                                        will have the right (and will be deemed to have accepted the right) to enforce this
                                        Agreement against you as a third-party beneficiary thereof; notwithstanding the
                                        foregoing, Forstec’s right to enter into, rescind or terminate any variation, waiver or
                                        settlement under this Agreement is not subject to the consent of any third party.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        18. The ForstecApps
                                    </div>
                                    <div className='mb-10'>
                                        By using any of the Forstec Apps, including but not limited to the Forstec Job Search,
                                        Forstec Resume Search, Forstec Employer, Forstec Job Spotter or others, you agree
                                        that all of the terms and conditions found herein apply to your use of the Forstec App.
                                        Additionally, you agree that the Forstec App may send you push notifications, if you turn
                                        on receipt of notifications and/or sign up to receive notifications. By downloading and
                                        using the Forstec App, you are asking Forstec to make formatting changes to any Job
                                        Listings as they appear on your phone, in order to enable you to utilize Your Resume.
                                        You further acknowledge that your use of the Forstec Apps may require use of your
                                        data plan from your cellular provider. You are responsible for payment of all data usage
                                        accrued based on your use of the Forstec App, and Forstec disclaims all responsibility
                                        for such data usage. In addition, if you allow Forstec Apps to utilize location services,
                                        the Forstec Apps may suggest to you and collect from you information based on your
                                        geographic location. Some location data the Forstec Apps collect is linked to your
                                        account (for example, “coarse” location data collected by an iOS app). Other location
                                        data the Forstec Apps collect is not linked to your account (for example, “precise”
                                        location data collected by an iOS app) but is still used by Forstec. The legal entity that (
                                        i) provides any services to you through the Forstec Apps, (ii) owns or licenses the
                                        intellectual property rights to any of Forstec Apps and/or (iii) has access or controls any
                                        personal data through the Forstec Apps may at times be an affiliate of Forstec, even if
                                        the entity identified as the application developer on any of the application marketplaces
                                        is Forstec, Inc.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        19. Payments
                                    </div>
                                    <div className='mb-10'>
                                        You understand that services offered by Forstec or supported by our Sites may require
                                        payment for access or continued use. Each product may have its own payment terms
                                        specific to the needs and function of the service and such terms will be agreed on the
                                        Site, in a separate agreement, insertion order, or as indicated by the Site between you
                                        and Forstec. For the avoidance of doubt, where a product or insertion order has
                                        product-specific payment terms which overlap or conflict with the payment terms in this
                                        section, you understand and agree that the product-specific terms will govern.
                                        Forstec may charge you in different ways depending on the service provided, including
                                        based on clicks, RSVPs, applies, impressions, subscription, and/or a platform fee, if
                                        applicable.
                                        <strong>
                                            Charges are solely based on Forstec’s measurements, including, but
                                            not limited to, Forstec’s Apply, click, or ITA Brand Impression counts. Forstec’s
                                            determination of such impression count shall be binding. Forstec’s
                                            measurements may include measurements from third-party vendors. Forstec
                                            disclaims warranty with respect to measurements provided by third-party
                                            vendors. Forstec’s determination of the number, including, but not limited to,
                                            Applies, clicks or ITA Brand Impressions, shall be binding, even in cases where
                                            this number encompasses measurements provided by a third-party vendor.
                                        </strong>
                                        Forstec may transfer your prepaid funds for the use of one product towards your use of
                                        another product if you so request through any means of communication, including via
                                        phone, website or email. Further, you understand and agree that if your Forstec
                                        employer account has a credit card on file for the Forstec Ads Program or any other
                                        Forstec service, we will charge that same credit card for your RSVPs unless otherwise
                                        indicated by you.
                                    </div>

                                    <div className='mb-2 fs-6 fw-bolder'>
                                        Billing Models
                                    </div>
                                    <div className='mb-10'>
                                        For payment based on a subscription, you will be charged as indicated in an insertion
                                        order or by the Site. If you purchase a subscription plan, you acknowledge and agree
                                        that your subscription will automatically renew and Forstec will charge you on a
                                        recurring basis until you cancel your subscription plan and such cancellation goes into
                                        effect, which may not be until the next billing cycle. Pausing your subscription does not
                                        cancel it. Your subscription will continue to automatically renew after being unpaused.
                                        Upon your request, Forstec may, in its sole discretion, pause your subscription and
                                        associated costs, and upon Forstec unpausing the subscription, it will continue for the
                                        remaining term until it expires and automatically renews. Details regarding the resume
                                        subscription or plan are available on the Site.
                                        <strong>
                                            THERE ARE NO REFUNDS OR
                                            CREDITS FOR PARTIALLY USED BILLING CYCLES UNDER THE APPLICABLE
                                            RESUME SUBSCRIPTION PLAN. AMOUNTS FOR FUTURE PREPAID UNUSED
                                            BILLING CYCLES MAY RESULT IN A PRORATED REFUND OF THE UNUSED
                                            BILLING CYCLES, WHICH SHALL BE DETERMINED IN FORSTEC’S SOLE
                                            DISCRETION.
                                        </strong>
                                        Following any cancellation, however, you will continue to have access to
                                        the service through the end of your current billing period.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        Billing Policy
                                    </div>
                                    <div className='mb-10'>
                                        You acknowledge and agree that any credit card and related billing and payment
                                        information that you provide to Forstec may be shared by Forstec with companies who
                                        work on Forstec’s behalf, such as payment processors and/or credit agencies, solely for
                                        the purposes of checking credit, effecting payment to Forstec and servicing your
                                        account. Forstec may also provide information in response to valid legal process, such
                                        as subpoenas, search warrants and court orders, or to establish or exercise its legal
                                        rights or defend against legal claims. You agree that Forstec may obtain updated
                                        payment information for credit and debit cards through payment card networks, card
                                        issuers or other third party sources. You further agree that Forstec may use the updated
                                        card information to charge amounts you owe Forstec. Forstec shall not be liable for any
                                        use or disclosure of such information by such third parties. Invoices may be provided to
                                        you via electronic mail, unless otherwise specified by Forstec. To the extent permitted
                                        by law, if you have an unpaid or outstanding invoice or account balance for any Forstec
                                        product, Forstec reserves the right to suspend or terminate your use of that Forstec
                                        product as well as any other Forstec product, including but not limited to those Forstec
                                        product where you do not have an unpaid invoice or account balance.
                                    </div>
                                    <div className='mb-2 fs-6 fw-bolder'>
                                        Refunds
                                    </div>
                                    <div className='mb-10'>
                                        Refunds for Forstec services that have already been provided (if any) are at the
                                        absolute discretion of Forstec and only in the form of advertising credit for Forstec
                                        Property. If you are removed from Forstec, but have previously spent on Forstec, you
                                        are not entitled to a refund of previously spent amounts for services already provided.
                                        Refunds for unused Forstec services that have been pre-paid will be refunded on a
                                        prorated basis and as per product specific Payment Terms only the unused future prepaid subscription billing cycles will be paid, subject to Forstec’s discretion. All approved
                                        refunds for unused Forstec services shall first, in Forstec’s full discretion, be used to
                                        pay all outstanding unpaid balances and interest for any Forstec product or service
                                        where one exists associated with your account.
                                    </div>
                                    <div style={{ borderBottom: '1px solid #eeeeee', margin: '15px 0' }}></div>
                                    <div className='mb-5'>
                                        Kind regards, The Accelerant Team.
                                        <tr>
                                            <td
                                                align='center'
                                                style={{
                                                    fontSize: '13px',
                                                    textAlign: 'center',
                                                    padding: '20px',
                                                    color: '#6d6e7c',
                                                }}
                                            >
                                                <p>Floor 5, Ben Aoun Jawhra, Sousse, 4000, Tunisia.</p>
                                                <p>
                                                    Copyright 2022 ©
                                                    <a rel='noopener' target='_blank'>
                                                        Accelerant
                                                    </a>
                                                    .
                                                </p>
                                            </td>
                                        </tr>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export { TermsOfService }
