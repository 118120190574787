import { FC, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  confirmCandidateEmail,
  confirmEmail,
  resetCandidatePassword,
  resetCompanyPassword,
} from '../../../setup/repository/sharedAPI'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import * as redux from '../auth/redux/AuthRedux'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
const ResetCard: FC = () => {
  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const [apiMessage, setApiMessage] = useState({
    error: false,
    success: false,
  })
  const params: any = useParams()
  const token = params.token
  const id = params.id
  const [passwordUpdateData, setPasswordUpdateData] = useState<any>({ password_confirmation: '', new_password: '' })

  const dispatch = useDispatch()
  const history = useHistory()
  const passwordFormValidationSchema = Yup.object().shape({
    new_password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
    password_confirmation: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required')
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
  })
  const formik = useFormik<any>({
    initialValues: {
      ...passwordUpdateData,
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: (values) => {

      if (location.pathname.includes('candidate'))
        resetCandidatePassword({ id: id, token: token, new_password: values.new_password })
          .then(() => {
            setLoading(false)
            setApiMessage({ ...apiMessage, success: true })
          })
          .catch(() => {
            setLoading(false)
            setApiMessage({ ...apiMessage, error: true })
          })
      else
        resetCompanyPassword({ id: id, token: token, new_password: values.new_password })
          .then(() => {
            setLoading(false)
            setApiMessage({ ...apiMessage, success: true })
            setTimeout(() => history.push('/auth'), 3000)
          })
          .catch(() => {
            setLoading(false)
            setApiMessage({ ...apiMessage, error: true })
          })
    },
  })
  return (
    <>
      <div
        style={{
          fontFamily: 'Arial,Helvetica,sans-serif',
          lineHeight: '1.5',
          fontWeight: 'normal',
          fontSize: '15px',
          color: '#2F3044',
          minHeight: '100%',
          margin: '0',
          padding: '0',
          width: '100%',
          backgroundColor: '#edf2f7',
        }}
      >
        <table
          width='100%'
          style={{ borderCollapse: 'collapse', margin: '0 auto', padding: '0', maxWidth: '600px' }}
        >
          <tbody>
            <tr>
              <td align='center' style={{ textAlign: 'center', padding: '40px' }}>
                <a rel='noopener' target='_blank'>
                  <img alt='Logo' width={"50%"} src={toAbsoluteUrl('/media/logos/forstec2.png')} />
                </a>
              </td>
            </tr>
            <tr>
              <td align='left'>
                <div
                  style={{
                    textAlign: 'left',
                    margin: '0 20px',
                    padding: '40px',
                    backgroundColor: '#ffffff',
                    borderRadius: '6px',
                  }}
                >
                  <div style={{ paddingBottom: '30px', fontSize: '17px' }}>
                    <strong>Welcome to Forstec!</strong>
                  </div>
                  {apiMessage.error && (
                    <div className='mb-lg-15 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>
                        There was a problem activating your account please try again later
                      </div>
                      <div className='alert-text font-weight-bold'>
                        لقد وقع خطأ في تفعيل حسابك يرجى إعادة المحاولة
                      </div>
                    </div>
                  )}
                  {apiMessage.success && (
                    <div className='mb-10 bg-light-success p-8 rounded'>
                      {location.pathname.includes('candidate') ?
                        (
                          <div className='text-info fw-normal'>Your Password has been reset</div>
                        )
                        :
                        (
                          <>
                            <div className='text-info fw-normal'>
                              You reseted your password Successfully, you will be redirected to Login
                              page
                            </div>
                            <div className='text-info fw-normal'>
                              لقد قمت بإعادة تعيين كلمة السر بنجاح، سوف يتم إعادة توجيهك إلى صفحة الدخول
                            </div>
                          </>
                        )}
                    </div>
                  )}
                  <div style={{ paddingBottom: '30px' }}>Enter Your New Password and Confirm it</div>
                  <div style={{ paddingBottom: '30px' }}>يرجى إدخال كلمة السر الجديدة و تأكيدها</div>
                  <div style={{ paddingBottom: '40px' }}>
                    <form
                      onSubmit={formik.handleSubmit}
                      id='kt_signin_change_password'
                      className='form'
                      noValidate
                    >
                      <div className='col-lg-12'>
                        <div className='fv-row mb-0'>
                          <label htmlFor='new_password' className='form-label fs-6 fw-bolder mb-3'>
                           كلمة السر
                          </label>
                          <input
                            type='password'
                            className='form-control form-control-lg form-control-solid '
                            id='new_password'
                            {...formik.getFieldProps('new_password')}
                          />
                          {formik.touched.new_password && formik.errors.new_password && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.new_password}</div>
                            </div>
                          )}
                        </div>

                        <div className='col-lg-12'>
                          <div className='fv-row mb-0'>
                            <label
                              htmlFor='password_confirmation'
                              className='form-label fs-6 fw-bolder mb-3'
                            >
                              تأكيد كلمة السر
                            </label>
                            <input
                              type='password'
                              className='form-control form-control-lg form-control-solid '
                              id='password_confirmation'
                              {...formik.getFieldProps('password_confirmation')}
                            />
                            {formik.touched.password_confirmation &&
                              formik.errors.password_confirmation && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    {formik.errors.password_confirmation}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <button
                        type='submit'
                        id='kt_password_submit'
                        style={{
                          textDecoration: 'none',
                          display: 'inlineBlock',
                          textAlign: 'center',
                          padding: '0.75575rem 1.3rem',
                          fontSize: '0.925rem',
                          lineHeight: '1.5',
                          borderRadius: '0.35rem',
                          color: '#ffffff',
                          backgroundColor: '#009EF7',
                          border: '0px',
                          marginRight: '0.75rem!important',
                          fontWeight: '600!important',
                          outline: 'none !important',
                          verticalAlign: 'middle',
                          marginTop: '20px',
                        }}
                      >
                        {!loading && <span className='indicator-label'>تحيين كلمة السر</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                             يرجى الإنتظار...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </form>
                  </div>

                  <div style={{ borderBottom: '1px solid #eeeeee', margin: '15px 0' }}></div>

                  <div style={{ paddingBottom: '10px' }}>
                    Kind regards, Forstec Team.
                    <tr>
                      <td
                        align='center'
                        style={{
                          fontSize: '13px',
                          textAlign: 'center',
                          padding: '20px',
                          color: '#6d6e7c',
                        }}
                      >
                        <p>Floor 5, Ben Aoun Jawhra, Sousse, 4000, Tunisia.</p>
                        <p>
                          Copyright 2022 ©
                          <a rel='noopener' target='_blank'>
                            &nbsp; Forstec
                          </a>
                          .
                        </p>
                      </td>
                    </tr>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export { ResetCard }
