import {FC} from 'react'

const Error500: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-white mb-10'>No Job was selected</h1>

      <div className='fw-bold fs-3 text-400 mb-15'>
        Something went wrong! <br /> Please try selecting the job from list job.
      </div>
    </>
  )
}

export {Error500}
