/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'

export function AuthPage() {

  const intl = useIntl()
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-lg-row flex-column-fluid">

        {/* begin::Content */}
        <div className="d-flex flex-column flex-lg-row-auto w-xl-700px positon-xl-relative" style={{ backgroundColor: "#00013e" }}>
          <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-700px scroll-y">
            <div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-10 mt-lg-10">
              <a className="py-9 mb-5">
                <img alt="Logo" src={toAbsoluteUrl('/media/logos/forstec3.png')} className="h-100px" />
              </a>

              <h1 className="fw-bolder fs-2qx pb-5 pb-md-10" style={{ color: "#fff" }}>{intl.formatMessage({ id: 'WELCOME.TO' })}  Forstec</h1>

              
            </div>

            <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px" style={{ backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sigma-1/hiring.png')}')` }}></div>
          </div>
        </div>
        {/* begin::Wrapper */}
        <div className="d-flex flex-column flex-lg-row-fluid py-10">
          <div className="d-flex flex-center flex-column flex-column-fluid">
            <div className="w-lg-500px p-10 p-lg-15 mx-auto">

              <Switch>
                <Route path='/auth/login' component={Login} />
                <Route path='/auth/registration' component={Registration} />
                <Route path='/auth/forgot-password' component={ForgotPassword} />
                <Redirect from='/auth' exact={true} to='/auth/login' />
                <Redirect to='/auth/login' />
              </Switch>
              {/* end::Wrapper */}
            </div>
          </div>
        </div>
        {/* end::Content */}
      </div>
    </div>
  )
}
