/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl, defaultAlerts, defaultLogs} from '../../../helpers'
import * as redux from '../../../../app/modules/auth/redux/AuthRedux'
import { useLang } from '../../../../_metronic/i18n/Metronici18n'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import moment from 'moment'
const HeaderNotificationsMenu: FC = () => 
{
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) 
  const lang = useLang()
  const dispatch = useDispatch()
  const history = useHistory();
  const notifications: any = useSelector<RootState>(({ auth }) => auth.notifications) 
  useEffect(() => {
    if(user.id)
    dispatch(redux.actions.getNotifications({ field: "company_user_id", operand: "=", value: user.id }))
  },[user])
  const goToNotifcation = (notification : any) => {
    let values = {...notification, status: 'seen'}
    let id = notification.id
    dispatch(redux.actions.editNotification({id, values}))
    if(notification.url)
    history.push(notification.url)
  }
  return(
   notifications ? (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>Notifications</h3>
        {/*
        <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_1'
            >
              Alerts
            </a>
          </li>
  
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_2'
            >
              Updates
            </a>
          </li>
  
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_3'
            >
              Logs
            </a>
          </li>
        </ul>*/}
      </div>
  
      <div className='tab-content'>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_2' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {notifications.map((notification: any, index: number) => (
              <div  onClick={() => goToNotifcation(notification)} key={`alert${index}`} className='d-flex flex-stack py-4' style={{backgroundColor : `${notification.status == 'unseen' ? 'lightcyan' : ''}`}}>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-35px me-4'>
                    <span className={clsx('symbol-label')}>
                      {' '}
                      <KTSVG
                        path={`/media/icons/duotune/maps/map001.svg`}
                        className={`svg-icon-2 svg-icon-primary`}
                      />
                    </span>
                  </div>
  
                  <div className='mb-0 me-2'>
                    <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                      {lang == 'fr' ? notification.title_fr : lang == 'ar' ? notification.title_ar : notification.title}
                    </a>
                    <div className='text-400 fs-7'>{lang == 'fr' ? notification.content_fr : lang == 'ar' ? notification.content_ar : notification.content}</div>
                  </div>
                </div>
  
                <span className='badge badge-light fs-8'>{moment(notification?.created_at).locale(lang).fromNow()}</span>
              </div>
            ))}
          </div>
        </div>
  
        
      </div>
    </div>
  ): null)
}

export {HeaderNotificationsMenu}
