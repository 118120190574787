import axios from 'axios'
import { CompanyModel } from '../models/CompanyModel'

const API_URL = process.env.REACT_APP_API_URL
//const API_URL = "https://int.api.forstec.tn"

export const GET_COMPANY_DETAILS = `${API_URL}/companies/`
export const UPLOAD_IMAGE = `${API_URL}/upload`
export const GET_CATEGORIES = `${API_URL}/categories`
export const GET_METADATA = `${API_URL}/metadata`
export const API_SITE = `${API_URL}/sites`


// Server should return CompanyModel

export function getCompany(id: number) {
  return axios.get<CompanyModel>(GET_COMPANY_DETAILS + id)
}

export function editCompany(id: number, data: any) {
  return axios.patch<CompanyModel>(GET_COMPANY_DETAILS+id, data)
}
export function createSite(data: any) {
  return axios.post<any>(API_SITE, data)
}

export function deleteSite(id: any) {
  return axios.delete<any>(API_SITE+'/'+id)
}

export function updateSite(id: any, data:any) {
  return axios.patch<any>(API_SITE+'/'+id, data)
}


export function uploadImage(image: File) {
  let formData = new FormData();
  formData.append("file", image);
  return axios.post<any>(UPLOAD_IMAGE, formData)
}

export function getMetaData() {
  return axios.get<any>(GET_METADATA+'?filter={"status":{"op":"=","value":"active"}, "type":{"op":"!=","value":"company"}}')
}

export function getCategories(company_id: any) {
  return axios.get<any>(GET_CATEGORIES+`?filter={"status":{"orWhere": true,"op":"=","value":"active"}}&nestedfilter={"user":{"filters":[{"field":"company_id","op":"=","value":"${company_id}"}]}}`)
}

// Server should return object => { result: boolean } (Is Email in DB)

