import axios from 'axios'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL
//const API_URL = "https://int.api.forstec.tn"

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/company/me`
export const LOGIN_URL = `${API_URL}/company/login`
export const REGISTER_URL = `${API_URL}/company/register`
export const ROLES_URL = `${API_URL}/roles`
export const GET_NOTIFICATIONS = `${API_URL}/notifications/`
export const REQUEST_PASSWORD_URL = `${API_URL}/company/forgotPassword`
export const EDIT_USER = `${API_URL}/company_users/`

export const UPDATE_PASSWORD = `${API_URL}/company/updatePassword/`
export const UPDATE_EMAIL = `${API_URL}/company/updateEmail/`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(email: string, first_name: string, last_name: string, password: string, password_confirmation: string, company_name: string, role_id: any) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: first_name,
    last_name: last_name,
    password,
    password_confirmation,
    company_name : company_name,
    role_id: role_id
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email
  })
}

export function getUserByToken() {
  return axios.get<any>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function editCompanyPassword(data: any) {
  return axios.post<any>(UPDATE_PASSWORD, data)
}
export function editCompanyEmail(data: any) {
  return axios.post<any>(UPDATE_EMAIL, data)
}
export function getRoles(){
  return axios.get<any>(ROLES_URL)
}

export function editUser(id: number, data: any) {
  return axios.patch<any>(EDIT_USER+id, data)
}
export function editNotification(id: number, data: any) {
  return axios.patch<any>(GET_NOTIFICATIONS+id, data)
}
export function getNotifications(filter:string, operand:string, value:any) {
  return axios.get<any>(GET_NOTIFICATIONS+`?filter={"${filter}":{"op":"${operand}","value":"${value}"}}`)
}