import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as company from '../../app/modules/company'
import * as job from '../../app/modules/Job'
import * as candidate from '../../app/modules/candidate'
import * as users from '../../app/modules/sourcing'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  company: company.reducer,
  job: job.reducer,
  candidate: candidate.reducer,
  users: users.reducer

})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga(), company.saga(), job.saga(), candidate.saga(), users.saga()])
}
