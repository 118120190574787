import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeEvery} from 'redux-saga/effects'
import {getUsers} from './SourcingCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  getUsers: '[getUsers] Action',
  getUsersSucceded: '[getUsersSucceded] Action',
  getUsersFailed: '[getUsersFailed] Action',
  getSite: '[getSite] Action',
  getSiteSucceded: '[getSiteSucceded] Action',
  getArea: '[getArea] Action',
  getAreaSucceded: '[getAreaSucceded] Action',
}

const initialUsersState: UsersState = {
  users: [],
  loading: false,
  success: false,
  error: false,
  site: '',
  area: '',
}

export interface UsersState {
  users?: any
  loading?: boolean
  success?: boolean
  error?: boolean
  site: string
  area: string
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken']},
  (state: any = initialUsersState, action: ActionWithPayload<any>) => {
    switch (action.type) {
      case actionTypes.getUsers: {
        return {...state, loading: true, success: false, error: false}
      }
      case actionTypes.getUsersSucceded: {
        const users = action.payload
        return {...state, users, loading: false, success: true, error: false}
      }
      case actionTypes.getUsersFailed: {
        return {...state, loading: false, error: true, success: false}
      }
      case actionTypes.getSite: {
        return {...state, loading: true, success: false, error: false}
      }
      case actionTypes.getSiteSucceded: {
        const site = action.payload
        return {...state, site, loading: false, success: true, error: false}
      }
      case actionTypes.getArea: {
        return {...state, loading: true, success: false, error: false}
      }
      case actionTypes.getAreaSucceded: {
        const area = action.payload
        return {...state, area, loading: false, success: true, error: false}
      }

      default:
        return state
    }
  }
)

export const actions = {
  getUsers: (data: any) => ({type: actionTypes.getUsers, payload: data}),
  getUsersSucceded: (data: any) => ({type: actionTypes.getUsersSucceded, payload: data}),
  getUsersFailed: () => ({type: actionTypes.getUsersFailed}),
  getSite: (data: any) => ({type: actionTypes.getSite, payload: data}),
  getSiteSucceded: (data: any) => ({type: actionTypes.getSiteSucceded, payload: data}),
  getArea: (data: any) => ({type: actionTypes.getArea, payload: data}),
  getAreaSucceded: (data: any) => ({type: actionTypes.getAreaSucceded, payload: data}),
}

export function* saga() {
  yield takeEvery(actionTypes.getUsers, function* getUsersSaga(filters: any) {
    try {
      const {
        data: {data},
      } = yield getUsers(filters?.payload)
      yield put(actions.getUsersSucceded(data))
    } catch (e) {
      console.log('---eroor sourcing 1---', e)
      yield put(actions.getUsersFailed())
    }
  })
  yield takeEvery(actionTypes.getSite, function* getSiteSaga(id: any) {
    try {
      yield put(actions.getSiteSucceded(id?.payload))
    } catch (error) {
      console.log('---eroor sourcing 2---', error)
      yield put(actions.getUsersFailed())
    }
  })
  yield takeEvery(actionTypes.getArea, function* getAreaSaga(data: any) {
    try {
      yield put(actions.getAreaSucceded(data?.payload))
    } catch (error) {
      console.log('---eroor sourcing 3---', error)
      yield put(actions.getUsersFailed())
    }
  })
}
