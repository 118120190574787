import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest, select, takeEvery} from 'redux-saga/effects'
import {
  getcandidateApplications,getcandidatures
} from './CandidateCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  getcandidatures: '[getcandidatures] Action',
  getcandidaturesSucceded: '[getcandidaturesSucceded] Action',
  getcandidaturesFailed: '[getcandidaturesFailed] Action',
  getcandidateApplications: '[getcandidateApplications] Action',
  getcandidateApplicationsSucceded: '[getcandidateApplicationsSucceded] Action',
  getcandidateApplicationsFailed: '[getcandidateApplicationsFailed] Action',
  resetMessages: '[resetMessages] Action',
  resetMessagesSuccess: '[resetMessagesSuccess] Action',
}

const initialAuthState: JobState = {
  applications: [],
  candidatures: [],
  loading: false,
  success: false,
  failed: false,
}

export interface JobState {
  applications?: any
  candidatures?: any
  loading?: boolean
  success?: boolean
  failed?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken']},
  (state: any = initialAuthState, action: ActionWithPayload<any>) => {
    switch (action.type) {
      case actionTypes.getcandidatures: {
        return {...state, loading: true, success: false, failed: false}
      }
      case actionTypes.getcandidaturesSucceded: {
        const candidatures = action.payload.data
        return {...state, candidatures, loading: false}
      }
      case actionTypes.getcandidaturesFailed: {
        return {...state, failed: true, loading: false}
      }
      case actionTypes.getcandidateApplications: {
        return {...state, loading: true, success: false, failed: false}
      }
      case actionTypes.getcandidateApplicationsSucceded: {
        const applications = action.payload.data
        return {...state, applications, loading: false}
      }
      case actionTypes.getcandidateApplicationsFailed: {
        return {...state, failed: true, loading: false}
      }
      case actionTypes.resetMessagesSuccess: {
        return {...state, loading: false, success: false, failed: false}
      }
      default:
        return state
    }
  }
)

export const actions = {
  getcandidatures: (filters: any) => ({
    type: actionTypes.getcandidatures,
    payload: filters,
  }),
  getcandidaturesSucceded: (data: any) => ({
    type: actionTypes.getcandidaturesSucceded,
    payload: data,
  }),
  getcandidaturesFailed: () => ({type: actionTypes.getcandidaturesFailed}),
  
  getcandidateApplications: (filters: any) => ({
    type: actionTypes.getcandidateApplications,
    payload: filters,
  }),
  getcandidateApplicationsSucceded: (data: any) => ({
    type: actionTypes.getcandidateApplicationsSucceded,
    payload: data,
  }),
  getcandidateApplicationsFailed: () => ({type: actionTypes.getcandidateApplicationsFailed}),
  resetMessages: () => ({type: actionTypes.resetMessages}),
  resetMessagesSuccess: () => ({type: actionTypes.resetMessagesSuccess}),
}

export function* saga() {
  yield takeEvery(
    actionTypes.getcandidatures,
    function* getcandidaturesSaga(filters: any) {
      try {
        const {data} = yield getcandidatures(
          filters?.payload?.id,
          filters?.payload?.filter
        )
        yield put(actions.getcandidaturesSucceded(data))
      } catch (e) {
        yield put(actions.getcandidaturesFailed())
      }
    }
  )
  yield takeEvery(
    actionTypes.getcandidateApplications,
    function* getcandidateApplicationsSaga(filters: any) {
      try {
        const {data} = yield getcandidateApplications(
          filters?.payload?.id,
          filters?.payload?.filter
        )
        yield put(actions.getcandidateApplicationsSucceded(data))
      } catch (e) {
        yield put(actions.getcandidateApplicationsFailed())
      }
    }
  )
  yield takeEvery(actionTypes.resetMessages, function* resetMessagesSaga() {
    yield put(actions.resetMessagesSuccess())
  })
}
