import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
//const API_URL = "https://int.api.forstec.tn"

export const GET_COUNTRIES = `${API_URL}/countries`
export const GET_CITIES = `${API_URL}/cities/`
export const UPLOAD_IMAGE = `${API_URL}/upload`
export const GET_DEPARTMENTS = `${API_URL}/departments`
export const GET_SITES = `${API_URL}/sites`
export const GET_CATEGORIES = `${API_URL}/categories`
export const GET_METADATA = `${API_URL}/metadata`




export function getCountries() {
  return axios.get<any>(GET_COUNTRIES)
}

export function getCities() {
  return axios.get<any>(GET_CITIES)
}

export function getSites(filter:string, operand:string, value:any) {
  return axios.get<any>(GET_SITES+`?filter={"${filter}":{"op":"${operand}","value":"${value}"}}`)
}

export function getCategories(filter:string, operand:string, value:any) {
  return axios.get<any>(GET_CATEGORIES+`?filter={"${filter}":{"op":"${operand}","value":"${value}"}}`)
}


export function getAllCategories() {
  return axios.get<any>(GET_CATEGORIES)
}

export function createCategorie(data: any) {
  return axios.post<any>(GET_CATEGORIES, data)
}

export function createMetaData(data: any) {
  return axios.post<any>(GET_METADATA, data)
}

export function getMetaData(filter:string, operand:string, value:any) {
  return axios.get<any>(GET_METADATA+`?filter={"${filter}":{"op":"${operand}","value":"${value}"}}`)
}

export function getDepartments(filter:string, operand:string, value:any) {
  return axios.get<any>(GET_DEPARTMENTS+`?filter={"${filter}":{"op":"${operand}","value":"${value}"}}`)
}

export function uploadImage(image: File) {
  let formData = new FormData();
  formData.append("file", image);
  return axios.post<any>(UPLOAD_IMAGE, formData)
}


export function confirmEmail(token:any){
  return axios.get<any>(`${API_URL}/company/confirm/`+token)
}
export function confirmCandidateEmail(token:any){
  return axios.get<any>(`${API_URL}/confirm/`+token)
}
export function resetCompanyPassword(data: any){
  return axios.post<any>(`${API_URL}/company/resetPassword/`+data.token+'/'+data.id, data)
}
export function resetCandidatePassword(data: any){
  return axios.post<any>(`${API_URL}/resetPassword/`+data.token+'/'+data.id, data)
}
