import React from 'react'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'
import { useIntl } from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      <MenuItem title={intl.formatMessage({id: 'DASHBOARD'})} to='/dashboard' />
      <MenuItem title={intl.formatMessage({id: 'JOB.OFFER'})} to='/jobs' />
      {/* <MenuItem title="Workflow" to='/workflow' /> */}
      {/* <MenuItem title="Agenda" to='/agenda' /> */}
      <MenuItem title={intl.formatMessage({id: 'SOURCING'})} to='/sourcing' />
      <MenuItem title={intl.formatMessage({id: 'CANDIDATES'})} to='/candidates/list' />
      <MenuItem title={intl.formatMessage({id: 'INTERVIEW.CALENDER'})} to='/calendar' />

      {/* <MenuInnerWithSub
        title='Workflow'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        {/* PAGES */}
        {/*<MenuInnerWithSub
          title='Job Offers'
          to='/crafted/pages'
          icon='/media/icons/duotune/general/gen022.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
        </MenuInnerWithSub>
      </MenuInnerWithSub> */}


    </>
  )
}
