/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { ResetCard } from './ResetCard'

const PasswordReset: React.FC = () => {
    const history = useHistory()


    return (
        <ResetCard/>
    )
}

export { PasswordReset }