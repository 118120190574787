import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
//const API_URL = "https://int.api.forstec.tn"

export const GET_USERS = `${API_URL}/users`

// Server should return CompanyModel

export function getUsers(filters:any) {
  if(filters){
    return axios.get<any>(GET_USERS+"?"+filters)
  }else {
    return axios.get<any>(GET_USERS+'?filter={"role":{"op":"=","value":"condidate"},"status":{"op":"=","value":"active"}, "availability":{"op":"=","value":"available"}}')
  }
}

